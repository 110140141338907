const teamTexts = {
    es: {
        missingTeam: 'Aún no eres parte de un plan teams:',
        missingTeamSubtitle: 'Lleva a tu organización a otro nivel y poténciala con IA utilzando nuestros equipos colaborativos.',
        librariesForTeam: "Libraries for the whole team",
        personalizedProfile: "Personalized profile",
        systemAnalysis: "System analysis",
        collaborationTools: "Collaboration tools",
        apiIntegrations: "API integrations",
        missingTeamButton: 'Ver planes',
        teamNoAppsYet: 'Una vez que tu equipo comience a trabajar en aplicaciones colaborativas, podrás verlas ver todas esas brillantes ideas tomar forma justo en esta pantalla. Es hora de desatar tu creatividad. Empieza a colaborar y observa como las aplicaciones toman forma.',
        teamLatestApps: 'Últimas apps',
        teamUserApps: 'Tus apps en', 
        teamCategoryList: 'Seleccionar categoria',
        teamShowAll: 'Mostrar todas',
    },
    en: {
        missingTeam: 'You are not part of a team yet:',
        missingTeamSubtitle: 'Take your organization to the next level and power it with AI using our collaborative teams.',
        librariesForTeam: "Libraries for the whole team",
        personalizedProfile: "Personalized profile",
        systemAnalysis: "System analysis",
        collaborationTools: "Collaboration tools",
        apiIntegrations: "API integrations",
        missingTeamButton: 'See plans',
        teamNoAppsYet: 'Once your team starts working on collaborative apps, you will be able to see them all here. It is time to unleash your creativity. Start collaborating and see how the apps take shape.',
        teamLatestApps: 'Latest apps',
        teamUserApps: 'Your apps in',
        teamCategoryList: 'Select tags',
        teamShowAll: 'Show all',
    },
};

export default teamTexts;
