import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import './confirmationDialog.scss';

const ConfirmationDialog = ({ message, onConfirm, onCancel, confText, cancelTxt }) => {
  const { language } = useLanguage();
  return (
    <><div className="modal-background"></div>
    <div className="confirmation-dialog">
      <div className="confirmation-dialog-content">
        <h2>{message}</h2>
        <div className="confirmation-dialog-buttons">
          <button className="button cancel" onClick={onCancel}>{cancelTxt ? cancelTxt : languageTexts[language].cancel}</button>
          <button className="button confirm" onClick={onConfirm}>{confText ? confText : languageTexts[language].confirm}</button>
        </div>
      </div>
    </div></>
  );
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
