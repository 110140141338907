import React from "react";
import './PageNotFound.scss';


const PageNotFound = () => {
    return(
        <section className="pageNotFound">
            <h1>Ooops! Page not found</h1>
        </section>

    )
}

export default PageNotFound;