import clipboardCopy from 'clipboard-copy';
import md5 from 'md5';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';

import { createApiKey, deleteApiKey, getApiKeys } from "../../api/apiKey";
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import ConfirmationDialog from '../../components/confirmationDialog/ConfirmationDialog';
import TopBar from "../../components/navBar/TopBar";
import languageTexts from '../../languajeConstant';
import './ApiKey.scss';
import NewApiKeyModal from "./NewApiKeyModal";


const ApiKey = () => {
    const [apiKeys, setApiKeys] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [name, setName] = useState();
    const [isCopied, setIsCopied] = useState(false);
    const [apiKeyToDelete, setApiKeyToToDelete] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showModalNewApiKey, setShowModalNewApiKey] = useState(false);
    const token = useSelector((state) => state.session.token);
    const { language } = useLanguage();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    const isMobile = windowWidth <= 768;

    function transformApiKey(apiKey) {
        const md5Hash = md5(apiKey);
        const truncatedKey = md5Hash.slice(0, 10);
      
        return `fl-***-${truncatedKey}`;
      }
      

    useEffect(() => {
        const fetchApiKeys = async () => {
            try {
                const apiKeysResponse = await getApiKeys(token);
                setApiKeys(apiKeysResponse);
            } catch (error) {
                console.error(error);
            }
        };
        fetchApiKeys();
    }, [token]);

    const confirmDeleteApiKey = () => {
        deleteApiKey(token, apiKeyToDelete)
            .then(async () => {
                setShowConfirmation(false);
                const apiKeysResponse = await getApiKeys(token);
                setApiKeys(apiKeysResponse);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const handleCreateApiKey = (e) => { 
        e.preventDefault();
        createApiKey(token, name)
            .then(async () => {
                const apiKeysResponse = await getApiKeys(token);
                setApiKeys(apiKeysResponse);
            })
            .catch((error) => {
                console.error(error);
            });

            setShowModalNewApiKey(false);
    };
    function copyToClipboard(text) {
        clipboardCopy(text);
        setIsCopied(!isCopied);
      }
  return(
    <section className="apikey">
        {!isMobile && <TopBar hasNavegation />}
        <section className="apikey-content">

            <a href="https://flipandoai.notion.site/Flipando-ai-API-Integration-Guide-6b508cfe1a5d4a249d20b926eac3a1d7" target="_blank" rel="noopener noreferrer" className="api-docs-link">
                <InfoIcon className="api-docs-icon" />
                {languageTexts[language].apiKeyDocsLink}
            </a>

            <h1 className="apikey-title">{languageTexts[language].apiKeyTitle}</h1>
            <h2>{languageTexts[language].apiKeySubtitle}</h2>
            <section className="apikey-information">
                <table className="table apikey-table">
                    <thead>
                        <tr>
                            <th>{languageTexts[language].apiKeyInformation}</th>
                            <th>{languageTexts[language].apiKeyInformation2}</th>
                            <th>{languageTexts[language].apiKeyInformation3}</th>
                            <th>{languageTexts[language].apiKeyInformation4}</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                    {apiKeys &&
                        apiKeys.map((apiKey) => (
                            <tr key={apiKey.id}>
                            <td>{apiKey.name}</td>
                            <td>{transformApiKey(apiKey.api_key)}</td>
                            <td>{new Date(apiKey.create_time).toLocaleDateString()}</td>
                            <td>{apiKey.last_used ? new Date(apiKey.last_used).toLocaleDateString() : ""}</td>
                            <td className="apikey-table-actions">
                                <button
                                className="apikey-table-actions-button"
                                onClick={() => {
                                    setApiKeyToToDelete(apiKey.id);
                                    setShowConfirmation(true);
                                }}
                                >
                                    <DeleteIcon />
                                </button>

                                <button
                                className="apikey-table-actions-button"
                                onClick={() => {
                                    copyToClipboard(apiKey.api_key);
                                }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.25 0C11.6478 0 12.0294 0.158035 12.3107 0.43934C12.592 0.720644 12.75 1.10218 12.75 1.5V8.25C12.75 8.64782 12.592 9.02936 12.3107 9.31066C12.0294 9.59196 11.6478 9.75 11.25 9.75H9.75V11.25C9.75 11.6478 9.59196 12.0294 9.31066 12.3107C9.02936 12.592 8.64782 12.75 8.25 12.75H1.5C1.10218 12.75 0.720645 12.592 0.439341 12.3107C0.158035 12.0294 0 11.6478 0 11.25V4.5C0 4.10218 0.158035 3.72064 0.439341 3.43934C0.720645 3.15804 1.10218 3 1.5 3H3V1.5C3 1.10218 3.15804 0.720644 3.43934 0.43934C3.72064 0.158035 4.10218 0 4.5 0H11.25ZM4.5 3V1.5H11.25V8.25H9.75V4.5C9.75 4.10218 9.59196 3.72064 9.31066 3.43934C9.02936 3.15804 8.64782 3 8.25 3H4.5ZM8.25 4.5H1.5V11.25H8.25V4.5Z" fill="black"/>
                                    </svg>
                                </button>
                                {showConfirmation && apiKeyToDelete && (
                                <ConfirmationDialog
                                    message={languageTexts[language].apiKeyDelete}
                                    onConfirm={() => confirmDeleteApiKey()}
                                    onCancel={() => setShowConfirmation(false)}
                                />
                                )}
                            </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                {showModalNewApiKey && (
                    <NewApiKeyModal closeModal={setShowModalNewApiKey} handleCreateApiKey={handleCreateApiKey} setName={setName} />
                )}
                {isCopied && <p>{languageTexts[language].apiKeyCopy}</p>}
                <button className="apikey-information-addnew" onClick={() => setShowModalNewApiKey(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.93 5.9H8.82V7.1H6.93V8.9H5.67V7.1H3.78V5.9H5.67V4.1H6.93V5.9ZM6.3 11.3C3.52107 11.3 1.26 9.1466 1.26 6.5C1.26 3.8534 3.52107 1.7 6.3 1.7C9.07893 1.7 11.34 3.8534 11.34 6.5C11.34 9.1466 9.07893 11.3 6.3 11.3ZM6.3 0.5C2.82051 0.5 0 3.1862 0 6.5C0 9.8138 2.82051 12.5 6.3 12.5C9.77949 12.5 12.6 9.8138 12.6 6.5C12.6 3.1862 9.77949 0.5 6.3 0.5Z" fill="black"/>
                    </svg>
                    {languageTexts[language].apiKeyCreate}
                </button>
            </section>
        </section>
    </section>
  )
}

export default ApiKey;
