import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import { getTeamById } from "../../api/teams";
import { ReactComponent as HomeIcon } from '../../assets/newDesign/home.svg';
import { ReactComponent as BackIcon } from '../../assets/newDesign/toparrownocircle-icon.svg';
import profileImg from '../../assets/profile-user.svg';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from "../../languajeConstant";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";
import UserAvatar from "../userAvatar/UserAvatar";

import { PROUSER, USER } from '../../constants';
import { logout } from "../../redux/actions/authActions";


const TopBar = ({hasNavegation}) => {
    const [dropIsActive, setDropIsActive] = useState(false);
    const [team, setTeam] = useState();
    const { language } = useLanguage();

    let navigate = useNavigate();

    function handleBack() {
      navigate(-1);
    }
  

    const toggleDropdown = () => {
        setDropIsActive(!dropIsActive);
    };
    const handleLogout = () => {
        dispatch(logout());
        localStorage.clear();
    }
    const dispatch = useDispatch();
    const handleImageError = (e) => {
        e.target.src = profileImg;
    }

    const user = useSelector((state) => state.session.userInfo);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchTeam = async () => {
            try {
                const teamResponse = await getTeamById(user.team);
                setTeam(teamResponse);
            } catch (error) {
                console.error(error);
            }
        };
        if(user.team) {
            fetchTeam();
        }
        }, [user.team]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropIsActive(false);
          }
        };
    
        if (dropIsActive) {
          document.addEventListener('click', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [dropIsActive]);
    return (
        <section className="topBar">
            <div className="topBar-left">
                {hasNavegation &&
                    <>
                    <Link to="/"><HomeIcon /></Link>
                    <button className="back-icon" onClick={handleBack}><BackIcon /></button>
                    </>
                }
            </div>
            <div className="topBar-right">
                <LanguageSwitch />
                <div className={`dropdown ${dropIsActive ? 'is-active' : ''}`} ref={dropdownRef}>
                    <div className="dropdown-trigger">
                    <button
                        className="navbar-item settings-user-button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        onClick={toggleDropdown}
                    >
                    {user.profileImage ? 
                        <img src={user.profileImage} className="menu-internal-sup-user-img" onError={handleImageError} alt="profile" />
                        :
                        <UserAvatar name={user.userName ? user.userName :"default"} />
                        }
                        <p>
                        {user.userName}
                        {team &&
                            <span className="menu-internal-sup-user-team">{team?.name}</span>
                        }
                        {user.role === PROUSER &&
                            <span className="menu-internal-sup-user-team">Pro User</span>
                        }
                        {user.role === USER &&
                            <span className="menu-internal-sup-user-credits"><span>{user.credits} </span>{languageTexts[language].navBarCredits}</span>
                        }
                        </p>
                    </button>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <p className="dropdown-item">{user.userName}</p>
                        <p className="dropdown-item">{user.email}</p>
                        <hr className="dropdown-divider" />
                        <Link className="dropdown-item" to="/settings">
                            {languageTexts[language].navBarUserSettings}
                        </Link>
                        <hr className="dropdown-divider" />
                        {team &&
                        <>
                        <Link className="dropdown-item" to="/workspace/teams">
                            {languageTexts[language].navBarTeamWorkspace}
                        </Link>
                        <Link className="dropdown-item" to="/teamsettings">
                            {languageTexts[language].navBarTeamSetttings}
                        </Link>
                        </>
                        }
                        <hr className="dropdown-divider" />
                        {user.role === USER ? 
                            <span className="dropdown-item" data-tooltip="Pro User Feature">API Keys</span> :
                            <Link className="dropdown-item" to="/apikey" >
                                {languageTexts[language].navBarApiKeys}
                        </Link>
                        }
                        <hr className="dropdown-divider" />
                        <button className="dropdown-item button" onClick={handleLogout}>
                            {languageTexts[language].navBarLogout}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default TopBar;