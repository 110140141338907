import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import "bulma/css/bulma.min.css";
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.scss";
import configureStore from "./redux/storeConfig";

import { Provider as ReduxProvider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';

import ReactGA from "react-ga4"

ReactGA.initialize("G-5TNMR5R897")

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='881189248824-p8f35gms7gq6bvcqn1o8ouie9l2p25nk.apps.googleusercontent.com'>
    <Router>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </Router>
  </GoogleOAuthProvider>  
);
