import React from "react";
import './newAppBtn.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';

const NewAppBtn = () => {
    return(
        <Link className="button new-app-btn" to="/newapp">
            <PlusIcon/>
           New App
        </Link>
    )
}

export default NewAppBtn;