const dashboardTexts = {
    es: {
        tooltipProUserFeature: 'Usuario Pro',
        tooltipComingSoon: 'Próximamente'
    },
    en: {
        tooltipProUserFeature: 'Pro User Feature',
        tooltipComingSoon: 'Coming Soon'
    },
};

export default dashboardTexts;
