import React, { useState, useEffect } from "react";
import { getTeamById, joinTeam } from "../../../api/teams";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../../components/errorMessage/ErrorMessage";

import './joinTeam.scss';


const JoinTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const teamId = params.get('team_id');

  const [team, setTeam] = useState(null);
  const [errorMessage, setErrorMessage] = useState();


  useEffect(() => {
    if (teamId) {
      const fetchTeam = async () => {
        try {
          const teamResponse = await getTeamById(teamId);
          setTeam(teamResponse);
        } catch (error) {
          console.error(error);
        }
      }
      fetchTeam()
    };
  }, [teamId]);

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const response = await joinTeam(teamId, token);
      console.log(response);
      alert(`You are now part of ${team.name} team!`);
      navigate('/login');
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message)
    }
  };

  const onDecline = async (e) => {
    e.preventDefault();
    navigate('/login');
  };

  return (
    <>
      <div className="modal-background"></div>
      <section className="join-team">
        {team && (
          <div className="modal-content">
            <h1 className="join-team-modal-title">Join Team</h1>
            <h3>You've been invited to join the team {team.name}</h3>
            <div className="confirmation-buttons">
              <button className="button is-danger" onClick={onConfirm}>Accept</button>
              <button className="button is-light" onClick={onDecline}>Decline</button>
            </div>
          </div>
        )}
        <ErrorMessage message={errorMessage} />
      </section>
    </>
  );
};

export default JoinTeam;
