import React from 'react';
import './toggleButton.scss';

const ToggleButton = ({ isOn, setIsOn, borderColor }) => {
  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const labelStyle = {
    border: `1px solid ${borderColor ? borderColor : 'black'}`,
  };

  return (
    <label className="toggle-button" style={labelStyle}>
      <input
        type="checkbox"
        checked={isOn}
        onChange={handleToggle}
      />
      <span className="slider" />
    </label>
  );
};

export default ToggleButton;
