import apiKeyTexts from './languaje/apiKey';
import appCardTexts from './languaje/appCard';
import appGeneratorTexts from './languaje/appGenerator';
import authTexts from './languaje/auth';
import createWorkflowTexts from './languaje/createWorkflow';
import dashboardTexts from './languaje/dashboard';
import editWorkflowTexts from './languaje/editWorkflow';
import exploreTexts from './languaje/explore';
import flipperModalTexts from './languaje/flipper';
import learningTexts from './languaje/learning';
import liveAppModalTexts from './languaje/liveApp';
import languageNavbar from './languaje/navbar';
import newAppTexts from './languaje/newApp';
import recentTexts from './languaje/recents';
import subscriptionsTexts from './languaje/subscriptions';
import teamTexts from './languaje/team';
import templatesTexts from './languaje/templates';
import tooltipTexts from './languaje/tooltip';
import useAppTexts from './languaje/useApp';
import WorkflowTexts from './languaje/useWorkflow';
import userSettingsTexts from './languaje/userSettings';
import workspace from './languaje/workspace';

const languageTexts = {
    es: {
        by: 'Por',
        created: 'Creada',
        run: 'Corrida',
        time: 'vez',
        times: 'veces',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        unlockFeature: 'Desbloquear esta funcionalidad',
        search: 'Buscar',
        ...languageNavbar.es,
        ...appCardTexts.es,
        ...exploreTexts.es,
        ...teamTexts.es,
        ...recentTexts.es,
        ...newAppTexts.es,
        ...subscriptionsTexts.es,
        ...learningTexts.es,
        ...authTexts.es,
        ...workspace.es,
        ...templatesTexts.es,
        ...apiKeyTexts.es,
        ...appGeneratorTexts.es,
        ...useAppTexts.es,
        ...WorkflowTexts.es,
        ...dashboardTexts.es,
        ...userSettingsTexts.es,
        ...flipperModalTexts.es,
        ...tooltipTexts.es,
        ...liveAppModalTexts.es,
        ...createWorkflowTexts.es,
        ...editWorkflowTexts.es,
    },
    en: {
        by: 'By',
        created: 'Created',
        run: 'Run',
        time: 'time',
        times: 'times',
        cancel: 'Cancel',
        confirm: 'Confirm',
        unlockFeature: 'Unlock this feature',
        search: 'Search',
        ...languageNavbar.en,
        ...appCardTexts.en,
        ...exploreTexts.en,
        ...teamTexts.en,
        ...recentTexts.en,
        ...newAppTexts.en,
        ...subscriptionsTexts.en,
        ...learningTexts.en,
        ...authTexts.en,
        ...workspace.en,
        ...templatesTexts.en,
        ...apiKeyTexts.en,
        ...appGeneratorTexts.en,
        ...useAppTexts.en,
        ...WorkflowTexts.en,
        ...dashboardTexts.en,
        ...userSettingsTexts.en,
        ...flipperModalTexts.en,
        ...tooltipTexts.en,
        ...liveAppModalTexts.en,
        ...createWorkflowTexts.en,
        ...editWorkflowTexts.en,
    }
  };
  
  export default languageTexts;
  
