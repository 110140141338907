import React from "react";
import './header.scss';

import Searcher from "../searcher/Searcher";
import NewAppBtn from "../newAppBtn/NewAppBtn";
import { ReactComponent as CancelIcon } from '../../assets/navBarIcons/close-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/navBarIcons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/navBarIcons/delete-icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/navBarIcons/copy-icon.svg';


const Header = ({
    title,
    search, 
    handleSearch, 
    cancelButton = false, 
    handleCancelButton,
    handleDuplicateButton,
    createNewApp, 
    editButton = false, 
    handleEdit, 
    deleteButton = false, 
    handleDelete,
    handleTemplate,
    templateButton,
    duplicateButton
    }) => {

    return(
        <header className="header">
            <h1>{title}</h1>
            <div className="header-left">
                {search &&
                    <Searcher onSearch={handleSearch} placeholder="Explore" />
                }
                {createNewApp &&
                    <NewAppBtn />
                }
                {cancelButton &&
                    <button className="header-btn" onClick={handleCancelButton} data-tooltip='Close this app'>
                        <CancelIcon/>
                    </button>
                }
                {editButton &&
                    <button className="header-btn" onClick={handleEdit} data-tooltip='Edit this app'>
                        <EditIcon/>
                    </button>
                }
                {duplicateButton &&
                    <button className="header-btn" onClick={handleDuplicateButton} data-tooltip='Duplicate this app'>
                        <CopyIcon />
                    </button>
                }
                {deleteButton &&
                    <button className="header-btn" onClick={handleDelete} data-tooltip='Delete this app'>
                        <DeleteIcon />
                    </button>
                }
                {templateButton &&
                    <button className="header-btn" onClick={handleTemplate} data-tooltip='Duplicate to my apps'>
                        <CopyIcon/>
                    </button>
                }
            </div>
        </header>
    )
}

export default Header;