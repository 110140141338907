import { 
  LOGIN,
  LOGIN_SUCCESS, 
  LOGIN_FAILURE, 
  LOGOUT, 
  CREATE_USER_REQUEST, 
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_CREDITS,
  INITIALIZE_USER, 
  ACTIVATE_USER_REQUEST} from '../constants/actionTypes';
import * as userApi from "../../api/session";
import { getSession } from '../../api/session';
import initialState from '../reducers/initialState';

export const login = () =>({
  type: LOGIN,
})

export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  payload: response,
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const logout = () => ({
  type: LOGOUT,
});

export const createUserRequest = () => ({
  type: CREATE_USER_REQUEST
});

export const activateUserRequest = () => ({
  type: ACTIVATE_USER_REQUEST
});

export const createUserSuccess = (response) => ({
  type: CREATE_USER_SUCCESS,
  payload: response, 
});

export const createUserFailure = (errorMessage) => ({
  type: CREATE_USER_FAILURE,
  payload: errorMessage
});

export const updateCredits = (newCredits) => ({
  type: UPDATE_CREDITS,
  payload: newCredits,
});

export const createUserAction = (userData) => {
  return async (dispatch) => {
    dispatch(createUserRequest());
    try {
      const response = await userApi.createUser(userData);
      dispatch(createUserSuccess(response));
    } catch (error) {
      dispatch(createUserFailure(error.message));
      throw error;
    }
  };
};

export const activateUserAction = (confirmationCode, token) => {
  return async (dispatch) => {
    dispatch(createUserRequest());
    try {
      const response = await userApi.activateUser(confirmationCode, token);
      dispatch(createUserSuccess(response));
    } catch (error) {
      dispatch(createUserFailure(error.message));
      throw error;
    }
  };
};

export const updateCreditsAction = (newCredits) => {
  return (dispatch) => {
    try {
      dispatch(updateCredits({ credits: newCredits }));
    } catch (error) {
      throw error;
    }
  };
};

export const loginUserAction = (credentials, googleAuth) => {
  return async (dispatch) => {
    dispatch(login());
    try {
      let response = await userApi.submitLogin(credentials, googleAuth);
      if (response.access_token === undefined) {
        dispatch(logout());
      } else {
        dispatch(loginSuccess(response));
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
      throw error;
    }
  };
};


const getCurrentSession = async () => {
  const currentSession = await getSession();
  return currentSession;
};
const fetchCurrentSession = async () => {
  const currentSession = await getCurrentSession();
  return {
      ...initialState,
      session: {
          ...initialState.session,
          userInfo: {
              ...initialState.session.userInfo,
              userId: currentSession.id,
              userName: currentSession.username,
              email: currentSession.email,
              role: currentSession.active_role,
              team: currentSession.team_id,
              phoneNumber: currentSession.phone_number,
              profileImage: currentSession.profile_pic,
              credits: currentSession.credits
          }
      }
  };
}
export const initializeUser = () => {
  return async (dispatch) => {
    try {
      const data = await fetchCurrentSession();
      dispatch({ type: INITIALIZE_USER, payload: data.session.userInfo });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else {
        dispatch(loginFailure(error.message));
      }
    }
  };
};


