import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import './useApp.scss';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { updateCreditsAction } from "../../redux/actions/authActions";

import { getApp, getAppCompletions, getUserAppHistory, getUserAppUseEvent, getAppGeneratorCompletions } from "../../api/applications";
import { isAppFavoritedByUser } from '../../api/user';
import { getTeamById } from "../../api/teams";
import { checkForResult } from "../../api/backgroundTasks";
import AppGenerator from './AppGenerator';
import Loader from "../../components/loader/Loader";
import TopBar from '../../components/navBar/TopBar';
import { APPGENERATOR, USER, TEAMMEMBER, TEAMADMIN } from '../../constants';
import UseAppGeneral from './UseAppGeneral';



const UseApp = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentApp, setCurrentApp] = useState({});
    const [appInput, setAppInput] = useState([]);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState("");
    const [error, setError] = useState("");
    const [textToCopy, setTextToCopy] = useState("");
    const [availableToEdit, setAvailableToEdit] = useState(false);
    const [availableToDelete, setAvailableToDelete] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [historyResult, setHistoryResult] = useState("")

    const [isTemplate, setIsTemplate] = useState(false);
    const [team, setTeam] = useState();
    const [createdAgo, setCreatedAgo] = useState('')
    const [isFavApp, setIsFavApp] = useState('')
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileDescription, setFileDescription] = useState(null);
    const [showPrompt, setShowPrompt] = useState(false);
    const [appGeneratorPrivate, setAppGeneratorPrivate] = useState(false);
    const [appHistory, setAppHistory] = useState(null);
    const [audio, setAudio] = useState(null);
    const [transcription, setTranscription] = useState(null);

    const token = useSelector((state) => state.session.token);
    const userInfo = useSelector((state) => state.session.userInfo)
    const dispatch = useDispatch();

    const location = useLocation();
    const { pathname } = location;
    const [appId, setAppId] = useState(null);
    const [isAppGenerator, setIsAppGenerator] = useState(pathname === '/appgenerator');
    const [selectedFormat, setSelectedFormat] = useState('general');
    const [showTooltipIndex, setShowTooltipIndex] = useState(null);
    const [canDuplicate, setCanDuplicate] = useState(false);

    const userInTeam = userInfo.role === TEAMMEMBER || userInfo.role === TEAMADMIN;
    const freeUser =  userInfo.role === USER;

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      const isMobile = windowWidth <= 768;


    useEffect(() => {
        if (isAppGenerator) {
          setAppId(APPGENERATOR);
        } else {
          const parts = pathname.split('/');
          const appPart = parts[parts.length - 1];
          const parsedAppId = parseInt(appPart, 10);
    
          if (!isNaN(parsedAppId)) {
            setAppId(parsedAppId);
          }
        }
      }, [isAppGenerator, pathname]);


    useEffect(() => {
        async function fetchData() {
            if(!appId) {
                setLoading(true);
                return
            } else {setLoading(false);}
            try {
                const app = await getApp(token, appId);
                const favApp = await isAppFavoritedByUser(token, app.id)
                const appHistory = await getUserAppHistory(token , app.id)
                
                setCurrentApp(app);
                setIsAppGenerator(app.id === APPGENERATOR);
                setIsFavApp(favApp)
                setIsTemplate(app.is_template);
                setShowPrompt(app.shows_prompt)
                setCreatedAgo(moment(app.creation_date).fromNow());
                setAppHistory(appHistory.results);
                setAppInput(app.inputs.map((input) => {
                    return {
                      name: input.name,
                      value: "",
                      placeholder: input.value
                    };
                }));
                setCanDuplicate(
                    userInfo.userId === app.user.id || userInfo.role === 1 ||
                    (app.user.team_id === userInfo.team)
                );
                setAvailableToDelete(
                    (userInfo.userId === app.user.id && app.type_id !== 3) || userInfo.role === 1 ||
                    (app.user.team_id === userInfo.team && userInfo.role === 3 && app.type_id !== 3)
                );
                setAvailableToEdit(
                    userInfo.userId === app.user.id || userInfo.role === 1 ||
                    (userInfo.team && app.user.team_id === userInfo.team)
                );
                ReactGA.event({
                    category: 'application',
                    action: 'use_app',
                    label: `id: ${appId}, name: ${app.name}`
                });
            } catch (error) {
                setLoading(false);
                setError(error.mesage);
            }
        }
        const fetchTeam = async () => {
            try {
                const teamResponse = await getTeamById(userInfo.team);
                setTeam(teamResponse);
            } catch (error) {
                console.error(error);
            }
        };
        if(userInfo.team) {
            fetchTeam();
        }
        fetchData();
    }, [token, appId, userInfo.userId, userInfo.role, userInfo.team]);

    const handleChangeInputs = (index, event) => {
        const newValue = event.target.value;
        const pattern = new RegExp("http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+(?<![.,])");
    
        setAppInput(prevState => {
            const values = [...prevState];
            values[index].value = newValue;
            return values;
        });
    
        if (pattern.test(newValue) && userInfo.role === USER) {
            setShowTooltipIndex(index);
        } else if (showTooltipIndex === index) { 
            setShowTooltipIndex(null);
        }
        if (isHistory) {
            setIsHistory(false);
            setResult({"results": {"results": historyResult}});
        }
    };
    
    const handleAppGeneratorSubmission = async () => {
        const isAudio = !!audio;
        return await getAppGeneratorCompletions(appInput, appGeneratorPrivate, audio, isAudio, token);
    };

    const handleRegularAppSubmission = async () => {
        return await getAppCompletions(appId, appInput, uploadedFile, fileDescription, selectedFormat, token);
    };

    const postSubmissionActions = (data) => {
        if (data && data.id) {
            setTimeout(() => {
              checkForResult(data.id, setLoading, setResult, setError, setTextToCopy, 3000);
            }, 750);
          }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const creditCost = appId === APPGENERATOR ? 10 : 1;
            const response = appId === APPGENERATOR ? await handleAppGeneratorSubmission() : await handleRegularAppSubmission();
            const data = response.data.results;
            if (appId === APPGENERATOR){
                setTranscription(data.transcript);
            }
            dispatch(updateCreditsAction(userInfo.credits - creditCost));
            postSubmissionActions(data);
        } catch (error) {
            console.error('Error starting task processing:', error.message);
            setLoading(false);
            setError(error.message);
            setAudio(null);
        }
    };

    const handleClearButton = () => {
        setAppInput(appInput.map((input) => {
            const placeholder = input.placeholder;
            return {
                name: input.name,
                value: "",
                placeholder: placeholder
            
            };
        }));
    };
    const handleShowHistory = async (selectedValue) => {
        if(selectedValue !== 0) {
            const appEvent = await getUserAppUseEvent(token, currentApp.id, selectedValue);
            if(appEvent.code === 200) {
                const results = appEvent.results;
                setAppInput(results.inputs);
                setHistoryResult(results.output);
                setResult(results.output);
                setIsHistory(true);
            } else {
                setError("Something went wrong when retrieving app history");
            }
        }
    }
    return (
        <section  className={`useApp ${isAppGenerator ? "isGenerator" : ""}`}>
            {!isMobile && <TopBar hasNavegation />}
                { appInput.length || (currentApp.has_docs  && currentApp.inputs.length === 0) ? (
                <section className={`useApp-content ${isAppGenerator ? "isGenerator-content" : ""}`}>
                     {isAppGenerator ? 
                     <AppGenerator
                        transcription={transcription}
                        result={result}
                        setAudio={setAudio}
                        setAppGeneratorPrivate={setAppGeneratorPrivate}
                        appHistory={appHistory}
                        handleClearButton={handleClearButton}
                        handleSubmit={handleSubmit}
                        setTextAreaContent={handleChangeInputs}
                        handleShowHistory={handleShowHistory}
                        userInTeam={userInTeam}
                        freeUser={freeUser}
                        error={error}
                        loading={loading} /> : 
                        <UseAppGeneral
                        handleClearButton={handleClearButton} 
                        handleSubmit={handleSubmit} 
                        handleChangeInputs={handleChangeInputs} 
                        currentApp={currentApp}
                        appInput={appInput}
                        setAppInput={setAppInput}
                        loading={loading}
                        availableToDelete={availableToDelete}
                        availableToEdit={availableToEdit}
                        isTemplate={isTemplate}
                        team={team}
                        createdAgo={createdAgo}
                        isFavApp={isFavApp}
                        appHistory={appHistory}
                        isAppGenerator={isAppGenerator}
                        showTooltipIndex={showTooltipIndex}
                        canDuplicate={canDuplicate}
                        handleShowHistory={handleShowHistory}
                        appId={appId}
                        setSelectedFormat={setSelectedFormat}
                        selectedFormat={selectedFormat}
                        result={result}
                        textToCopy={textToCopy}
                        isHistory={isHistory}
                        setUploadedFile={setUploadedFile}
                        setFileDescription={setFileDescription}
                        fileDescription={fileDescription}
                        error={error}
                        setError={setError}
                        setShowPrompt={setShowPrompt}
                        showPrompt={showPrompt}
                        token={token}
                        userInfo={userInfo}
                        />
                        }
                </section>
            ) : <Loader />}
        </section>
    );
};

export default UseApp;
