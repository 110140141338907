import axios from "axios";

export async function getWorkflowsByTeam(token) {
  const requestOptions = {
    headers: {
      "Accept": "application/json",
      "authorization": "Bearer " + token,
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/search/team`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function getUserWorkflows(token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/search/user`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}

export async function createWorkflow(workflowName, workflowDescription, modelId, steps, token) {
  const config = {
    headers: {
      accept: "application/json",
      authorization: "Bearer " + token
    }
  }

  const body = {
    name: workflowName,
    description: workflowDescription,
    structure: steps,
    model_id: modelId
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/`, body, config);
    return response.data.results;
  }
  catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function updateWorkflow(workflowId, workflowName, workflowDescription, modelId, steps, token) {
  const config = {
    headers: {
      accept: "application/json",
      authorization: "Bearer " + token
    }
  }

  const body = {
    name: workflowName,
    description: workflowDescription,
    structure: steps,
    model_id: modelId
  }

  try {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/${workflowId}`, body, config);
    return response.data.results;
  }
  catch (error) {
    throw new Error(error.response.data.message);
  }
}


export async function getWorkflow(token, id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/${id}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};


export async function runWorkflow(workflowId, inputs, files, token) {
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }

  const formData = new FormData();
  if (files.length > 0) {
    files.forEach((file) => {
      formData.append('files', file);
    })
  };

  formData.append('workflow_execution_data', JSON.stringify(inputs));

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/${workflowId}/run`, formData, config);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}


export async function deleteWorkflow(token, id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/workflows/${id}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
