import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { USER } from '../../constants';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';

import { deleteApp, removeFavoriteApp, setFavoriteApp } from "../../api/applications";
import { ReactComponent as InfoIcon } from '../../assets/newDesign/information-icon.svg';
import { ReactComponent as LiveIcon } from '../../assets/newDesign/live-icon.svg';
import { ReactComponent as PrivateIcon } from '../../assets/newDesign/lock-icon.svg';
import { ReactComponent as MoreIcon } from '../../assets/newDesign/more-icon.svg';
import { ReactComponent as StarIcon } from '../../assets/newDesign/star-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/newDesign/teamwhite-icon.svg';
import { ReactComponent as TemplateIcon } from '../../assets/newDesign/template-icon.svg';
import "./appCardMini.scss";

import defaultImg from '../../assets/newDesign/default-profile.webp';
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";
import HeartButton from "../../components/heartButton/HeartButton";
import UserAvatar from "../../components/userAvatar/UserAvatar";

const AppCardMini = ({ appInformation, favoriteApp, hasTopBadge, onUpdateFavorites }) => {
  const token = useSelector((state) => state.session.token);
  const userInfo = useSelector((state) => state.session.userInfo);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dropIsActive, setDropIsActive] = useState(false);
  const [availableToEdit, setAvailableToEdit] = useState(false);
  const [availableToDelete, setAvailableToDelete] = useState(false);
  const canFavApps = userInfo.role !== USER;
  const sectionRef = useRef(null); 
  const { language } = useLanguage();
  const airportApp = 3
  const hasTeamBadge = appInformation.user.team_id === userInfo.team && userInfo.team !== null && !appInformation.is_private && appInformation.type_id !== airportApp;
  const hasPrivateBadge = appInformation.is_private;
  const hasTemplateBadge = appInformation.is_template;
  const hasLiveBadge = appInformation.type_id === airportApp;
  
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropIsActive(!dropIsActive);
  };
  useEffect(() => {
    async function fetchData() {
        try {
            setAvailableToDelete(
                userInfo.userId === appInformation.user.id || userInfo.role === 1 ||
                (appInformation.user.team_id === userInfo.team && userInfo.role === 3)
            );
            setAvailableToEdit(
                userInfo.userId === appInformation.user.id || userInfo.role === 1 ||
                (userInfo.team && appInformation.user.team_id === userInfo.team)
            );
        } catch (error) {
            console.error(error);
        }
    }
    fetchData();
  }, [token, appInformation.id, userInfo.userId, userInfo.role, userInfo.team, appInformation.user.id, appInformation.user.team_id]);


  const handleFavoriteClick = async (isFavorited) => {
    try {
      if (isFavorited) {
        await setFavoriteApp(token, appInformation.id);
      } else {
        await removeFavoriteApp(token, appInformation.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageError = (e) => {
    e.target.src = defaultImg;
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropIsActive(false);
      }
    };

    if (dropIsActive) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropIsActive]);


  const confirmDeleteApp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteApp(token, appInformation.id)
        .then(() => navigate('/'))
        .catch((error) => {
            console.error(error);
        });
  }; 

  const handleEditApp = (e) => {
    e.preventDefault();
    e.stopPropagation();
      navigate(`./${appInformation.id}/edit`);
  } 
  const handleDeleteApp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirmation(true);
    setDropIsActive(!dropIsActive);
  };

  const handleCancelDeleteApp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirmation(false);
  };

  return (
    <>
    <section  
      className="appCardMini"
      ref={sectionRef}>
      <header className="card-header">
            <div className="card-header-badges">
                {hasLiveBadge && <div className="card-header-badge live-badge" data-tooltip={languageTexts[language].appCardsLiveApp}><LiveIcon /></div>}
                {hasTemplateBadge && <div className="card-header-badge template-badge" data-tooltip={languageTexts[language].appCardsTemplate}><TemplateIcon /></div>}
                {hasTeamBadge && <div className="card-header-badge team-badge" data-tooltip={languageTexts[language].appCardsTeamApp}><TeamIcon /></div>}
                {hasTopBadge && <div className="card-header-badge top-badge" data-tooltip={languageTexts[language].appCardsSuperApp}><StarIcon /></div>}
                {hasPrivateBadge && <div className="card-header-badge private-badge" data-tooltip={languageTexts[language].appCardsPrivateApp}><PrivateIcon /></div>}
                <p>{appInformation.category[`name_${language}`]}</p>
            </div>
            <div className="card-header-actions">
              <div className="info-tooltip" data-tooltip={appInformation.description}><InfoIcon /></div>
              <HeartButton
              canFavApps={canFavApps}
              isFavorited={favoriteApp}
              onFavorite={handleFavoriteClick}
              onUpdateFavorites={onUpdateFavorites}
              />
                {(availableToDelete || availableToEdit) && (
                  
                  <>
                <div className={`dropdown ${dropIsActive ? 'is-active' : ''}`} ref={dropdownRef}>
                  <button
                  className="appCard-options-button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={toggleDropdown}
                >
                  <MoreIcon />
                </button>
                  <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                      {availableToEdit &&
                        <button className="button appCard-options-option" onClick={handleEditApp}>
                          {languageTexts[language].appCardsEdit}
                        </button>}
                      {availableToDelete &&
                        <button className="dropdown-item appCard-options-option" onClick={handleDeleteApp}>
                        {languageTexts[language].appCardsDelete}
                        </button>}
                    </div>
                  </div>
              </div></>
                )}
            </div>
      </header>
      <main className="card-content">
        <div className="content">
          <h1 className="content-title">{appInformation.name}</h1>
        </div>
      </main>
      <footer className="card-footer">
        {appInformation.user.profile_pic ? 
          <img 
            src={appInformation.user.profile_pic} 
            onError={handleImageError} 
            alt="Profile" 
            className="menu-internal-sup-user-img" 
          />
          :
          <UserAvatar name={appInformation.user.username || "default"} />
        }
        <p className="card-footer-username">{languageTexts[language].by} {appInformation.user.username}</p>
        <p>{languageTexts[language].run} {appInformation.interactions} {appInformation.interactions === 1 ? languageTexts[language].time : languageTexts[language].times}</p>
      </footer>
    </section>

    {showConfirmation && ReactDOM.createPortal(
      <ConfirmationDialog
        message={languageTexts[language].appCardsCancelText}
        onConfirm={confirmDeleteApp}
        onCancel={handleCancelDeleteApp}
      />,
      document.getElementById('modal-root')
    )}
    </>
  )
}

export default AppCardMini;