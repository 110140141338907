import React,{ useState, useEffect } from "react";
import './newApp.scss';

import NewAppContent from "./NewAppContent";
import TopBar from '../../components/navBar/TopBar';

const NewApp = () => {
    const [appName, setAppName] = useState("New App");
    const [appDescription, setAppDescription] = useState("New App Description");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      const isMobile = windowWidth <= 768;
    return(
        <section className="new-app">
            {!isMobile && <TopBar hasNavegation />}
            <section className="new-app-content">
                <NewAppContent  setAppName={setAppName} setAppDescription={setAppDescription} appName={appName} appDescription={appDescription} />   
            </section>     
        </section>
    )
}

export default NewApp;