import React from 'react';
import './termsAndConditions.scss';

const TermsAndConditions = () => {
  React.useEffect(() => {
    window.location.href = 'https://animated-carnival-j84l61p.pages.github.io/';
  }, []);
  return null;
};

export default TermsAndConditions;
