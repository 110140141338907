const newApp = { 
    es: {
        newAppColumnTitle: 'Crear Nueva App',
        newAppInfo: 'Informacion de la app:',
        newAppName: 'Nombre de la App:',
        newAppDescription: 'Descripción:',
        newAppCategory: 'Categoria:',
        newAppLogic: 'Prompt & Variables:',
        newAppLogicDescription: 'Bring your app to life effortlessly: type the logic directly into the text box and add variables using brackets, or simply click the '+' icon to add them manually. It is that easy and customizable.',
        newAppVariables: 'Variables',
        newAppTextAreaLogic: 'Logica con Variables',
        newAppSubmit: 'Publicar App',
        newAppSelectStatus: 'Publicar tu app como:',
        newAppStatus1: 'Todos en mi team pueden verla',
        newAppStatus2: 'Esta app es privada para mi',
        newAppPreview: 'Vista previa',
        newAppField: 'Agregar Campo',
        newAppFileName: 'Nombre del Archivo',
        newAppFieldInput: 'Input de Archivo',
        newAppVariableName: 'Nombre de la Variable',
        newAppVariableDescription: 'Descripción',
        newAppHideLogicButton: 'Ocultar Logica',
        newAppSubmitPreviewButton: 'Enviar',
        newAppOutputFormat: 'Formato de Respuesta',
        newAppNamePlaceholder: 'Nombre de la App',
        newAppDescriptionPlaceholder: 'Descripcion de la App',
        newAppLogicPlaceholder: 'Escribe tu logica aqui con ',
        newAppNoLogicNorVariables: 'Logica y Nombre de la Variable o FileName deben tener contenido',
        newAppLogicMissingVariables: 'Faltan las siguientes variables en la logica:',
        NewAppLogicMissingFileName: 'Falta {FileName} en la logica.',
        newAppErrorHelper: 'Recuerda que al hacer clic en los nombres de las variables anteriores, se agregarán automáticamente a la lógica de tu app.',
    },
    en: {
        newAppColumnTitle: 'Create a New App',
        newAppInfo: 'App info:',
        newAppName: 'App Name:',
        newAppDescription: 'Description:',
        newAppCategory: 'Cataegory:',
        newAppLogic: 'Prompt & Variables:',
        newAppLogicDescription: 'Bring your app to life effortlessly: type the logic directly into the text box and add variables using brackets, or simply click the '+' icon to add them manually. It is that easy and customizable.',
        newAppVariables: 'Variables',
        newAppTextAreaLogic: 'Logic with Variables',
        newAppSubmit: 'Publish App',
        newAppSelectStatus: 'Upload your app:',
        newAppStatus1: 'Anyone can use this app',
        newAppStatus2: 'This app is private for me',
        newAppPreview: 'Preview',
        newAppField: 'Add Field',
        newAppFileName: 'FileName',
        newAppFieldInput: 'File Variable',
        newAppVariableName: 'Variable Name',
        newAppVariableDescription: 'Description',
        newAppHideLogicButton: 'Hide Logic',
        newAppSubmitPreviewButton: 'Submit',
        newAppOutputFormat: 'Output Format',
        newAppNamePlaceholder: 'App Name',
        newAppDescriptionPlaceholder: 'App Description',
        newAppLogicPlaceholder: 'Write your logic here with ',
        newAppNoLogicNorVariables: 'Logic and Input Name or FileName must have content',
        newAppLogicMissingVariables: 'Logic is missing the following variable(s):',
        newAppLogicMissingFileName: 'Logic is missing {FileName}.',
        newAppErrorHelper: 'Remember that clicking on the variable names above will automatically add them to your app\'s logic.',
    }
}

export default newApp;