import React, { useState, useEffect, useRef } from 'react';
import { getApp } from "../../../api/applications";
import { useLanguage } from "../../../hooks/languageHook/LanguageHook";
import languageTexts from '../../../languajeConstant';


function CardWorkflow({
    step,
    appId,
    workflowInputs,
    setFiles,
    token,
    inputValues,
    onInputChange,
    uploadedFiles,
    setUploadedFiles,
    fileInputRefs
}) {

    const { language } = useLanguage();
    const [appInput, setAppInput] = useState([]);
    const [currentApp, setCurrentApp] = useState();
    const [error, setError] = useState("");

    const handleFileUpload = (event, inputName) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFiles(prevFiles => ({
                ...prevFiles,
                [`${step}-${inputName}`]: file
            }));
            onInputChange(step, inputName, file.name);
            setFiles(prevFiles => [...prevFiles, file]);
        }
    };
    const handleRemoveFile = (inputName) => {
        setUploadedFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            delete updatedFiles[`${step}-${inputName}`];
            return updatedFiles;
        });
        
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter(file => file.name !== uploadedFiles[`${step}-${inputName}`].name);
            return updatedFiles;
        });
        
        onInputChange(step, inputName, "");
        
        if (fileInputRefs.current[`${step}-${inputName}`]) {
            fileInputRefs.current[`${step}-${inputName}`].value = '';
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const app = await getApp(token, appId);

                setCurrentApp(app);
            } catch (error) {
                setError(error.mesage);
            }
        }
        fetchData();
    }, [token, appId, workflowInputs]);

    useEffect(() => {
        if (workflowInputs) {
            setAppInput(Object.keys(workflowInputs).map(key => {
                return {
                    name: key,
                    value: inputValues ? inputValues[key] : "",
                    placeholder: workflowInputs[key].value,
                    type: workflowInputs[key].type
                };
            }));
        }
    }, [workflowInputs, inputValues]);

    const handleInputChange = (step, inputName, inputValue) => {
        onInputChange(step, inputName, inputValue);
    };

    return (
        <section className="useWorkflow-card">
            <div className='useWorkflow-card-left'>
                <h2>{currentApp?.name}</h2>
            </div>
            <div className='useWorkflow-card-right'>
                {appInput?.map((inputObj, index) => (
                    inputObj.type === 'user_input' ? (
                        <div key={index} className='field'>
                            <label className='label'>{inputObj.name}</label>
                            <input
                                type="text"
                                name={`${step}-${inputObj.name}`}
                                placeholder={inputObj.placeholder}
                                value={inputObj.value}
                                onChange={(e) => handleInputChange(step, inputObj.name, e.target.value)}
                            />
                        </div>
                    ) : inputObj.type === 'step_output' ? (
                        <div key={index} className='field'>
                            <label className='label'>{inputObj.name}</label>
                            <p>{languageTexts[language].useWorkflowCardOutputApp} {inputObj.placeholder}</p>
                        </div>
                    ) : inputObj.type === 'doc_input' ? (
                        <div key={index} className='field'>
                            <label className='label'>{inputObj.name}</label>
                            <div className='file-upload'>
                                <input
                                    type='file'
                                    id={`${step}-${inputObj.name}`}
                                    name='file'
                                    accept='.pdf, .docx, .csv, .xlsx, .xls, .txt'
                                    onChange={(e) => handleFileUpload(e, inputObj.name)}
                                    style={{ display: 'none' }}
                                    ref={el => fileInputRefs.current[`${step}-${inputObj.name}`] = el}
                                />
                                {uploadedFiles[`${step}-${inputObj.name}`] ? (
                                    <div className='useApp-form-file-uploaded'>
                                        <p>{languageTexts[language].useWorkflowCardFileUploaded} {uploadedFiles[`${step}-${inputObj.name}`].name} <button type="button" className='button useApp-form-remove-file' onClick={() => handleRemoveFile(inputObj.name)}>X</button></p>
                                    </div>
                                ) : (
                                    <label htmlFor={`${step}-${inputObj.name}`} className="button useApp-form-upload-button">
                                        {languageTexts[language].useWorkflowCardUploadFile}
                                    </label>
                                )}
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
            {error}
        </section>
    );
}

export default CardWorkflow;
