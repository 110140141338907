import React, { useRef, useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import { USER } from '../../constants';

import "./appCard.scss";
import { setFavoriteApp, removeFavoriteApp, deleteApp } from "../../api/applications";
import { ReactComponent as OpenIcon } from '../../assets/newDesign/openarrow-icon.svg';
import { ReactComponent as MoreIcon } from '../../assets/newDesign/more-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/newDesign/information-icon.svg';

import Badge from '../../components/badge/Badge'
import HeartButton from "../../components/heartButton/HeartButton";
import defaultImg from '../../assets/newDesign/default-profile.jpeg';
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";
import UserAvatar from "../../components/userAvatar/UserAvatar";

const AppCard = ({ appInformation, favoriteApp, onUpdateFavorites }) => {
  const token = useSelector((state) => state.session.token);
  const userInfo = useSelector((state) => state.session.userInfo);
  const canFavApps = userInfo.role !== USER;
  const sectionRef = useRef(null); 
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dropIsActive, setDropIsActive] = useState(false);
  const [availableToEdit, setAvailableToEdit] = useState(false);
  const [availableToDelete, setAvailableToDelete] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropIsActive(!dropIsActive);
};

useEffect(() => {
  async function fetchData() {
      try {
          setAvailableToDelete(
              userInfo.userId === appInformation.user.id || userInfo.role === 1 ||
              (appInformation.user.team_id === userInfo.team && userInfo.role === 3)
          );
          setAvailableToEdit(
              userInfo.userId === appInformation.user.id || userInfo.role === 1 ||
              (userInfo.team && appInformation.user.team_id === userInfo.team)
          );
      } catch (error) {
          console.error(error);
      }
  }
  fetchData();
}, [token, appInformation.id, userInfo.userId, userInfo.role, userInfo.team, appInformation.user.id, appInformation.user.team_id]);

  const handleFavoriteClick = async (isFavorited) => {
    try {
      if (isFavorited) {
        await setFavoriteApp(token, appInformation.id);
      } else {
        await removeFavoriteApp(token, appInformation.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageError = (e) => {
    e.target.src = defaultImg;
  }
  const confirmDeleteApp = () => {
    deleteApp(token, appInformation.id)
        .then(() => navigate('/'))
        .catch((error) => {
            console.error(error);
        });
  }; 


  const handleEditApp = (e) => {
    e.stopPropagation();
      navigate(`./${appInformation.id}/edit`);
      setDropIsActive(!dropIsActive);
  } 
  const handleDeleteApp = (e) => {
    e.stopPropagation();
    setShowConfirmation(true);
    setDropIsActive(!dropIsActive);
  };

  const handleCancelDeleteApp = (e) => {
    e.stopPropagation();
    setShowConfirmation(false);
  };
  return (
    <>
    <section  
      className="appCard"
      ref={sectionRef}>
      <header className="card-header">
        <div className="card-header-actions">
          <div className="info-tooltip" data-tooltip={appInformation.description}><InfoIcon /></div>
          <HeartButton
            canFavApps={canFavApps}
            isFavorited={favoriteApp}
            onFavorite={handleFavoriteClick}
            onUpdateFavorites={onUpdateFavorites}
            />
            {(availableToDelete || availableToEdit) && (

            <>
            <div className={`dropdown ${dropIsActive ? 'is-active' : ''}`}>
              <button
                className="appCard-options-button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                onClick={toggleDropdown}
              >
                <MoreIcon />
              </button>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    {availableToEdit &&
                      <button className="button appCard-options-option" onClick={handleEditApp}>
                        {languageTexts[language].appCardsEdit}
                      </button>}
                    {availableToDelete &&
                      <button className="dropdown-item appCard-options-option" onClick={handleDeleteApp}>
                        {languageTexts[language].appCardsDelete}
                      </button>}
                  </div>
                </div>
              </div>
              </>
            )}
        </div>
        <OpenIcon />
      </header>
      <main className="card-content">
        <div className="card-header-badges">
          <Badge isEnterprise={false} text={appInformation?.category?.name} />
        </div>
        <div className="content">
          <h1 className="content-title">{appInformation.name}</h1>
        </div>
      </main>
      <footer className="card-footer">
      {appInformation.user.profile_pic ? 
        <img 
          src={appInformation.user.profile_pic} 
          onError={handleImageError} 
          alt="Profile" 
          className="menu-internal-sup-user-img" 
        />
        :
        <UserAvatar name={appInformation.user.username || "default"} />
      }
        <p className="card-footer-username">{languageTexts[language].by} {appInformation.user.username}</p>
        <p>{languageTexts[language].run} {appInformation.interactions} {appInformation.interactions === 1 ? languageTexts[language].time : languageTexts[language].times}</p>
      </footer>
    </section>
    {showConfirmation && ReactDOM.createPortal(
      <ConfirmationDialog
        message={languageTexts[language].appCardsCancelText}
        onConfirm={confirmDeleteApp}
        onCancel={handleCancelDeleteApp}
      />,
      document.getElementById('modal-root')
    )}
    </>
  )
}

export default AppCard;