import React from "react";
import { NavLink, useMatch } from 'react-router-dom';
import "./navBar.scss";
import { useSelector } from "react-redux";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import NavBarInternal from "./NavBarInternal";

const NavBar = ({openFlipperModal}) => {
    const token = useSelector((state) => state.session.isLoggingIn);

    const isActive = useMatch({
      path: '/',
      exact: true
    });

    const isAppGenerator = useMatch({
      path: '/explore/4',
      exact: true
    });

    const isTemplates = useMatch({
      path: '/templates',
      exact: true
    });

    const isMyApps = useMatch({
      path: '/workspace',
      exact: true
    });
    return(
        <nav className={`navbar is-light ${!token ? 'transparentbg' : ''}  ${!isAppGenerator && !isTemplates && !isMyApps ? "" : "clearbg"}`}  role="navigation" aria-label="main navigation">
        {token ? 
        (<NavBarInternal openFlipperModal={openFlipperModal} />) : (
        <div className="navbar-general-content">
          <div className="center-items">
            <div className="general-logo">
              <Logo />
            </div>
          </div>
          <div className="navbar-switch-buttons">
            <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/login">
              Log in
            </NavLink>
            <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/register">
              Sign up
            </NavLink>
          </div>
        </div>
        )}
      </nav>
    )
}

export default NavBar;
