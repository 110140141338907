import clipboardCopy from 'clipboard-copy';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { updateCreditsAction } from "../../redux/actions/authActions";
import './useApp.scss';

import ReactDOM from 'react-dom';
import { createApp, deleteApp, downloadFile, duplicateApp, removeFavoriteApp, setFavoriteApp } from "../../api/applications";
import { ReactComponent as CopyIcon } from '../../assets/copy-icon.svg';
import { ReactComponent as CancelIcon } from '../../assets/newDesign/cancel-icon.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/newDesign/duplicate-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/newDesign/edit-icon.svg';
import { ReactComponent as LiveIcon } from '../../assets/newDesign/live-icon.svg';
import { ReactComponent as PrivateIcon } from '../../assets/newDesign/lock-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/newDesign/teamwhite-icon.svg';
import { ReactComponent as TemplateIcon } from '../../assets/newDesign/template-icon.svg';
import { ReactComponent as TopArrow } from '../../assets/newDesign/toparrownocircle-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/newDesign/trash-icon.svg';
import ConfirmationDialog from '../../components/confirmationDialog/ConfirmationDialog';
import HeartButton from "../../components/heartButton/HeartButton";
import Loader from "../../components/loader/Loader";
import { USER, outputFormats } from '../../constants';
import languageTexts from '../../languajeConstant';
import LiveAppModal from '../../components/liveAppModal/LiveAppModal';


const UseAppGeneral = ({
    handleClearButton, 
    handleSubmit, 
    handleChangeInputs, 
    currentApp, 
    appInput, 
    loading,
    availableToDelete,
    availableToEdit,
    team,
    isFavApp,
    appHistory,
    isAppGenerator,
    showTooltipIndex,
    canDuplicate,
    handleShowHistory,
    appId,
    setSelectedFormat,
    selectedFormat,
    result,
    textToCopy,
    isHistory,
    setUploadedFile,
    uploadedFile,
    setFileDescription,
    fileDescription,
    setError,
    error,
    setShowPrompt,
    showPrompt,
    token,
    userInfo
    }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showLiveAppModel, setShowLiveAppModal] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const { language } = useLanguage();

    const contentRef = useRef(null);
    const canFavApps = userInfo.role !== 2;
    const [isDownloading, setIsDownloading] = useState(false);
    const airportApp = 3
    const hasTeamBadge = currentApp.user.team_id === userInfo.team && userInfo.team !== null && !currentApp.is_private && currentApp.type_id !== airportApp;
    const hasPrivateBadge = currentApp.is_private;
    const hasTemplateBadge = currentApp.is_template;
    const hasLiveBadge = currentApp.type_id === airportApp;

    const navigate = useNavigate();

    const handleFileUpload = (event) => {
        setUploadedFile(event.target.files[0]);
    }
    const handleRemoveFile = () => {
        setUploadedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    }

    useEffect(() => {
        const toggleButton = document.getElementById('toggleNodesBtn');
        const nodesContainer = document.getElementById('nodesContainer');
        if (!toggleButton || !nodesContainer) return;
        const handleTextToggle = (event) => {
            if (event.target && event.target.getAttribute('data-toggle-id')) {
                const targetId = event.target.getAttribute('data-toggle-id');
                const textElement = document.querySelector(`.node-text-content[data-id="${targetId}"]`);
                if (textElement) {
                    textElement.style.display = (textElement.style.display === 'none') ? 'block' : 'none';
                }
            }
        };
        const determineLanguage = (btnText) => {
            if (btnText === "Show Relevant Sections") return 'en';
            if (btnText === "Mostrar Secciones Relevantes") return 'es';
            if (btnText === "Mostrar Seções Relevantes") return 'pt';
            return 'en';
        };
        const buttonTranslations = {
            "en": {show: "Show Relevant Sections", hide: "Hide Relevant Sections"},
            "es": {show: "Mostrar Secciones Relevantes", hide: "Ocultar Secciones Relevantes"},
            "pt": {show: "Mostrar Seções Relevantes", hide: "Ocultar Seções Relevantes"}
        };
        const currentLanguage = determineLanguage(toggleButton.textContent);
        document.addEventListener('click', handleTextToggle);
        toggleButton.addEventListener('click', () => {
            if (nodesContainer.style.display === 'none') {
                nodesContainer.style.display = 'block';
                toggleButton.textContent = buttonTranslations[currentLanguage].hide;
            } else {
                nodesContainer.style.display = 'none';
                toggleButton.textContent = buttonTranslations[currentLanguage].show;
            }
        });
        return () => {
            if (toggleButton) {
                toggleButton.removeEventListener('click', null);
            }
            document.removeEventListener('click', handleTextToggle);
        };
    }, [result]);

    const handleEditApp = () => {
        if (currentApp.type_id === airportApp) {
            setShowLiveAppModal(true);
        } else {
            navigate(`./edit`);
        }
    };
    
    const handleCopyClick = () => {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(textToCopy.results.results, 'text/html');
        const plainText = parsedHtml.body.textContent;

        clipboardCopy(plainText);
        setIsCopied(true);
    };

    const handleCopyCodeClick = (code, button) => {
        navigator.clipboard.writeText(code);
        button.textContent = '✓ Copied!';
    
        setTimeout(() => {
            button.textContent = 'Copy code';
        }, 2000);
    };
    
    useEffect(() => {
        const container = contentRef.current;
        if (container) {
            const codeSnippets = container.querySelectorAll('pre > code');
            codeSnippets.forEach(snippet => {
                const copyButton = document.createElement('button');
                copyButton.textContent = 'Copy code';
                copyButton.className = 'useApp-content-logic-copy-code';
                copyButton.addEventListener('click', () => handleCopyCodeClick(snippet.textContent, copyButton));
                snippet.parentNode.insertBefore(copyButton, snippet);
            });

            const scriptElements = container.getElementsByTagName('script');
            for (let script of scriptElements) {
                const newScript = document.createElement('script');
                newScript.text = script.innerHTML;
                script.parentNode.replaceChild(newScript, script);
            }
        }
    }, [result]);
    const handleTemplateApp = async () => {
        const newName = `From Template ${currentApp.name}`;
        const app = await createApp(newName, currentApp.description, currentApp.logic, appInput, currentApp.model_id, currentApp.category_id, currentApp.shows_prompt, currentApp.is_private, false, token);
        if (app) {
            navigate(`/workspace/${app.id}/edit`, { state: { fromTemplate: true } });
            dispatch(updateCreditsAction(userInfo.credits - 5));
        } else {
            setError("Something went wrong when creating app");
        }
    }
    const handleFavoriteClick = async (isFavorited) => {
        try {
            if (isFavorited) {
                await setFavoriteApp(token, currentApp.id);
            } else {
                await removeFavoriteApp(token, currentApp.id);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleCancelButtonClick = () => {
        navigate('/');
    }
    const handleDownload = async (format) => {
        if (contentRef.current) {
            setIsDownloading(true);
            try {
                const fileName = uploadedFile ? uploadedFile.name : null;
                const blob = await downloadFile(currentApp.id, appInput, fileName, fileDescription, result?.results.results, format, token);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${currentApp.name}-${new Date().toISOString()}.${format}`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error(`Error generating ${format.toUpperCase()}`, error);
                setError(error.message);
            } finally {
                setIsDownloading(false);
            }
        }
    };
    
    const handleDownloadSelect = (event) => {
        const value = event.target.value;
        if (["pdf", "docx"].includes(value)) handleDownload(value);
        event.target.value = "default";
    };
    const handleDuplicateApp = async () => {
        const app = await duplicateApp(currentApp.id, token);
        if (app) {
            navigate(`/workspace/${app.id}/edit`);
            dispatch(updateCreditsAction(userInfo.credits - 5));
        } else {
            setError("Something went wrong when creating app");
        }
    };


    const confirmDeleteApp = () => {
        deleteApp(token, appId)
            .then(() => navigate('/'))
            .catch((error) => {
                setError(error)
            });
    };
    return (
    <section className='useApp-content-container'>

        {showConfirmation && (
            <ConfirmationDialog
                message={languageTexts[language].useAppDeleteConfirmation}
                onConfirm={confirmDeleteApp}
                onCancel={() => setShowConfirmation(false)}
                />
         )}

        {showLiveAppModel && ReactDOM.createPortal(
            <LiveAppModal onEditPage={false} setLiveAppModal={setShowLiveAppModal} onConfirm={() => navigate(`./edit`)}/>,
            document.getElementById('modal-root')
        )}
         <header className='useApp-content-header'>

            <div className="card-header-badges">
                {hasLiveBadge && <div className="card-header-badge live-badge" data-tooltip={languageTexts[language].appCardsLiveApp}><LiveIcon /></div>}
                {hasTemplateBadge && <div className="card-header-badge template-badge" data-tooltip={languageTexts[language].appCardsTemplate}><TemplateIcon /></div>}
                {hasTeamBadge && <div className="card-header-badge team-badge" data-tooltip={languageTexts[language].appCardsTeamApp}><TeamIcon /></div>}
                {hasPrivateBadge && <div className="card-header-badge private-badge" data-tooltip={languageTexts[language].appCardsPrivateApp}><PrivateIcon /></div>}
            </div>
            <div className='useApp-content-actions'>
                {availableToDelete
                    && <button className='useApp-content-actions-delete' data-tooltip={languageTexts[language].appCardsDelete} onClick={() => setShowConfirmation(true)}><DeleteIcon /></button>
                }
                {availableToEdit
                    && <button className='useApp-content-actions-delete' data-tooltip={languageTexts[language].appCardsEdit} onClick={handleEditApp}><EditIcon /></button>
                }
                {canDuplicate
                    && <button className='useApp-content-actions-duplicate' data-tooltip={languageTexts[language].appCardsDuplicateApp} onClick={handleDuplicateApp}><DuplicateIcon /></button>
                }
                {canFavApps && <HeartButton isFavorited={isFavApp} canFavApps={canFavApps} onFavorite={handleFavoriteClick} className='useApp-left-content-heart' />}
                <button className='useApp-content-actions-cancel' onClick={handleCancelButtonClick}><CancelIcon /></button>
            </div>
         </header>
         <main className='useApp-content-main'>
            <h1>{currentApp.name}</h1>
            <p className='useApp-content-username'>{languageTexts[language].by}: {currentApp.user.username} | <span>{languageTexts[language].run}: {currentApp.interactions} {currentApp.interactions === 1 ? languageTexts[language].useAppTime : languageTexts[language].useAppTimes}</span></p>
            <div className='useApp-content-more-info border-botom'>
                <button className={`useApp-content-more-info-button ${showMoreInfo ? 'open' : ''}`} onClick={() => setShowMoreInfo(!showMoreInfo)}><TopArrow />{languageTexts[language].useAppInformation}</button>
                {showMoreInfo &&
                    <>
                    <p className='useApp-content-description'>{currentApp.description}</p>
                    </>
                }
            </div> 

            {currentApp.shows_prompt &&
            <div className='useApp-content-more-info'>
                <button className={`useApp-content-more-info-button ${showPrompt ? 'open' : ''}`} onClick={() => setShowPrompt(!showPrompt)}>
                    <TopArrow />{languageTexts[language].useAppPrompt}:
                </button>
                    {showPrompt && <p>{currentApp.logic}</p>}
            </div>
            }
            {result ? (
        <>
            {isHistory ?
                <div ref={contentRef} className='useApp-content-logic' dangerouslySetInnerHTML={{ __html: result }} />
            :
                <div ref={contentRef} className='useApp-content-logic' dangerouslySetInnerHTML={{ __html: result.results.results }} />
            }
            <div className='useApp-content-logic-actions'>
                <button className='useApp-content-logic-copy' onClick={handleCopyClick}><CopyIcon /> {languageTexts[language].useAppCopyText}</button>
                <div className="useApp-content-logic-download select-container">
                    <select
                        disabled={isDownloading}
                        defaultValue="default"
                        onChange={handleDownloadSelect}
                        className='useApp-content-logic-download-select'
                    >
                        <option value="pdf">{languageTexts[language].useAppDownloadPdf}</option>
                        <option value="docx">{languageTexts[language].useAppDownloadDoc}</option>
                    </select>
                </div>
            </div>
            {isCopied && <p>{languageTexts[language].useAppCopySuccess}</p>}
            </>
            )
        :
        <form className='useApp-form' onSubmit={handleSubmit}>
        {currentApp.has_docs && (
            <div className='file-upload'>
                <div className='field'>
                    <label className='label'>{languageTexts[language].j}</label>
                    <input
                        className='input'
                        type="text"
                        name="file_description"
                        placeholder="Describe what this file is about"
                        onChange={(event) => setFileDescription(event.target.value)}
                    />
                </div>
                <input
                    type='file'
                    id='file'
                    name='file'
                    accept='.pdf, .docx, .csv, .xlsx, .xls, .txt'
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                {uploadedFile ?
                    <div className='useApp-form-file-uploaded'>
                        <p>{languageTexts[language].useAppFileUploaded} {uploadedFile.name} <button type="button" className='button useApp-form-remove-file' onClick={handleRemoveFile}>X</button></p>
                    </div> :
                    <label
                        htmlFor="file"
                        className="button useApp-form-upload-button"
                    >
                        {languageTexts[language].useAppFileUpload}
                    </label>
                }
            </div>
            )}
            {appInput.map((inputObj, index) => (
                <div key={index} className='field'>
                    <label className='label'>{inputObj.name}</label>
                    <input
                        className={`input ${showTooltipIndex === index ? 'is-warning' : ''}`}
                        type="text"
                        name={inputObj.name}
                        placeholder={inputObj.placeholder}
                        value={inputObj.value}
                        onChange={(event) => handleChangeInputs(index, event)}
                    />
                    {showTooltipIndex === index && <span className='warning-message'>{languageTexts[language].useAppURLMessage}</span>}
                </div>
            ))}
            {!isAppGenerator && <div className='field'>
                <label className='label'>{languageTexts[language].useAppOutputFormat}</label>
                <div className='select-container'>
                    <select
                        className='input'
                        value={selectedFormat}
                        onChange={(event) => setSelectedFormat(event.target.value)}
                    >
                        {outputFormats.map((format) => (
                            <option key={format.value} value={format.value}>{format.label[language]}</option>
                        ))}
                    </select>
                </div>
            </div>
            }
            <div className="useApp-form-buttons">
                <span className="button useApp-form-clear" onClick={handleClearButton} disabled={loading}>
                {languageTexts[language].useAppClear}
                </span>
                <button 
                className="button useApp-form-submit" 
                type="submit" disabled={loading || isHistory}
                {...((userInfo.role === USER) && isAppGenerator ? { "data-tooltip": languageTexts[language].useAppCreditUse10 } : {})}
                {...((userInfo.role === USER) && uploadedFile ? { "data-tooltip": languageTexts[language].useAppCreditUse3 } : {})}
                {...((userInfo.role === USER) && (!uploadedFile && !isAppGenerator) ? { "data-tooltip": languageTexts[language].useAppCreditUse1 } : {})}>
                    {languageTexts[language].useAppSubmit}
                </button>
            </div>
        </form>}
            {appHistory.length > 0 && (
                <div className='field'>
                    <label className='useApp-left-content-logic'>{languageTexts[language].useAppHistory}</label>
                    <div className='select-container'>
                        <select className='input' name="history" id="history"
                            onChange={(event) => {
                            const selectedValue = event.target.value;
                            handleShowHistory(selectedValue);
                        }}>
                            <option value="0">{languageTexts[language].useAppSelectDate}</option>
                            {appHistory.map((date, index) => (
                                <option key={index} value={date}>
                                {moment(date).format('DD/MM/YYYY - HH:mm')}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {loading &&
                <Loader />
            }
            {error &&
                <p>{error}</p>
            }
         </main>
    </section>
    );
};

export default UseAppGeneral;
