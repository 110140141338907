import React, { useState, useEffect } from "react";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import TopBar from "../../components/navBar/TopBar";
import "./learningResources.scss";
import languageTexts from '../../languajeConstant';

const LearningResources = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { language } = useLanguage();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const FAQ = [
    {
      Title: languageTexts[language].learningQuestion1, 
      Content: languageTexts[language].learningAnswer1
    },
    {
      Title: languageTexts[language].learningQuestion2,
      Content: languageTexts[language].learningAnswer2
    },
    {
      Title: languageTexts[language].learningQuestion3,
      Content: languageTexts[language].learningAnswer3
    },
    {
      Title: languageTexts[language].learningQuestion4,
      Content: languageTexts[language].learningAnswer4
    },
    {
        Title: languageTexts[language].learningQuestion5,
        Content: languageTexts[language].learningAnswer5
    },
    {
        Title: languageTexts[language].learningQuestion6,
        Content: languageTexts[language].learningAnswer6
    },
    {
        Title: languageTexts[language].learningQuestion7,
        Content: languageTexts[language].learningAnswer7
    },
    {
        Title: languageTexts[language].learningQuestion8,
        Content: languageTexts[language].learningAnswer8
    },
    {
      Title: languageTexts[language].learningQuestion9,
      Content: (
        <div>
          <p>
            {languageTexts[language].learningAnswer9_1}
          </p>
          <h5>{languageTexts[language].learningAnswer9_2}</h5>
          <p>
          {languageTexts[language].learningAnswer9_3}
            <a href="https://app.flipando.ai/" target="_blank" rel="noopener noreferrer">
              https://app.flipando.ai/
            </a>
            {languageTexts[language].learningAnswer9_4}
          </p>
          <p>{languageTexts[language].learningAnswer9_5}</p>
          <p>{languageTexts[language].learningAnswer9_6}</p>
          <p>{languageTexts[language].learningAnswer9_7}</p>
          <p>{languageTexts[language].learningAnswer9_8}</p>
          <p>{languageTexts[language].learningAnswer9_9}</p>
          <p>{languageTexts[language].learningAnswer9_10}</p>
          <p>{languageTexts[language].learningAnswer9_11}</p>
          <ul>
            <li>{languageTexts[language].learningAnswer9_12}</li>
            <li>{languageTexts[language].learningAnswer9_13}</li>
            <li>{languageTexts[language].learningAnswer9_14}</li>
          </ul>
          <p>{languageTexts[language].learningAnswer9_15}</p>
          <p>{languageTexts[language].learningAnswer9_16}</p>
          <p>{languageTexts[language].learningAnswer9_17}</p>
          <p>{languageTexts[language].learningAnswer9_18}</p>
          <p>{languageTexts[language].learningAnswer9_19}</p>
          <p>{languageTexts[language].learningAnswer9_20}</p>
          <p>{languageTexts[language].learningAnswer9_21}</p>
        </div>
      ),
    },
    {
        Title: languageTexts[language].learningQuestion10,
        Content: languageTexts[language].learningAnswer10
    },
    {
        Title: languageTexts[language].learningQuestion11,
        Content: languageTexts[language].learningAnswer11
    },
    {
        Title: languageTexts[language].learningQuestion12,
        Content: languageTexts[language].learningAnswer12
    },
    {
        Title: languageTexts[language].learningQuestion13,
        Content: languageTexts[language].learningAnswer13
    }
  ];

  const videoIdsEn = [
    "_Ke6xw7EAkc",
    '5umF_7zI3M8',
    'ddlRsKOtF80'
  ];

  const videoIdsEs = [  
    "kzvSEYx9t5o",
    'nNcA7ML_o1A',
    'NXpWxDSXcow'
  ];

  const [openPanels, setOpenPanels] = useState(Array(FAQ.length).fill(false));

  const togglePanel = (index) => {
    const newOpenPanels = [...openPanels];
    newOpenPanels[index] = !newOpenPanels[index];
    setOpenPanels(newOpenPanels);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  function renderVideo(videoId) {
    return (
      <iframe
        key={videoId}
        width="300" // Ancho del video
        height="200" // Altura del video
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
        title={`YouTube Video ${videoId}`}
      ></iframe>
    );
  }
  

  return (
    <section className="learningResources">
      {!isMobile && <TopBar hasNavegation />}
      <div className="learningResources-content">
        <h1 className="learningResources-title">{languageTexts[language].learningNewTitle}</h1>
        <h2 className="learningResources-subtitle">{languageTexts[language].learningTitle}</h2>
        {FAQ.map((item, index) => (
          <div key={index} className={`desplegable ${openPanels[index] ? 'abierto' : ''}`}>
            <button className="boton-desplegable" onClick={() => togglePanel(index)}>
              {item.Title}
            </button>
            <div className="contenido-desplegable">
              {openPanels[index] && item.Content}
            </div>
          </div>
        ))}
      </div>
      <div className="learningResources-content">
            <h2 className="learningResources-subtitle">{languageTexts[language].learningVideos}</h2>
            <div className="video-row">
            {language === 'en' ? videoIdsEn.map(renderVideo) : videoIdsEs.map(renderVideo)}
    </div>
      </div>
    </section>
  );
};

export default LearningResources;
