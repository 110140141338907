import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from '@react-oauth/google';
import { useLanguage } from '../../../hooks/languageHook/LanguageHook';
import './login.scss';
import axios from "axios"

import { useDispatch } from "react-redux";
import { loginUserAction } from "../../../redux/actions/authActions";
import { getUserByEmail } from "../../../api/user";
import DecorationBanner from "../../../components/decorationBanner/DecorationBanner";

import ErrorMessage from "../../../components/errorMessage/ErrorMessage";
import eyeIcon from '../../../assets/eye-password-hide-icon.svg';
import googleIcon from '../../../assets/google-icon.svg';
import languageTexts from '../../../languajeConstant';



const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const recaptchaRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [recaptchaStyle, setRecaptchaStyle] = useState({});
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const isProduction = process.env.NODE_ENV === 'IS_PRODUCTION';

    const { language } = useLanguage();


    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
      const recaptchaWidth = document.querySelector('.g-recaptcha')?.parentNode?.offsetWidth || 0;
      if (recaptchaWidth < 302 && recaptchaWidth > 0) {
          const scale = recaptchaWidth / 302;
          setRecaptchaStyle({
              transform: `scale(${scale})`,
              transformOrigin: '0 0',
          });
      } else {
          setRecaptchaStyle({});
      }
  }, [windowWidth]);

    const handleSubmit = async event => {
        event.preventDefault();
        if (isProduction && !recaptchaValue) {
            setErrorMessage(languageTexts[language].authCompleteRecaptcha);
            return;
        }
        const data = {'username': email, password};
        try {
            await dispatch(loginUserAction(data, false));
        }
        catch (error) {
            setErrorMessage(error.response.data.message);
        }
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async respose => {
          try {
            const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
              headers: {
                "Authorization": `Bearer ${respose.access_token}`
              }
            })

            try {
                const user = await getUserByEmail(res.data.email);
                if (user) {
                    const password = null
                    const data = { 'username': user.email, password };
                    dispatch(loginUserAction(data, true));
                } else {
                    setErrorMessage('Email not registered.')
                }

            } catch (error) {
                setErrorMessage(error.response.data.message);
            }

    
          } catch (err) {
            console.error(err)
    
          }
    
        },
        onFailure: async response => {
          console.error(response)
        }
      });

    return(
        <section className="login">
            <div className="banner-top">
              <DecorationBanner text={'Learn Build Share Showcase'} />
            </div>
            <div className="login-content">
                <h1 className="login-content-title">{languageTexts[language].authLogin}</h1>
                <form className="login-content-form" onSubmit={handleSubmit}>
                    <div className="field login-content-form-field">
                        <label className="label">{languageTexts[language].authEmail}</label>
                        <div className="control">
                            <input type="email" 
                            placeholder={languageTexts[language].authEmailPlaceholder}
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className="input"
                            required />
                        </div>
                    </div>
                    <div className="field login-content-form-field">
                      <label className="label">{languageTexts[language].authPassword}</label>
                      <div className="control">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder={languageTexts[language].authPasswordPlaceholder}
                          value={password} 
                          onChange={(e) => setPassword(e.target.value)}                           
                          className="input"
                          required
                        />
                        <img
                          src={eyeIcon}
                          alt="Toggle Password"
                          className="eye-icon"
                          onClick={handleTogglePassword}
                        />
                      </div>
                    </div>
                    <p className="login-content-new-password"><a href="/forgotPassword">{languageTexts[language].authForgotPassword}</a></p>
                    <ErrorMessage message={errorMessage}/>
                    <ReCAPTCHA 
                    ref={recaptchaRef}
                    style={recaptchaStyle} 
                    className="g-recaptcha" 
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    onChange={(value) => setRecaptchaValue(value)} />
                    <button className="button is-primary login-content-submit" type="submit">{languageTexts[language].authLoginButton}</button>
                    <button 
                    className="button is-primary login-content-google-submit" 
                    onClick={googleLogin}>
                        <img src={googleIcon} alt="Google Icon" className="google-logo" />
                        {languageTexts[language].authGoogle}
                    </button>
                </form>
            </div>
            <p className="login-footer">{languageTexts[language].authNoAccount} <a href="/register">{languageTexts[language].authCreateOne}</a></p>
            <div className="banner-bottom">
              <DecorationBanner text={'Learn Build Share Showcase'} />
            </div>
        </section>

    )
}


export default Login;