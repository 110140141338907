import axios from "axios";


export async function getApps(orderRule) {
  const requestOptions = {
    headers: {
      "Accept": "application/json",
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/${orderRule ? '?order_by=' + orderRule : ''}`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};



export async function getUserApps(token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/user`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}

export async function getUserRecentsApps(token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/user/latest`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}
export async function getUserAppsUsed(token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/user/recent`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}

export async function getUserAppHistory(token, appId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/history`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}


export async function getUserAppUseEvent(token, appId, timestamp) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/history/${timestamp}`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}


export async function getTeamApps(token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/team`, requestOptions);
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong. Couldn't load the apps");
  }
}


export async function getApp(token, id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${id}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};


export async function getAppCompletions(appId, logic, file, fileDescription, outputFormat, token) {
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token 
    }
  }

  const formData = new FormData();
  if (file) {
    formData.append('document', file);
    if (fileDescription) {
      formData.append('document_description', fileDescription);
    } 
  }
  formData.append('inputs_in', JSON.stringify(logic));
  formData.append('output_format', outputFormat);

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/completion`, formData, config);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}


export async function getAppGeneratorCompletions(appInput, appGeneratorPrivate, audioBlob, isAudio, token) {
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'multipart/form-data'
    }
  }

  const formData = new FormData();
  if (isAudio) {
    formData.append('audio_blob', audioBlob);
  } else {
    formData.append('inputs_in', JSON.stringify(appInput));
  }

  formData.append('is_new_app_private', appGeneratorPrivate);
  formData.append('is_audio', isAudio);

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/app-generator`, formData, config);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}


export async function getPreviewCompletions(logic, modelId, file, fileDescription, outputFormat, token) {
  const config = {
    headers: {
      authorization: "Bearer " + token 
    }
  }

  const formData = new FormData();
  if(file) {
    formData.append('document', file);
    formData.append('document_description', fileDescription);
  }
  formData.append('prompt_in', logic);
  formData.append('model_id', modelId);
  formData.append('output_format', outputFormat);

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/preview-completion`, formData, config)
    return response
  }
  catch (error) {
    throw new Error(error.response.data.message)
  }
}


export async function createApp(appName, appDescription, logicInput, inputsFormFields, modelSelected, categorySelected, showsPrompt, isPrivate, allowsDocument, token){
  const config = {
    headers: {
      accept: "application/json",
      authorization: "Bearer " + token 
    }
  }

  const body = {
    name: appName,
    description: appDescription, 
    inputs: inputsFormFields, 
    logic: logicInput,
    model_id: parseInt(modelSelected),
    category_id: parseInt(categorySelected),
    shows_prompt: showsPrompt,
    is_private: isPrivate,
    has_docs: allowsDocument
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/`, body, config);
    return response.data.results;
  } 
  catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function editApp(appId, appName, appDescription, logicInput, inputsFormFields, showsPrompt, isPrivate, hasDocs, categoryId, token){
  const config = {
    headers: {
      accept: "application/json",
      authorization: "Bearer " + token 
    }
  }

  const body = {
    name: appName,
    description: appDescription, 
    inputs: inputsFormFields, 
    logic: logicInput,
    shows_prompt: showsPrompt,
    is_private: isPrivate,
    has_docs: hasDocs,
    category_id: categoryId
  }

  try {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}`, body, config);
    return response.data.results;
  } 
  catch (error) {
    throw new Error(error.message);
  }
};

export async function deleteApp(token, id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${id}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function setFavoriteApp(token, appId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/favorite`, {}, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function removeFavoriteApp(token, appId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/favorite`, requestOptions);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function getAppHistory(token, id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${id}/history`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function downloadFile(appId, appInputs, fileName, fileDescription, resultsHtmlString, format, token) {
  const config = {
      headers: {
          accept: "application/json",
          authorization: "Bearer " + token
      },
      responseType: "blob"
  }

  const body = {
      app_id: appId,
      html_string: resultsHtmlString,
      inputs: appInputs,
      file_name: fileName,
      file_description: fileDescription
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/download-results/${format}`;
  try {
      const response = await axios.post(url, body, config);
      return response.data;
  } 
  catch (error) {
      throw new Error(error.response.data.message);
  }
}


export async function getAirportApps(type) {
  // Type tiene que ser una string que sea "arrival" (para la app con info sobre uru) o "departure" (para la app con info de otros paises)
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/airport/${type}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}


export async function getAirportAppCompletions(appId, logic) {
  const formData = new FormData();
  formData.append('inputs_in', JSON.stringify(logic));

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/guest-completion`, formData);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function getAirportAppResponse(token) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/history/${token}`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function duplicateApp(appId, token) {
  const config = {
    headers: {
        accept: "application/json",
        authorization: "Bearer " + token
    }
  }
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/duplicate`;
  try {
    const response = await axios.post(url, {}, config);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}
