import React,{ useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './newApp.scss';
import { USER } from '../../constants';
import languageTexts from '../../languajeConstant';

import AppPreview from "../../components/appPreview/AppPreview";
import { createApp } from '../../api/applications';
import { getCategories } from "../../api/categories";
import { getModels } from "../../api/models";
import { updateCreditsAction } from "../../redux/actions/authActions";

import { ReactComponent as TrashIcon } from '../../assets/newDesign/trash-icon.svg';
import { ReactComponent as CancelIcon } from '../../assets/mini-cancel-icon.svg';
import { ReactComponent as NextVariable } from '../../assets/logout-icon.svg';
import { ReactComponent as FileIcon } from '../../assets/newDesign/file-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/newDesign/plus-icon.svg';


const NewAppContent = ({ setAppName, setAppDescription, appName, appDescription }) => {
    const [inputsFormFields, setInputsFormFields] = useState([]);
    const [logicInput, setLogicInput] = useState('');
    const [categorySelected, setCategorySelected] = useState(1);
    const [modelSelected, setModelSelected] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [models, setModels] = useState([]);
    const [showsPrompt, setShowsPrompt] = useState(true);
    const [documentChecked, setDocumentChecked] = useState(false);
    const [action, setAction] = useState('');
    const [dropIsActive, setDropIsActive] = useState(false);
    const dropdownRef = useRef(null);

    const token = useSelector((state) => state.session.token);
    const user = useSelector((state) => state.session.userInfo);
    const { language } = useLanguage();

    console.log("langauge: ", language)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeInputs = (index, event) => {
        const values = [...inputsFormFields];
        values[index][event.target.name] = event.target.value;
        setInputsFormFields(values);
    };
    const logicInputRef = useRef();

    const handleButtonClick = (inputName) => {
        const logicInput = logicInputRef.current;
        logicInput.focus();
        const cursorPosition = logicInput.selectionStart;
        logicInput.value = logicInput.value.slice(0, cursorPosition) + ` {${inputName}}` + logicInput.value.slice(cursorPosition);
        setLogicInput(logicInput.value)
    };

    const handleAddFieldsInputs = () => {
        setDropIsActive(false);
        setInputsFormFields([...inputsFormFields, { name: '', value: '' }]);
    };

    const handleAddFileInputs = () => {
        setDocumentChecked(!documentChecked);
    }
  
    const handleRemoveFields = (index) => {
        const values = [...inputsFormFields];
        values.splice(index, 1);
        setInputsFormFields(values);
    };
  
    const handleSubmit = async (event, isPrivate) => {
        event.preventDefault();
        ReactGA.event({
            category: 'application',
            action: 'create_app',
            label: `name: ${appName}, description: ${appDescription}, logic: ${logicInput}, category: ${categorySelected}`
        });
        try {
            const app = await createApp(appName, appDescription, logicInput, inputsFormFields, modelSelected, categorySelected, showsPrompt, isPrivate, documentChecked, token);
            if (app) {
                navigate(`/explore/${app.id}`);
                dispatch(updateCreditsAction(parseInt(user.credits, 10) - 5));
            } else {
                setErrorMessage("Something went wrong when creating app");
            }

        } catch(error) {
            setErrorMessage(error.message);
        }
    };

  const handleSelectChange = (event) => {
    const selectedAction = event.target.value;
    setAction(selectedAction);
    if (selectedAction === 'save') {
      handleSubmit(event, true);
    } else if (selectedAction === 'publish') {
      handleSubmit(event, false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropIsActive(false);
      }
    };

    if (dropIsActive) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropIsActive]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesResponse = await getCategories(token);
                setCategories(categoriesResponse);
            } catch (error) {
                console.error(error);
            }
        };
        const fetchModels = async () => {
            try {
                const modelsResponse = await getModels(token);
                setModels(modelsResponse);
            } catch (error) {
                console.error(error);
            }
        };        
        fetchCategories();
        fetchModels();        
    }, [token]);

    useEffect(() => {
        const isLogicValid = inputLogic => {
            const missingVariables = inputsFormFields
                .filter(({name}) => !inputLogic.includes(`{${name}}`))
                .map(({name}) => `{${name}}`)
                .join(', ');
            const fileNameMissing = documentChecked && !inputLogic.includes('{FileName}');
        
            let errorMessage = "";
            if (missingVariables) {
                errorMessage += `${languageTexts[language].newAppLogicMissingVariables} ${missingVariables}. `;
            }
            if (fileNameMissing) {
                errorMessage += languageTexts[language].newAppLogicMissingFileName;
            }
            if (errorMessage) {
                errorMessage += languageTexts[language].newAppErrorHelper;
                setErrorMessage(errorMessage);
                return false;
            }
            return true;
        };
        if (logicInput === "" || (inputsFormFields.length === 0 && documentChecked === false)) {
            setErrorMessage(languageTexts[language].newAppNoLogicNorVariables);
        } else if (isLogicValid(logicInput)) {
            setErrorMessage("");
        }
      }, [inputsFormFields, logicInput, documentChecked]);

      const getPlaceholder = () => {
        let placeholder = languageTexts[language].newAppLogicPlaceholder;
        if (documentChecked) {
            placeholder += "{FileName}";
        }
        if (inputsFormFields.length > 0 && !inputsFormFields.every(field => field.name === '')) {
            const variableNames = inputsFormFields.map((field) => `{${field.name}}`).join(', ');
            placeholder += documentChecked ? `, ${variableNames}` : variableNames;
        } else if (!documentChecked) {
            placeholder += "{Variable Name}";
        }
        return placeholder;
    };

    const toggleDropdown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDropIsActive(!dropIsActive);
    };
    return(
        <div className="new-app-columns">
            <section className="column">
                <h1>{languageTexts[language].newAppColumnTitle}</h1>
                <form className="new-app-form">
                    <div className="new-app-form-section">
                        <h2>{languageTexts[language].newAppInfo}</h2>
                        <div className="field">
                            <label>{languageTexts[language].newAppName}</label>
                            <input
                                className="input"
                                type="text"
                                placeholder={languageTexts[language].newAppNamePlaceholder}
                                required
                                onChange={(event) => setAppName(event.target.value)}
                                maxLength={256}
                            />
                        </div>
                        <div className="field">
                            <label>{languageTexts[language].newAppDescription}</label>
                            <textarea
                                className="textarea"
                                placeholder={languageTexts[language].newAppDescriptionPlaceholder}
                                onChange={(event) => setAppDescription(event.target.value)}
                                maxLength={512}
                            ></textarea>
                        </div>
                        <div className="field">
                            <label>{languageTexts[language].newAppCategory}</label>
                            <div className="select-container">
                            <select className="select" value={categorySelected} onChange={(event) => setCategorySelected(event.target.value)}>
                            {categories?.map((option, key) => (
                                <option key={key} value={option.id}>
                                    {option[`name_${language}`]}
                                </option>
                            ))}
                            </select>
                            </div>
                        </div>
                    </div>
                    <div className="new-app-form-section">
                        <h2 className="new-app-form-logic">{languageTexts[language].newAppLogic}</h2>
                        {/* <div className="field is-grouped">
                            <label>Select  AI Model</label>
                            <div className="select-container">
                                <select className="input" value={modelSelected} onChange={(event) => setModelSelected(event.target.value)}>
                                    {models?.map((option, key) => (
                                        <option key={key} value={option.id} disabled={!option.active}>
                                            {option.alias} {!option.active ? '- Coming Soon' : ''}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        {inputsFormFields.map((field, index) => {
                            if (field.name !== '') {
                                return (
                                    <span key={index} className="button new-app-variable-button" onClick={() => handleButtonClick(field.name)}>{field.name}</span>
                                );
                            }
                            return null;
                        })}
                        {documentChecked && (
                            <span className="button new-app-filename-button" onClick={() => handleButtonClick('FileName')}>File Name</span>
                        )}
                        <textarea
                            ref={logicInputRef}
                            className="textarea"
                            placeholder={getPlaceholder()}
                            value={logicInput}
                            onChange={(event) => setLogicInput(event.target.value)}
                            ></textarea>  

                        <div className="field is-grouped">
                            <label>{languageTexts[language].newAppHideLogicButton}</label>
                            <input 
                            type="checkbox" 
                            onChange={() => setShowsPrompt(!showsPrompt)} 
                            checked={!showsPrompt}
                            disabled={user.role === USER}
                            {...(user.role === USER ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}
                            />
                            </div>
                    </div>
                    
                    <div className="new-app-form-section">
                        <h2>{languageTexts[language].newAppVariables}</h2>    

                        <div className={`dropdown ${dropIsActive ? 'is-active' : ''}`} ref={dropdownRef}>
                        <button
                            className="new-app-dropdown-button"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                            onClick={toggleDropdown}
                        >
                            <PlusIcon /> {languageTexts[language].newAppVariables}
                        </button>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <div className="field is-grouped">
                                        <div className="control">
                                            <button
                                            className="button is-info add-variable"
                                            type="button"
                                            onClick={() => handleAddFieldsInputs()}
                                            >
                                                <NextVariable />{languageTexts[language].newAppField}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="field is-grouped">
                                        <div className="control add-file-control">
                                            {documentChecked ?
                                                <div className="file-info-container">
                                                    <div className="file-info">
                                                        <span>{languageTexts[language].newAppFileName}</span>
                                                    </div>
                                                    <button className="delete-file-button" type="button" onClick={handleAddFileInputs}>
                                                        <CancelIcon />
                                                    </button>
                                                </div>
                                                :
                                                <button 
                                                    className="button is-info add-file"
                                                    type="button"
                                                    onClick={handleAddFileInputs}
                                                    disabled={user.role === USER}
                                                    {...(user.role === USER ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}
                                                >
                                                    <FileIcon />   
                                                    {languageTexts[language].newAppFieldInput}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {inputsFormFields.map((field, index) => (
                        <div className="new-app-form-field variables-container" key={index}>
                            <div className="delete-button">
                                {inputsFormFields.length > 0 &&
                                    <button className="remove" type="button" onClick={() => handleRemoveFields(index)}>
                                        <TrashIcon />
                                    </button>
                                }
                            </div>
                            <div className="new-app-form-field-control control is-expanded">
                                <label>{languageTexts[language].newAppVariableName}</label>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder={languageTexts[language].newAppVariableName}
                                    name="name"
                                    value={field.name}
                                    required
                                    onChange={(event) => handleChangeInputs(index, event)}
                                />
                            </div>
                            <div className="new-app-form-field-control control is-expanded">
                                <label>{languageTexts[language].newAppVariableDescription}</label>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder={languageTexts[language].newAppVariableDescription}
                                    name="value"
                                    value={field.value}
                                    onChange={(event) => handleChangeInputs(index, event)}
                                />
                            </div>
                        </div>
                        ))}
                    </div> 
                    <div className="new-app-dropdown">
                        <select
                            className="select is-dark"
                            value={action}
                            onChange={handleSelectChange}
                            disabled={errorMessage || (user.role === USER && action !== '')}
                        >
                            <option value="">{languageTexts[language].newAppSelectStatus}</option>
                            <option value="save" {...(user.role === USER ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}>
                            {languageTexts[language].newAppStatus2}
                            </option>
                            <option value="publish" {...(user.role === USER ? { "data-tooltip": languageTexts[language].useAppCreditUse5 } : {})}>
                            {languageTexts[language].newAppStatus1}
                            </option>
                        </select>
                    </div>
                    <p>{errorMessage}</p>
                </form>
            </section>
            <AppPreview previewInputs={inputsFormFields} initialLogic={logicInput} modelId={modelSelected} documentChecked={documentChecked} />
        </div>
    )
}

export default NewAppContent;