import React, {useState} from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import CredentialsRegister from "./pages/authenticate/register/CredentialsRegister";
import EmailConfirmation from './pages/authenticate/register/EmailConfirmation';
import Login from "./pages/authenticate/login/Login";
import ResetPassword from "./pages/authenticate/resetPassword/ResetPassword";
import ForgotPassword from "./pages/authenticate/forgotPassword/ForgotPassword";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import SubscriptionDetails from './pages/subscriptions/SubscriptionDetails';
import NavBar from "./components/navBar/NavBar";
import Explore from "./pages/explore/Explore";
import NewApp from "./pages/newApp/NewApp";
import UseApp from "./pages/useApp/UseApp";
import EditApp from "./pages/editApp/EditApp";
import MyApp from "./pages/myApp/MyApp";
import TeamSettings from "./pages/team/teamSettings/TeamSettings";
import JoinTeam from "./pages/team/joinTeam/JoinTeam";
import Templates from "./pages/templates/Templates";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import UserSettings from "./pages/userSettings/UserSettings";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import Privacy from "./pages/privacy/Privacy";
// import Dashboard from './pages/dashboard/Dashboard';
import LookerStudioDashboard from './pages/looker/LookerDash';
import ApiKey from './pages/apiKey/ApiKey';
import WorkflowsList from './pages/workflows/WorkflowsList/WorkflowsList';
import CreateWorkflow from './pages/workflows/CreateWorkflow/CreateWorkflow';
import UseWorkflow from './pages/workflows/UseWorkflow/UseWorkflow';
import EditWorkflow from './pages/workflows/EditWorkflow/EditWorkflow';
import Recents from './pages/recents/Recents';
import NewTeam from './pages/newTeam/NewTeam';
import FlipNews from './pages/flipNews/FlipNews';
import { LanguageProvider } from './hooks/languageHook/LanguageHook';
import LearningResources from './pages/learningResources/LearningResources';
import PostRegisterStepOne from './pages/authenticate/postRegister/PostRegisterStepOne';
import FlipperModal from './components/flipperModal/FlipperModal';

const App = () => {
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.session.isLoggingIn);
  const hideNavBarForPaths = [
    "/jointeam",
    "/register/activate",
    "/aeropuerto",
];

const shouldHideNavBar = (path) => {
    if (hideNavBarForPaths.includes(path)) return true;
    if (path.startsWith("/aeropuerto")) return true;
    return false;
};

const showNavBar = !shouldHideNavBar(location.pathname);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [flipperModal, setFlipperModal] = useState(false);

  const openModal = () => {
    setOpenModalInformation(true);
  };
  const openFlipperModal = () => {
    setFlipperModal(true);
  }
  return (
    <LanguageProvider>
    <main className={`${isLoggedIn ? 'flipando' : 'enter-flipando'} ${openModalInformation ? 'modalOpened' : ''}`}>
      {showNavBar && <NavBar openFlipperModal={openFlipperModal} />}
      <Routes>
        <Route path="/" element={isLoggedIn ? <Navigate to="/explore" /> : <Navigate to="/login" />} />
        <Route path="/explore" element={isLoggedIn ? <Explore /> : <Navigate to="/login" />} />
        <Route path="/explore/:app" element={isLoggedIn ? <UseApp /> : <Navigate to="/login" />} />
        <Route path="/explore/:app/edit" element={isLoggedIn ? <EditApp /> : <Navigate to="/login" />} />
        <Route path="/newapp" element={isLoggedIn ? <NewApp /> : <Navigate to="/login" />} />
        <Route path="/settings" element={isLoggedIn ? <UserSettings /> : <Navigate to="/login" />} />
        <Route path="/templates" element={isLoggedIn ? <Templates /> : <Navigate to="/login" />} />
        <Route path="/templates/:app" element={isLoggedIn ? <UseApp />  : <Navigate to="/login" />} />
        <Route path="/workspace" element={isLoggedIn ? <MyApp /> : <Navigate to="/login" />}>
          <Route path="teams" element={'<></>'} />
          <Route path="favorites" element={<></>} />
          <Route path="myapps" element={<></>} />
        </Route>
        <Route path="/workspace/:app" element={isLoggedIn ? <UseApp /> : <Navigate to="/login" />} />
        <Route path="/workspace/:app/edit" element={isLoggedIn ? <EditApp /> : <Navigate to="/login" />} />
        <Route path="/myworkflows/:workflow" element={isLoggedIn ? <UseWorkflow /> : <Navigate to="/login" />} />
        <Route path="/myworkflows/:workflow/edit" element={isLoggedIn ? <EditWorkflow /> : <Navigate to="/login" />} />
        <Route path="/myworkflows" element={isLoggedIn ? <WorkflowsList /> : <Navigate to="/login" />} />
        <Route path="/newworkflow" element={isLoggedIn ? <CreateWorkflow /> : <Navigate to="/login" />} />
        <Route path="/team" element={isLoggedIn ? <NewTeam /> : <Navigate to="/login" />} />
        <Route path="/teamsettings" element={isLoggedIn ? <TeamSettings /> : <Navigate to="/login" />} />
        <Route path="/jointeam" element={<JoinTeam />} />
        <Route path="/team/:app" element={isLoggedIn ? <UseApp /> : <Navigate to="/login" />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login/>}  />
        <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <CredentialsRegister openInformationModal={openModal} />} />
        <Route path="/register/activate" element={isLoggedIn ? <Navigate to="/" /> : <EmailConfirmation openInformationModal={openModal} />} />
        <Route path="/resetpassword" element={isLoggedIn ? <Navigate to="/" /> : <ResetPassword />} />
        <Route path="/forgotPassword" element={isLoggedIn ? <Navigate to="/" /> : <ForgotPassword />} />
        <Route path="/subscriptions" element={isLoggedIn ? <Subscriptions /> : <Navigate to="/login" />} />
        <Route path="/subscriptions/details" element={isLoggedIn ? <SubscriptionDetails /> : <Navigate to="/login" />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        {/* <Route path='/dashboard' element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} /> */}
        <Route path='/dashboard' element={isLoggedIn ? <LookerStudioDashboard /> : <Navigate to="/login" />} />
        <Route path='/appgenerator' element={isLoggedIn ? <UseApp /> : <Navigate to="/login" />} />
        <Route path='/apikey' element={isLoggedIn ? <ApiKey /> : <Navigate to="/login" />} />
        <Route path='/learningresources' element={isLoggedIn ? <LearningResources /> : <Navigate to="/login" />} />
        <Route path='/recents' element={isLoggedIn ? <Recents /> : <Navigate to="/login" />} />
        <Route path='/news' element={isLoggedIn ? <FlipNews /> : <Navigate to="/login" />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      {openModalInformation && <PostRegisterStepOne closeModal={() => setOpenModalInformation(false)} />}
      {flipperModal && <FlipperModal setFlipperModal={setFlipperModal} />}
      <div id="modal-root"></div>
      
    </main>
    </LanguageProvider>
  );
}

export default App;
