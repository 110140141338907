import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactMultiEmail } from 'react-multi-email';
import './teamSettings.scss';
import 'react-multi-email/dist/style.css';
import { getTeamById, editTeam, addUsersToTeam, getTeamUsers, deleteUserTeam, getTeamPendingUsers, promoteUser } from "../../../api/teams";
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import TopBar from "../../../components/navBar/TopBar";

const TeamSettings = () => {
    const token = useSelector((state) => state.session.token);
    const user = useSelector((state) => state.session.userInfo);
    const [team, setTeam] = useState();
    const [teamUsers, setTeamUsers] = useState();
    const [pendingUsers, setPendingUsers] = useState();
    const [, setFocused] = useState();
    const [emails, setEmails] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputData, setInputData] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(); 
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [pendingRole, setPendingRole] = useState(null);
    const [userToChange, setUserToChange] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowWidth <= 768;
  
    const handleChange = (e, teamUser) => {
        setPendingRole(e.target.value);
        setUserToChange(teamUser);
        setShowConfirmationModal(true);
    };
  
    const handleConfirm = async () => {
        try {
            await promoteUser(userToChange.id, team.id, token);
            const updatedTeamUsers = teamUsers.map(user => {
                if (user.id === userToChange.id) {
                    return { ...user, role_id: parseInt(pendingRole) };
                }
                return user;
            });
            setTeamUsers(updatedTeamUsers);
            setShowConfirmationModal(false);
        } catch (error) {
            console.error("Error promoting user:", error);
        }
    };
    
    const handleCancel = () => {
      setPendingRole(null);
      setShowConfirmationModal(false);
    };

    const teamData = {
        teamName: team?.name,
        teamColor: team?.color,
      };
     useEffect(() => {
        const fetchTeam = async () => {
            try {
                const teamResponse = await getTeamById(user.team);
                setTeam(teamResponse);
            } catch (error) {
                setErrorMessage(error);
            }
        };
        fetchTeam();
    }, [token, user.team]);
    
    useEffect(() => {
        const fetchTeamUsers = async () => {
            try {
                if (team && team.id) {
                    const teamUsersResponse = await getTeamUsers(team.id, token);
                    setTeamUsers(teamUsersResponse);
                }
            } catch (error) {
                setErrorMessage(error);
            }
        };
        const fetchTeamPendingUsers = async () => {
            try {
                if (team && team.id) {
                    const teamPendingUsersResponse = await getTeamPendingUsers(team.id, token);
                    setPendingUsers(teamPendingUsersResponse);
                }
            } catch (error) {
                setErrorMessage(error);
            }
        };
        if (team) {
            fetchTeamUsers();
            fetchTeamPendingUsers();
        }
    }, [team, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
          name: inputData.teamName,
          color: inputData.teamColor
        }
    
        const editTeamForm = Object.entries({ ...data})
        .reduce((acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});

        if (Object.keys(editTeamForm).length !== 0) {
          try {
            const response = await editTeam(team.id, editTeamForm, token);
            console.log(response);
            alert("Data successfully updated!");
            window.location.reload();
          } catch (error) {
            setErrorMessage(error.message);
          }
        }
      };

      const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await addUsersToTeam(team.id, emails, token);
          console.log(response);
          alert("Invitations sent!");
          window.location.reload();
        } catch (error) {
          setErrorMessage(error.message);
        }
      }

      const newTeamData = Object.entries(teamData).map(([key, value]) => {
        let placeHolder = "";
        let label = "";
    
        if (key === "teamName") {
          placeHolder = "Enter new team name";
          label = "Team Name";
        } else if (key === "teamColor") {
          placeHolder = "Enter new team color";
          label = "Team Color";
        }
    
        return {
          placeHolder,
          value: inputData[key] || value,
          label,
          key
        };
      }).filter(obj => obj.key !== "profileImage").filter(obj => obj.key !== "subscribenewsletter");

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputData(prevData => ({ ...prevData, [name]: value }));
      };
      const confirmDeleteUser = (userId, teamId) => {
        deleteUserTeam(userId, teamId, token)
            .then(async () => {
                setShowConfirmation(false);
                const teamUsersResponse = await getTeamUsers(team.id, token);
                setTeamUsers(teamUsersResponse);
            })
            .catch((error) => {
                console.error(error);
            });
      }
      const roleName = (roleId) => {
        if(roleId === 3 || roleId === 1) return "Admin" 
        if(roleId === 4) return "Member" 
    }
    return(
        <main className="teamSettings">
            {!isMobile && <TopBar hasNavegation />}
            {showConfirmationModal &&
                <div className="background-modal"></div>
            }

            {(user.role === 1 || user.role === 3) &&
            <section className="teamSettings-form">       
                <form onSubmit={handleSubmit} className="teamSettings-form-body">
                    {newTeamData?.map((prop, index) => {
                        return (
                            <div key={index} className="field">
                            {prop.key === 'teamColor' ? (
                                <>
                                <h3>{prop.label}</h3>
                                <div className="settings-form-field">
                                    <label>{prop.value}</label>
                                    <input
                                    type="color"
                                    className="input"
                                    name={prop.key}
                                    value={prop.value || ''}
                                    placeholder={prop.placeHolder}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </>
                            ) : (
                                <>
                                <h3>{prop.label}</h3>
                                <div className="settings-form-field">
                                    <label>{prop.value}</label>
                                    <input
                                    type="text"
                                    className="input"
                                    name={prop.key}
                                    value={inputData[prop.key] || ''}
                                    placeholder={prop.placeHolder}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </>
                            )}
                            </div>
                        );
                    })}
                    
                    <p className="errorMessage">{errorMessage}</p>
                    <div className="control teamSettings-form-footer">
                        <button className="button teamSettings-form-footer-button">Submit</button>
                    </div>
                </form>
                <form onSubmit={handleEmailSubmit} className="teamSettings-form-body">
                    <div className="field">
                        <h3>Invite Members</h3>
                        <ReactMultiEmail
                            placeholder='Input your email'
                            emails={emails}
                            onChange={(newEmails) => {
                                setEmails(newEmails);
                            }}
                            autoFocus={true}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            getLabel={(email, index, removeEmail) => {
                            return (
                                <div data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                                </div>
                            );
                            }}/>
                        </div>

                    <div className="control teamSettings-form-footer">
                        <button className="button teamSettings-form-footer-button">Send Invitations</button>
                    </div>
                </form>
            </section>
            }  
            <section className="teamSettings-form-bottom">
                <div className="teamSettings-form-body">
                      <div className="field">
                          <h3>Team Members</h3>
                          <table className="table teamSettings-form-body-table">
                              <thead>
                                  <tr>
                                      <th>User Name</th>
                                      <th>Date</th>
                                      <th>Role</th>
                                      <th>Status</th>
                                      {(user.role === 1 || user.role === 3) &&
                                        <th> </th>
                                      }
                                  </tr>
                              </thead>
                              <tbody>
                              { teamUsers?.map((teamUser) => (
                                  <tr key={teamUser.id}>
                                      <td className={teamUser.id === user.userId ? 'currentUser' : ''}>{teamUser.username}</td>
                                      <td>{new Date(teamUser.creation_date).toDateString()}</td>
                                      <td>
                                        {teamUser.role_id ===  4 && (user.role === 1 || user.role === 3)  ?
                                        <select className="select" value={teamUser.role_id} onChange={(e) => handleChange(e, teamUser)}>
                                            <option value="3">Admin</option>
                                            <option value="4">Member</option>
                                        </select>
                                        :
                                        roleName(teamUser.role_id)
                                        }
                                        </td>
                                      <td>ACTIVE</td>
                                      {(user.role === 1 || user.role === 3) &&
                                        <td className="teamSettings-form-body-table-actions">
                                            <button 
                                            className="teamSettings-form-body-table-actions-button" 
                                            {...((teamUser.role_id === 1) || (teamUser.role_id === 3) ? { "data-tooltip": "You cannot delete an Admin" } : {})}
                                            onClick={() => {
                                                setUserIdToDelete(teamUser.id);
                                                setShowConfirmation(true);
                                            }}
                                            disabled={(teamUser.role_id === 1) || (teamUser.role_id === 3)}
                                        >
                                            <DeleteIcon />
                                        </button>
                                        {showConfirmation && userIdToDelete && (
                                            <ConfirmationDialog
                                                message="Are you sure you want to delete this User from the team?"
                                                onConfirm={() => confirmDeleteUser(userIdToDelete, team.id)}
                                                onCancel={() => setShowConfirmation(false)}
                                                />
                                            )}
                                    </td>
                                      
                                      }
                                  </tr>               
                              ))}
                              { pendingUsers?.map((teamUser) => (
                                  <tr key={teamUser.id}>
                                        <td className={teamUser.id === user.userId ? 'currentUser' : ''}>{teamUser.recipient_email}</td>
                                        <td>{new Date(teamUser.sent_timestamp).toDateString()}</td>
                                        <td>-</td>
                                        <td>{teamUser.status}</td>
                                        <td className="teamSettings-form-body-table-actions">
                                            -
                                        </td>
                                  </tr>               
                              ))}
                            </tbody>
                        </table>
                      </div>
                    <p className="errorMessage">{errorMessage}</p>
                </div>
                {showConfirmationModal && (
                    <div className="modal teamSettings-modal">
                    <p>¿Estás seguro de que deseas cambiar el rol a {roleName(parseInt(pendingRole))}?</p>
                    <button onClick={handleConfirm} className="button is-success is-light">Confirmar</button>
                    <button onClick={handleCancel} className="button is-danger is-light">Cancelar</button>
                </div>
                )}
            </section>
        </main>
    )
}

export default TeamSettings;