//LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

//REGISTER
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

//USER
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const INITIALIZE_USER = 'INITIALIZE_USER';
export const UPDATE_CREDITS = 'UPDATE_CREDITS';