import axios from "axios";


export async function createSubscription(token, subscriptionId, planId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  const body = {
    paypal_subscription_id: subscriptionId,
    plan_id: planId
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/`, body, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function getSubscription(subscriptionId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/${subscriptionId}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function pauseSubscription(token, subscriptionId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/${subscriptionId}/pause`, {}, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};


export async function activateSubscription(token, subscriptionId) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/${subscriptionId}/activate`, {}, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};


export async function cancelSubscription(token, subscriptionId, forfeit = false) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/${subscriptionId}/cancel${forfeit ? '?forfeit=true' : ''}`;  
  try {
    const response = await axios.post(url, {}, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};


export async function getActiveSubscription(token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/subscriptions`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function contactUs(name, email, companyName, message, token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  const body = {
    name: name,
    email: email,
    company_name: companyName,
    message: message
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/contact-us`, body, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function feedbackEmail(name, email, message, token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  const body = {
    name: name,
    email: email,
    message: message
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscriptions/feedback-email`, body, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
