import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/newDesign/search-icon.svg';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import './searcher.scss';

function Searcher({ onSearch }) {
  const [query, setQuery] = useState('');
  const { language } = useLanguage();

  const handleQueryChange = event => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    onSearch(newQuery);
  };
  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <form className='searcher' onSubmit={handleSubmit}>
      <div className="control has-icons-right">
        <SearchIcon />
        <input className="input" type="text" value={query} onChange={handleQueryChange} placeholder={languageTexts[language].search} />
      </div>
    </form>
  );
}

export default Searcher;
