import React,{ useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { Link } from 'react-router-dom';
import "./recents.scss";

import { getUserAppsUsed } from "../../api/applications";
import { getUserFavoriteAppIds } from "../../api/user";

import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import Searcher from "../../components/searcher/Searcher";
import languageTexts from '../../languajeConstant';
import CategoriesButtons from "../../components/categoriesButtons/CategoriesButtons";
import ExtendedCarousel from "../../components/extendedCarousel/ExtendedCarousel";

import TopBar from "../../components/navBar/TopBar";
import AllAppsSection from "../../components/allAppsSection/AllAppsSection";


const Recents = () => {
    const token = useSelector((state) => state.session.token);
    const userInfo = useSelector((state) => state.session.userInfo);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const [apps, setApps] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [filteredApps, setFilteredApps] = useState(null);
    const [favoriteAppIds, setFavoriteAppIds] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showAllApps, setShowAllApps ] = useState(false);
    

    const { language } = useLanguage();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowWidth <= 768;
    useEffect(() => {
      async function fetchData() {
        try {
          const favoriteAppsResponse = await getUserFavoriteAppIds(token);
          const res = await getUserAppsUsed(token);
          const newApps = res.results;
          setApps(newApps);
          setFilteredApps(newApps);
          setFavoriteAppIds(favoriteAppsResponse);
        } catch (error) {
          setErrorMessage("Error to load the apps. " + error.message);
        }
      }
      fetchData();
    }, [userInfo.team, token]);
    
    const handleSearch = (query) => {
      setSearchQuery(query);
      applyFilters(query, selectedCategory);
    };

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const applyFilters = (query, category) => {
      let filtered = apps;
      if (query) {
        const normalizedQuery = removeAccents(query.toLowerCase());
        filtered = filtered.filter((app) =>
            removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
        );
      }
      if (category) {
        filtered = filtered.filter((app) => app.category[`name_${language}`] === category);
      }
      setFilteredApps(filtered);
    };
    useEffect(() => {
      let newFilteredApps = apps ? [...apps] : [];
      if (selectedCategory) {
        newFilteredApps = newFilteredApps.filter((app) => app.category[`name_${language}`] === selectedCategory);
      }
      if (searchQuery) {
        const normalizedQuery = removeAccents(searchQuery.toLowerCase());
        newFilteredApps = newFilteredApps.filter((app) =>
          removeAccents(app.name.toLowerCase()).includes(normalizedQuery) ||
          removeAccents(app.category[`name_${language}`].toLowerCase()).includes(normalizedQuery) ||
          removeAccents(app.user.username.toLowerCase()).includes(normalizedQuery)
        );
      }
      setFilteredApps(newFilteredApps);
    }, [apps, selectedCategory, searchQuery, userInfo.id, userInfo.userId]);
  
    return (
      <main className="recents">
      {!isMobile && <TopBar hasNavegation />}
      {!apps || apps.length === 0 ?
        <section className="recents-container notapps">
          <p>{languageTexts[language].recentNotApps}</p>
          <p>{languageTexts[language].recentNotAppsSubtitle}</p>

          <Link className="button newTeam-missing-btn" to="/explore">
                {languageTexts[language].recentNotAppsBtn}
          </Link>
        </section>
      :
      
      <section className="recents-container">
        <ErrorMessage message={errorMessage} />
        <header className="recents-header">
          <Searcher onSearch={handleSearch} placeholder="Search" />
        </header>
        <CategoriesButtons 
          token={token} 
          applyFilters={applyFilters} 
          searchQuery={searchQuery} 
          setSelectedCategory={setSelectedCategory} 
          selectedCategory={selectedCategory}
          labelText={languageTexts[language].exploreCategoryList} />
          { showAllApps ?
            <AllAppsSection mostUsedApps={apps} recentApps={apps} exploreMostUsedText={'Apps'} exploreRecentText={'Workflows'} />
          :
            <section className="recents-most-used">
              <h2 className="recents-subtitle">
                {languageTexts[language].recentTitle}
                <button className="button recents-subtitle-button" onClick={() => setShowAllApps(true)}>{languageTexts[language].exploreShowAll}</button>
              </h2>
              <ExtendedCarousel cards={filteredApps} favoriteApps={favoriteAppIds} />
            </section>
          }
      </section>
      }
      </main>
    );
  };
  
export default Recents;