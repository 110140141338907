const initialState = {
    session: {
        token: localStorage.getItem("flipandoToken"),
        isLoggingIn: localStorage.getItem("flipandoToken") ? true : false,
        loginError: null,
        userInfo: {
            userId: '',
            userName: '',
            email: '',
            role: '',
            team: '',
            phoneNumber: '',
            profileImage: '',
            subscribenewsletter: false,
            credits: ''
        }
    }
};

export default initialState;