const exploreTexts = {
    es: {
        exploreTitle: 'Comienza ahora',
        exploreCategoryList: 'Seleccionar categoria',
        exploreMostUsed: 'Más usadas',
        exploreRecent: 'Recientes  en Flipando:',
        exploreShowAll: 'Mostrar todas',
        exploreCreateApp: 'Crear \nnueva app',
        exploreCreateAppSubtitle: 'Crea una app desde cero o empieza desde una plantilla',
        exploreFlipper: 'Prueba \nFlipper',
        exploreFlipperSubtitle: 'Maximiza tu experiencia con Flipper.',
        exploreAppGenerator: 'Generador \nde apps',
        exploreAppGeneratorSubtitle: 'Genera una app especialmente para ti con simpletext o audio.',
        exploreSuperApps: 'Super \nApps',
        exploreSuperAppsSubtitle: 'Apps con características especiales.',
        exploreMostRecent: 'Más recientes',
        exploreTemplateTitle: 'Descubre las plantillas',
    },
    en: {
        exploreTitle: 'Get started',
        exploreCategoryList: 'Select tags',
        exploreMostUsed: 'Most Used',
        exploreRecent: 'Recent in Flipando:',
        exploreShowAll: 'Show all',
        exploreCreateApp: 'Create \nNew App',
        exploreCreateAppSubtitle: 'Create an app from blank or start from a template',
        exploreFlipper: 'Try \nFlipper',
        exploreFlipperSubtitle: 'Maximize your experience with Flipper.',
        exploreAppGenerator: 'App \nGenerator',
        exploreAppGeneratorSubtitle: 'Generates an app especially for you with simpletext or audio.',
        exploreSuperApps: 'Super \nApps',
        exploreSuperAppsSubtitle: 'Apps with special features.',
        exploreMostRecent: 'Most recent',
        exploreTemplateTitle: 'Discover the templates',
    },
};

export default exploreTexts;
