import React from "react";
import Avatar from "react-avatar";

function UserAvatar( {name} ) {
  const letter = name.charAt(0).toUpperCase();

  return (
    <Avatar
      name={name}
      color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
      size="30"
      round={true} 
    >
      {letter}
    </Avatar>
  );
}

export default UserAvatar;
