import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { contactUs } from '../../api/subscriptions';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';


const ContactUsModal = ({handleCancelButton}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("")
    const token = useSelector((state) => state.session.token);
    const { language } = useLanguage();

    const handleSubmit = (e) => {
        e.preventDefault();
        contactUs(name, email, companyName, message, token)
            .then(() => {
                alert(languageTexts[language].subscriptionsContactUsModalSuccess);
                handleCancelButton();
            })
            .catch(error => {
                setErrorMessage('Error sending contact-us email. ' + error.message);
                console.error('Something went wrong when sending contact-us email. ' + error.message);
            });
    };
  return (
    <div className="modal subscription-modal">
        <h2>{languageTexts[language].subscriptionsContactUsModalTitle}</h2>
        <button onClick={handleCancelButton} className="modal-close is-large">{languageTexts[language].subscriptionsContactUsModalCancel}</button>
        <form onSubmit={handleSubmit}>
            <div className="field">
            <label>{languageTexts[language].subscriptionsContactUsModalName}</label>
            <input
                className="input"
                type="text"
                placeholder={languageTexts[language].subscriptionsContactUsModalNamePlaceholder}
                value={name} 
                onChange={(e) => setName(e.target.value)}
                required
            />
            </div>
            <div className="field">
            <label>{languageTexts[language].subscriptionsContactUsModalEmail}</label>
            <input
                className="input"
                type="email"
                placeholder={languageTexts[language].subscriptionsContactUsModalEmailPlaceholder}
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            </div>
            <div className="field">
            <label>{languageTexts[language].subscriptionsContactUsModalCompanyName}</label>
            <input
                className="input"
                type="text"
                placeholder={languageTexts[language].subscriptionsContactUsModalCompanyNamePlaceholder}
                value={companyName} 
                onChange={(e) => setCompanyName(e.target.value)}
                required
            />
            </div>
            <div className="field">
            <label>{languageTexts[language].subscriptionsContactUsModalMessage}</label>
            <input
                className="input"
                type="text"
                placeholder={languageTexts[language].subscriptionsContactUsModalMessagePlaceholder}
                value={message} 
                onChange={(e) => setMessage(e.target.value)}
                required
            />
            </div>
            <button className="submit" type="submit">{languageTexts[language].subscriptionsContactUsModalSend}</button>
            <p>{errorMessage}</p>
        </form>
    </div>
  );
}

export default ContactUsModal;
