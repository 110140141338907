import React from "react";

import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';

import './liveAppModal.scss';


const LiveAppModal = ({ onEditPage, setLiveAppModal, onConfirm }) => {
  const { language } = useLanguage();  

  const handleCloseLiveAppModal = () => {
    setLiveAppModal(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <>
      <div className="modal-background"></div>
      <section className="live-app-modal">
        <h1>{onEditPage ? languageTexts[language].liveAppOnEditModal : languageTexts[language].liveAppEditModal} </h1>
        <button onClick={handleCloseLiveAppModal} className="modal-close is-large">{languageTexts[language].flipperModalCancelButton}</button>
        <div className="live-app-modal-content">
          <p>{onEditPage ? languageTexts[language].liveAppOnEditContent1 : languageTexts[language].liveAppEditContent1}</p>
          <p>{languageTexts[language].liveAppEditContent2}</p>
          <p>{onEditPage ? languageTexts[language].liveAppOnEditContent3 : languageTexts[language].liveAppEditContent3}</p>
          
        </div>
        <form className="live-app-modal-form">
          <div className="live-app-modal-form-buttons">
            <button type="button" className="button live-app-modal-cancel" onClick={handleCloseLiveAppModal}>{languageTexts[language].liveAppDiscardButton}</button>
            <button type="button" className="button live-app-modal-submit" onClick={handleSubmit}>{languageTexts[language].liveAppSubmitButton}</button>
          </div>
        </form>
      </section>
    </>
  );
};

export default LiveAppModal;