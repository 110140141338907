import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserApps } from '../../../api/applications';
import { getWorkflow, updateWorkflow } from '../../../api/workflow';
import { ReactComponent as CancelIcon } from '../../../assets/navBarIcons/close-icon.svg';
import TopBar from "../../../components/navBar/TopBar";
import Searcher from "../../../components/searcher/Searcher";
import { useLanguage } from '../../../hooks/languageHook/LanguageHook';
import languageTexts from "../../../languajeConstant";
import CardWorkflow from './CardWorkflow';
import './editWorkflow.scss';


function EditWorkflow() {
    const [workflowId, setWorkflowId] = useState(null);
    const token = useSelector((state) => state.session.token);
    const [errorMessage, setErrorMessage] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const { pathname } = location;
    const { language } = useLanguage();
    const [uploadedFiles, setUploadedFiles] = useState({});
    const fileInputRefs = useRef({});
    const [hasStartApp, setHasStartApp] = useState(true);
    const [itemsToShow, setItemsToShow] = useState(5);
    const [changesMade, setChangesMade] = useState(false);
    const [workflow, setWorkflow] = useState(null);
    const [workflowName, setWorkflowName] = useState('');
    const [workflowDescription, setWorkflowDescription] = useState('');
    const [currentSelectedApps, setCurrentSelectedApps] = useState([]);
    const [currentWorkflowName, setCurrentWorkflowName] = useState('');
    const [currentWorkflowDescription, setCurrentWorkflowDescription] = useState('');
    const [selectedApps, setSelectedApps] = useState([]);
    const [apps, setApps] = useState([]);
    const [filteredApps, setFilteredApps] = useState([]);

    const navigate = useNavigate();


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowWidth <= 768;

    useEffect(() => {
        const parts = pathname.split('/');
        const parsedWorkflowId = parseInt(parts[parts.length - 2], 10);
        if (!isNaN(parsedWorkflowId)) {
            setWorkflowId(parsedWorkflowId);
        }
    }, [pathname]);


    useEffect(() => {
        if (workflowId) {
            const fetchWorkflow = async () => {
                try {
                    const workflowResponse = await getWorkflow(token, workflowId);
                    setWorkflow(workflowResponse);
                    setWorkflowName(workflowResponse.name);
                    setWorkflowDescription(workflowResponse.description);

                    const userApps = await getUserApps(token);
                    setApps(userApps.results);
                    setFilteredApps(userApps.results);

                    const initialSelectedApps = workflowResponse.structure.map((step) => {
                        const app = userApps.results.find((app) => app.id === step.app_id);
                        return {
                            ...app,
                            step_inputs: step.inputs
                        };
                    });

                    setCurrentSelectedApps(JSON.parse(JSON.stringify(initialSelectedApps)));
                    setSelectedApps(JSON.parse(JSON.stringify(initialSelectedApps)));

                    setCurrentWorkflowName(workflowResponse.name);
                    setCurrentWorkflowDescription(workflowResponse.description);

                }
                catch (error) {
                    console.error(error);
                    setErrorMessage(error.message);
                }
            };
            fetchWorkflow();
        }
    }, [token, workflowId]);

    const removeStep = (stepNumber) => {
        const newSelectedApps = selectedApps.filter((_, index) => index !== stepNumber);
        if (newSelectedApps.length === 0) {
            setHasStartApp(false);
        }
        setSelectedApps(newSelectedApps);
        setChangesMade(true);
    };

    const validateOutputConnections = () => {
        const requiredOutputs = selectedApps.slice(0, -1).map((_, index) => `step_output_${index + 1}`);
        const stepOutputValues = selectedApps.flatMap(app =>
            Object.entries(app.step_inputs).flatMap(([inputName, inputDetail]) =>
                inputDetail.type === "step_output" ? `step_output_${inputDetail.value}` : []
            )
        );
        const allElementsPresent = requiredOutputs.every(element =>
            stepOutputValues.includes(element)
        );

        return allElementsPresent;
    };

    const handleEditWorkflow = async () => {
        if (!validateOutputConnections()) {
            setErrorMessage(languageTexts[language].editWorkflowError);
            return;
        }
        try {
            const workflowSteps = selectedApps.map((app, index) => {
                return {
                    app_id: app.id,
                    step: index + 1,
                    inputs: app.step_inputs
                };
            });
            const workflow = await updateWorkflow(workflowId, workflowName, workflowDescription, 1, workflowSteps, token);
            navigate(`/myworkflows/${workflow.id}`);
            setErrorMessage("");

        } catch (error) {
            console.error(error);
            setErrorMessage(error.message);
        }
    }

    const handleSearch = (searchText) => {
        if (searchText) {
            const filtered = apps.filter((app) => app.name.toLowerCase().includes(searchText.toLowerCase()));
            setFilteredApps(filtered);
            setItemsToShow(5);
        } else {
            setFilteredApps(apps);
        }
    };

    const handleLoadMore = (event) => {
        event.preventDefault();
        setItemsToShow(itemsToShow + 5);
    };

    const handleDiscardChanges = () => {
        navigate(`/myworkflows/${workflowId}`);
    }

    useEffect(() => {
        if (isEqual(selectedApps, currentSelectedApps)) {
            setChangesMade(false);
        } else {
            setChangesMade(true);
        }
        if (workflowName !== currentWorkflowName || workflowDescription !== currentWorkflowDescription) {
            setChangesMade(true);
        }
    }, [selectedApps, currentSelectedApps, workflowName, workflowDescription]);

    return (
        <section className='edit-workflow'>
            {!isMobile && <TopBar hasNavegation />}
            <section className='edit-workflow-content'>
                <header className="edit-workflow-content-header">
                    <div className='edit-workflow-content-actions'>
                        {changesMade
                            && <button className='edit-workflow-content-actions-delete' data-tooltip={languageTexts[language].editWorkflowDiscardChanges} onClick={handleDiscardChanges}><CancelIcon /></button>
                        }
                    </div>
                    <h1 className="edit-workflow-content-header-title">{changesMade ? `${workflowName} ${languageTexts[language].editWorkflowEditedFlag}` : workflowName}</h1>
                    <p className="edit-workflow-content-header-description">{workflowDescription}</p>
                </header>
                <form className='edit-workflow-content-body-form'>
                    <div className='edit-workflow-content-body-form-section'>
                        <h2>{languageTexts[language].editWorkflowInfo}</h2>
                        <div className="field center-content">
                            <label>{languageTexts[language].editWorkflowName}</label>
                            <input
                                className="input"
                                type="text"
                                value={workflowName}
                                placeholder={languageTexts[language].editWorkflowName}
                                required
                                onChange={(event) => setWorkflowName(event.target.value)}
                                maxLength={256}
                            />
                        </div>
                        <div className="field">
                            <label>{languageTexts[language].editWorkflowDescription}</label>
                            <textarea
                                className="textarea"
                                value={workflowDescription}
                                placeholder={languageTexts[language].editWorkflowDescription}
                                onChange={(event) => setWorkflowDescription(event.target.value)}
                                maxLength={512}
                            ></textarea>
                        </div>
                    </div>
                    {selectedApps.length > 0 &&
                        selectedApps.map((app, index) => (
                            <CardWorkflow
                                workflowStep={index}
                                app={app}
                                inWorkflow={true}
                                canDelete={true}
                                selectedApps={selectedApps}
                                setSelectedApps={setSelectedApps}
                                handleDeleteApp={removeStep}
                                fileInputRefs={fileInputRefs}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                setError={setErrorMessage}
                            />
                        ))
                    }
                    {selectedApps.length > 1 &&
                        <div className='new-workflow-content-body-form-submit'>
                            <p>{languageTexts[language].editWorkflowCreateMsg}</p>
                            <div className='new-workflow-content-body-form-submit-buttons'>
                                <button
                                    className='button'
                                    type='button'
                                    disabled={changesMade ? false : true}
                                    onClick={handleEditWorkflow}>
                                    {languageTexts[language].editWorkflowEditButton}
                                </button>
                            </div>
                        </div>
                    }
                    {errorMessage && <p className='message is-danger'>{errorMessage}</p>}
                    {hasStartApp ?
                        <div className='new-workflow-content-body-form-section'>
                            <h2>{languageTexts[language].editWorkflowSelectedApp}</h2>
                            <Searcher onSearch={handleSearch} />
                            {filteredApps?.slice(0, itemsToShow).map((app, key) => (
                                <CardWorkflow
                                    firstSelected={!hasStartApp}
                                    app={app}
                                    inWorkflow={false}
                                    canDelete={false}
                                    setHasStartApp={setHasStartApp}
                                    selectedApps={selectedApps}
                                    setSelectedApps={setSelectedApps}
                                    fileInputRefs={fileInputRefs}
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                    setError={setErrorMessage}
                                />
                            ))}
                            <div className='new-workflow-content-body-form-section-load-more'>
                                {filteredApps && filteredApps.length > itemsToShow && (
                                    <button className="button" onClick={handleLoadMore}>
                                        {languageTexts[language].editWorkflowLoadMoreButton}
                                    </button>
                                )}
                            </div>
                        </div>
                        :
                        <div className='new-workflow-content-body-form-section'>
                            <h2>{languageTexts[language].editWorkflowSelectFirstApp}</h2>
                            <Searcher onSearch={handleSearch} />
                            {filteredApps?.slice(0, itemsToShow).map((app, key) => (
                                <CardWorkflow
                                    firstSelected={true}
                                    app={app}
                                    inWorkflow={false}
                                    canDelete={false}
                                    setHasStartApp={setHasStartApp}
                                    selectedApps={selectedApps}
                                    setSelectedApps={setSelectedApps}
                                    fileInputRefs={fileInputRefs}
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                    setError={setErrorMessage}
                                />
                            ))}
                            <div className='new-workflow-content-body-form-section-load-more'>
                                {filteredApps && filteredApps.length > itemsToShow && (
                                    <button className="button" onClick={handleLoadMore}>
                                        {languageTexts[language].editWorkflowLoadMoreButton}
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                </form>
            </section>
        </section>
    );
}

export default EditWorkflow;
