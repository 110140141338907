const apiKeyTexts = { 
    es: {
        apiKeyTitle: 'Claves de API',
        apiKeySubtitle: 'Tus claves secretas de API: Trata tus claves de API con el mayor cuidado. Nunca las compartas con otros y evita exponerlas en tu navegador o en espacios públicos. La seguridad de tus datos es nuestra prioridad.',
        apiKeyInformation: 'Nombre',
        apiKeyInformation2: 'Clave',
        apiKeyInformation3: 'Creada',
        apiKeyInformation4: 'Último uso',
        apiKeyDelete: '¿Estás seguro de que quieres eliminar esta ApiKey?',
        apiKeyCreate: 'Crear nueva clave secreta',
        apiKeyCopy: '¡Texto copiado al portapapeles!',
        apiKeyDocsLink: 'Leer la documentación de la API',
    },
    en: {
        apiKeyTitle: 'API Keys',
        apiKeySubtitle: 'Your Secret API Keys: Please treat your API keys with the utmost care. Never share them with others, and avoid exposing them in your browser or public spaces. Your data security is our priority.',
        apiKeyInformation: 'Name',
        apiKeyInformation2: 'Key',
        apiKeyInformation3: 'Created',
        apiKeyInformation4: 'Last Used',
        apiKeyDelete: 'Are you sure you want to delete this ApiKey?',
        apiKeyCreate: 'Create New Secret Key',
        apiKeyCopy: 'Text copied to clipboard!',
        apiKeyDocsLink: 'Read the API Documentation',
    }
}

export default apiKeyTexts;