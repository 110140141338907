import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { Link } from 'react-router-dom';
import "./templates.scss";
import { getApps } from "../../api/applications";
import { getUserFavoriteAppIds } from "../../api/user";

import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import AppCardMini from '../../components/appCardMini/AppCardMini';
import Loader from "../../components/loader/Loader";
import CategoriesButtons from "../../components/categoriesButtons/CategoriesButtons";
import TopBar from '../../components/navBar/TopBar';
import languageTexts from '../../languajeConstant';
import Searcher from "../../components/searcher/Searcher";

const Templates = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const token = useSelector((state) => state.session.token);

  const [apps, setApps] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredApps, setFilteredApps] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [areFavoriteAppsFetched, setAreFavoriteAppsFetched] = useState(false);
  const [favoriteApps, setFavoriteApps] = useState([]);

  const { language } = useLanguage();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  useEffect(() => {
    async function fetchData() {
        try {
            const templateApps = await getApps(token);
            setApps(templateApps.results.filter((app) => app.is_template));
            setFilteredApps(templateApps.results.filter((app) => app.is_template));
        } catch (error) {
            setErrorMessage("Error to load the apps");
        }
    }

    async function fetchFavoriteApps() {
      try {
        const favoritesApps = await getUserFavoriteAppIds(token);
        setFavoriteApps(favoritesApps);
        setAreFavoriteAppsFetched(true);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
    fetchFavoriteApps();
  }, [token]);

  useEffect(() => {
    applyFilters(searchQuery, selectedCategory);
  }, [searchQuery, selectedCategory]);

  const applyFilters = (query, category) => {
    let filtered = apps;
    if (query) {
      const normalizedQuery = query.toLowerCase();
      filtered = filtered.filter((app) =>
        app.name.toLowerCase().includes(normalizedQuery)
      );
    }
    if (category) {
        filtered = filtered.filter((app) => app.category[`name_${language}`] === category);
    }
    setFilteredApps(filtered);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    applyFilters(query, selectedCategory);
  };

  return (
    <main className="templates">
      {!isMobile && <TopBar hasNavegation />}
      <ErrorMessage message={errorMessage} />
      {filteredApps && areFavoriteAppsFetched ? (
        <section className="templates-content">
          <header className="templates-header">
              {!isMobile && <h1>{languageTexts[language].templatesTitle}</h1>}
              {!isMobile && <p>{languageTexts[language].templatesSubtitle}</p>}
              <Searcher onSearch={handleSearch} placeholder="Search" />
          </header>
          <CategoriesButtons
            token={token}
            applyFilters={applyFilters}
            searchQuery={searchQuery}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            labelText={languageTexts[language].exploreCategoryList}
            />
          <div className="columns explore-columns">
            {filteredApps.map((app) => (
              <div key={app.id} className="column is-one-quarter">
                <Link to={`./${app.id}`}>
                  <AppCardMini appInformation={app} favoriteApp={favoriteApps.includes(app.id)} />
                </Link>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Templates;
