const flipperModalTexts = {
    es: {
        flipperModalAddPhoneNumber: "Agregar numero de teléfono",
        flipperModalCancelButton: "Cancelar",
        flipperModalContent1: "¡Ups! Parece que aún no has registrado tu número de teléfono en tu perfil. Para disfrutar de todas las funciones personalizadas de Flipper, simplemente ingresa tu número; se registrará en tu perfil.",
        flipperModalContent2: "Es fácil y te proporcionará una experiencia más completa. ¡No te lo pierdas!",
        flipperModalPhoneNumber: "Número de teléfono",
        flipperModalPhoneNumberPlaceholder: "Ingresa número de teléfono",
        flipperModalSubmitButton: "Enviar",
        flipperModalDiscardButton: "Descartar",
        flipperModalWhatsApp: 'WhatsApp con Flipper',
        flipperModalSteps: 'Para escanear un código QR desde tu teléfono, sigue estos pasos:',
        flipperModalStep1: 'Abre la aplicación de cámara en tu dispositivo.',
        flipperModalStep2: 'Apunta la cámara al código QR, asegurándote de que esté bien enfocado.',
        flipperModalStep3: 'Cuando la cámara detecte el código, toca la notificación o sigue las instrucciones en pantalla.',
        flipperModalFinalStep: 'Después de escanear el código, serás redirigido a una conversación en WhatsApp, donde podrás chatear con nuestro agente Flipper.',
    },
    en: {
        flipperModalAddPhoneNumber: "Add your phone number",
        flipperModalCancelButton: "Cancel",
        flipperModalContent1: "Oops! It seems you haven't registered your phone number in your profile yet. To enjoy all the personalized features of Flipper, simply enter your number; it will be recorded in your profile.",
        flipperModalContent2: "It's easy and will provide you with a more comprehensive experience. Don't miss out!",
        flipperModalPhoneNumber: "Phone Number",
        flipperModalPhoneNumberPlaceholder: "Enter phone number",
        flipperModalSubmitButton: "Submit",
        flipperModalDiscardButton: "Discard",
        flipperModalWhatsApp: "WhatsApp with Flipper",
        flipperModalSteps: "To scan a QR code from your phone, follow these steps:",
        flipperModalStep1: "Open the camera app on your device.",
        flipperModalStep2: "Point the camera at the QR code, ensuring it's well-focused.",
        flipperModalStep3: "When the camera detects the code, tap the notification or follow the on-screen instructions.",
        flipperModalFinalStep: "After scanning the code, you will be redirected to a conversation on WhatsApp, where you can chat with our agent Flipper.",
    },
};

export default flipperModalTexts;
