const liveAppModalTexts = {
    es: {
        liveAppEditModal: 'Editar App',
        liveAppEditContent1: 'Si decides editar esta aplicación, los cambios afectarán la versión actual que está en vivo.',
        liveAppEditContent2: 'Esto puede impactar la experiencia de los usuarios que ya están utilizando la app.',
        liveAppEditContent3: '¿Quieres continuar?',
        liveAppOnEditModal: 'Cambios en la App en Vivo',
        liveAppOnEditContent1: 'Si decides guardar los cambios realizados en esta aplicación,  estos afectarán la versión actual que está en vivo.',
        liveAppOnEditContent3: '¿Quieres guardar los cambios realizados?',
        liveAppDiscardButton: 'Cancelar',
        liveAppSubmitButton: 'Continuar',
    },
    en: {
        liveAppEditModal: 'Edit App',
        liveAppEditContent1: 'If you decide to edit this application, the changes will affect the current version that is live.',
        liveAppEditContent2: 'This may impact the experience of users who are already using the app.',
        liveAppEditContent3: 'Do you want to continue?',
        liveAppOnEditModal: 'Changes to the Live App',
        liveAppOnEditContent1: 'If you decide to save the changes made to this application, they will affect the current version that is live.',
        liveAppOnEditContent3: 'Do you want to save the changes made?',
        liveAppDiscardButton: 'Cancel',
        liveAppSubmitButton: 'Continue',
    },
};

export default liveAppModalTexts;
