import React, {useState, useEffect} from "react";
import './flipNews.scss';
import flipNews from '../../assets/newDesign/flipnews.png';
import TopBar from "../../components/navBar/TopBar";

const FlipNews = () => { 
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      const isMobile = windowWidth <= 768;
    return (
        <main className={`flipnews`}>
            {!isMobile && <TopBar hasNavegation />}
            <img src={flipNews} alt="flip news"/>
        </main>
    );
};

export default FlipNews;