import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { getUserWorkflows, getWorkflowsByTeam } from "../../../api/workflow";
import { useLanguage } from "../../../hooks/languageHook/LanguageHook";
import './workflowsList.scss';

import { deleteWorkflow } from "../../../api/workflow";
import { ReactComponent as EditIcon } from '../../../assets/newDesign/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/newDesign/trash-icon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/plus-icon.svg';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import TopBar from "../../../components/navBar/TopBar";
import Searcher from "../../../components/searcher/Searcher";
import languageTexts from "../../../languajeConstant";



function WorkflowsList() {
    const token = useSelector((state) => state.session.token);
    const userInfo = useSelector((state) => state.session.userInfo);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [workflows, setWorkflows] = useState(null);
    const [filteredWorkflows, setFilteredWorkflows] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [workflowIdToEdit, setWorkflowIdToEdit] = useState(null);
    const [workflowIdToDelete, setWorkflowIdToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const { language } = useLanguage();

    const navigate = useNavigate();

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowWidth <= 768;

    const fetchData = async () => {
        try {
            let myWorkflows;
            if (userInfo.roleId === 5) {
                myWorkflows = await getUserWorkflows(token);
            } else {
                myWorkflows = await getWorkflowsByTeam(token);
            }
            setWorkflows(myWorkflows.results);
            setFilteredWorkflows(myWorkflows.results);
        } catch (error) {
            console.error(error);
            setErrorMessage("Error to load the apps");
        }
    };

    useEffect(() => {
        fetchData();
    }, [token, userInfo.roleId]);

    const handleSearch = (searchText) => {
        if (searchText) {
            const filtered = workflows.filter((workflow) => workflow.name.toLowerCase().includes(searchText.toLowerCase()));
            setFilteredWorkflows(filtered);
        } else {
            setFilteredWorkflows(workflows);
        }
    };

    const confirmDeleteWorkflow = (workflowId) => {
        deleteWorkflow(token, workflowId)
            .then(() => {
                setShowConfirmation(false);
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (workflowIdToEdit) {
            navigate(`/myworkflows/${workflowIdToEdit}/edit`);
        }
    }, [workflowIdToEdit, navigate]);

    return (
        <main className="workflows-list">
            {!isMobile && <TopBar hasNavegation />}
            <section className="workflows-list-content">
                <header className="workflows-list-content-header">
                    <h1>{languageTexts[language].myWorkflowsTitle}</h1>
                    <p>{languageTexts[language].myWorkflowsDescription}</p>
                </header>
                <div className="workflows-list-content-subtitle">
                    <Searcher onSearch={handleSearch} />
                    <Link className="button create-workflow" to="/newworkflow"><PlusIcon />{languageTexts[language].newWorkflowButton}</Link>
                </div>
                <table className="workflows-list list-view">
                    <thead>
                        <tr>
                            <th>{languageTexts[language].workflowName}</th>
                            <th>{languageTexts[language].workflowCreatedBy}</th>
                            <th>{languageTexts[language].workflowLastUsed}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredWorkflows && filteredWorkflows.map((workflow) => (
                            <tr key={workflow.id}>
                                <td><Link to={`./${workflow.id}`}>{workflow.name}</Link></td>
                                <td>{workflow.user.username}</td>
                                <td>{moment(workflow.creation_date).fromNow()}</td>
                                {workflow.user_id === userInfo.userId &&
                                    <td className="list-view-actions">
                                        <button className="list-view-actions-button" onClick={() => {
                                            setWorkflowIdToEdit(workflow.id);
                                        }}>
                                            <EditIcon />
                                        </button>
                                        <button className="list-view-actions-button" onClick={() => {
                                            setWorkflowIdToDelete(workflow.id);
                                            setShowConfirmation(true);
                                        }}>
                                            <DeleteIcon />
                                        </button>
                                        {showConfirmation && workflowIdToDelete && (
                                            <ConfirmationDialog
                                                message={languageTexts[language].useWorkflowDeleteConfirmation}
                                                onConfirm={() => confirmDeleteWorkflow(workflowIdToDelete)}
                                                onCancel={() => setShowConfirmation(false)}
                                            />
                                        )}
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </main>
    );
}

export default WorkflowsList;
