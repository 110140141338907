import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import ErrorMessage from "../../../components/errorMessage/ErrorMessage";
import DecorationBanner from "../../../components/decorationBanner/DecorationBanner";
import { resendCode } from '../../../api/session';

import { activateUserAction } from "../../../redux/actions/authActions";
import { ReactComponent as EnvelopIcon } from '../../../assets/envelop-icon.svg';


const EmailConfirmation = ({openInformationModal}) => {
  const dispatch = useDispatch();
  const [confirmationCode, setConfirmationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [checkYourEmail, setCheckYourEmail] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const handleSubmit = async event => {
    try {
      await dispatch(activateUserAction(confirmationCode, token));
      openInformationModal();
      setErrorMessage("");
    }
    catch (error) {
      setErrorMessage(error.message);
    }
  }

  const handleResendCode = async event => {
    event.preventDefault();
    try {
      await resendCode(token);
      setErrorMessage("");
      setCheckYourEmail(true);
    }
    catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <section className="login">
      <div className="banner-top">
        <DecorationBanner text={'Learn Build Share Showcase'} />
      </div>
      <div className="login-content">
        {checkYourEmail
          ?
          <>
            <h1 className="login-content-title">Confirm your email</h1>
            <p>Check your email for your confirmation</p>
          </>
          :
          <>
            <h1 className="login-content-title forgot-password-title">Confirm your email</h1>
            <div className="login-content-form">
              <div className="field login-content-form-field">
                <label className="label">Code</label>
                <div className="control">
                  <input type="text"
                    placeholder="Enter confirmation code"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    className="input"
                    required />
                </div>
                <ErrorMessage message={errorMessage} />
                <div className="login-resend-link">
                  <span>I need a new code: </span>
                  <a href="#" onClick={handleResendCode}>Resend</a>
                </div>

              </div>
              <button className="button is-primary login-content-submit" type="submit" onClick={handleSubmit}>Verify</button>
              <p className='login-info-message'>If you don't receive the verification code, please contact us at:</p>
              <p className='login-info-message'><EnvelopIcon /><a href='mailto:support@flipando.ai'>support@flipando.ai</a></p>
            </div>
            <div className="banner-bottom">
              <DecorationBanner text={'Learn Build Share Showcase'} />
            </div>
          </>
        }
      </div>
    </section>
  );
};

export default EmailConfirmation;
