import React, {useState} from "react";
import PostRegisterStepTwo from "./PostRegisterStepTwo";
import { useSelector } from "react-redux";

const PostRegisterStepOne = ({closeModal}) => {
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [country, setCountry] = useState('');
    const [isStepOneOpen, setIsStepOneOpen] = useState(true);
    const userInfo = useSelector(state => state.session.userInfo);

    const handleContinue = () => {
        setIsStepOneOpen(false);
      };


    return(
        <section className="register">
            <div className="modal-background"></div>
            {isStepOneOpen ? (
            <div className="modal-content box register-post">
                <div className="slider-indicator">
                    <div className="indicator active"></div>
                    <div className="indicator"></div>
                    <div className="indicator"></div>
                </div>
                <header className="register-post-modal-header">
                    <h1>About You:</h1>
                </header>
                <div className="register-post-modal-body">
                    <div className="field register-modal-field">
                        <label className="label">Name</label>
                        <input 
                        type="text" 
                        className="input"
                        onChange={(event) => setName(event.target.value)} />
                    </div>
                    <div className="field register-modal-field">
                        <label className="label">Role</label>
                        <input type="text" className="input"
                        onChange={(event) => setRole(event.target.value)}  />
                    </div>
                    <div className="field register-modal-field">
                        <label className="label">Country</label>
                        <input type="text" className="input"
                        onChange={(event) => setCountry(event.target.value)}  />
                    </div>
                    <button className="button register-post-modal-continue-button" onClick={handleContinue}>Continue</button>
                </div>
            </div>) : (
                <PostRegisterStepTwo name={name} email={userInfo.email} role={role} country={country} isStepOneOpen={isStepOneOpen} closeModal={closeModal} />
            )}
        </section>
    )
}

export default PostRegisterStepOne;
