import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getUserApps } from "../../../api/applications";
import { createWorkflow } from "../../../api/workflow";
import TopBar from "../../../components/navBar/TopBar";
import Searcher from "../../../components/searcher/Searcher";
import { useLanguage } from "../../../hooks/languageHook/LanguageHook";
import languageTexts from "../../../languajeConstant";
import './createWorkflow.scss';

import CardWorkflow from './CardWorkflow';


function CreateWorkflow() {
    const { language } = useLanguage();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [workflowName, setWorkflowName] = useState("Workflow Name");
    const [workflowDescription, setWorkflowDescription] = useState("New workflow description");
    const [hasStartApp, setHasStartApp] = useState(false);
    const [apps, setApps] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedApps, setSelectedApps] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(5);
    const [filteredApps, setFilteredApps] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const fileInputRefs = useRef({});


    const token = useSelector((state) => state.session.token);
    const navigate = useNavigate();

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowWidth <= 768;

    useEffect(() => {
        async function fetchData() {
            try {
                const userApps = await getUserApps(token);
                setApps(userApps.results);
                setFilteredApps(userApps.results);
            } catch (error) {
                setErrorMessage(error.message);
            }
        }
        fetchData();
    }, [token]);

    const validateOutputConnections = () => {
        const requiredOutputs = selectedApps.slice(0, -1).map((_, index) => `step_output_${index + 1}`);
        const stepOutputValues = selectedApps.flatMap(app =>
            Object.entries(app.step_inputs).flatMap(([inputName, inputDetail]) =>
                inputDetail.type === "step_output" ? `step_output_${inputDetail.value}` : []
            )
        );
        const allElementsPresent = requiredOutputs.every(element =>
            stepOutputValues.includes(element)
        );

        return allElementsPresent;
    };

    const handleSearch = (searchText) => {
        if (searchText) {
            const filtered = apps.filter((app) => app.name.toLowerCase().includes(searchText.toLowerCase()));
            setFilteredApps(filtered);
            setItemsToShow(5);
        } else {
            setFilteredApps(apps);
        }
    };

    const handleCreateWorkflow = async () => {
        if (!validateOutputConnections()) {
            setErrorMessage(languageTexts[language].createWorkflowError);
            return;
        }

        try {
            const workflowSteps = selectedApps.map((app, index) => {
                return {
                    app_id: app.id,
                    step: index + 1,
                    inputs: app.step_inputs
                };
            });
            const workflow = await createWorkflow(workflowName, workflowDescription, 1, workflowSteps, token);
            navigate(`/myworkflows/${workflow.id}`);
            setErrorMessage("");

        } catch (error) {
            console.error(error);
            setErrorMessage(error.message);
        }
    };

    const removeStep = (stepNumber) => {
        const newSelectedApps = selectedApps.filter((_, index) => index !== stepNumber);
        if (newSelectedApps.length === 0) {
            setHasStartApp(false);
        }
        setSelectedApps(newSelectedApps);
    };

    const handleLoadMore = (event) => {
        event.preventDefault();
        setItemsToShow(itemsToShow + 5);
    };
    
    return (
        <section className='new-workflow'>
            {!isMobile && <TopBar hasNavegation />}
            <section className="new-workflow-content">
                <header className="new-workflow-content-header">
                    <h1 className="new-workflow-content-header-title">{workflowName}</h1>
                    <p className="new-workflow-content-header-description">{workflowDescription}</p>
                </header>
                <div className='new-workflow-content-body'>
                    <form className='new-workflow-content-body-form'>
                        <div className='new-workflow-content-body-form-section'>
                            <h2>{languageTexts[language].createWorkflowInfo}</h2>
                            <div className="field center-content">
                                <label>{languageTexts[language].createWorkflowName}</label>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder={languageTexts[language].createWorkflowName}
                                    required
                                    onChange={(event) => setWorkflowName(event.target.value)}
                                    maxLength={256}
                                />
                            </div>
                            <div className="field">
                                <label>{languageTexts[language].createWorkflowDescription}</label>
                                <textarea
                                    className="textarea"
                                    placeholder={languageTexts[language].createWorkflowDescription}
                                    onChange={(event) => setWorkflowDescription(event.target.value)}
                                    maxLength={512}
                                ></textarea>
                            </div>
                        </div>
                        {selectedApps.length > 0 &&
                            selectedApps?.map((app, index) => (
                                <CardWorkflow
                                    workflowStep={index}
                                    app={app}
                                    inWorkflow={true}
                                    canDelete={true}
                                    selectedApps={selectedApps}
                                    setSelectedApps={setSelectedApps}
                                    handleDeleteApp={removeStep}
                                    fileInputRefs={fileInputRefs}
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                    setError={setErrorMessage}
                                />
                            ))
                        }
                        {selectedApps.length > 1 &&
                            <div className='new-workflow-content-body-form-submit'>
                                <p>{languageTexts[language].createWorkflowCreateMsg}</p>
                                <div className='new-workflow-content-body-form-submit-buttons'>
                                    <button className='button' type='button' onClick={handleCreateWorkflow}>{languageTexts[language].createWorkflowCreateButton}</button>
                                </div>
                            </div>
                        }
                        {errorMessage && <p className='message is-danger'>{errorMessage}</p>}
                        {hasStartApp ?
                            <div className='new-workflow-content-body-form-section'>
                                <h2>{languageTexts[language].createWorkflowSelectedApp}</h2>
                                <Searcher onSearch={handleSearch} />
                                {filteredApps?.slice(0, itemsToShow).map((app, key) => (
                                    <CardWorkflow
                                        firstSelected={!hasStartApp}
                                        app={app}
                                        inWorkflow={false}
                                        canDelete={false}
                                        setHasStartApp={setHasStartApp}
                                        selectedApps={selectedApps}
                                        setSelectedApps={setSelectedApps}
                                        fileInputRefs={fileInputRefs}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        setError={setErrorMessage}
                                    />
                                ))}
                                <div className='new-workflow-content-body-form-section-load-more'>
                                    {filteredApps && filteredApps.length > itemsToShow && (
                                        <button className="button" onClick={handleLoadMore}>
                                            {languageTexts[language].createWorkflowLoadMoreButton}
                                        </button>
                                    )}
                                </div>
                            </div>
                            :
                            <div className='new-workflow-content-body-form-section'>
                                <h2>{languageTexts[language].createWorkflowSelectFirstApp}</h2>
                                <Searcher onSearch={handleSearch} />
                                {filteredApps?.slice(0, itemsToShow).map((app, key) => (
                                    <CardWorkflow
                                        firstSelected={true}
                                        app={app}
                                        inWorkflow={false}
                                        canDelete={false}
                                        setHasStartApp={setHasStartApp}
                                        selectedApps={selectedApps}
                                        setSelectedApps={setSelectedApps}
                                        fileInputRefs={fileInputRefs}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        setError={setErrorMessage}
                                    />
                                ))}
                                <div className='new-workflow-content-body-form-section-load-more'>
                                    {filteredApps && filteredApps.length > itemsToShow && (
                                        <button className="button" onClick={handleLoadMore}>
                                            {languageTexts[language].createWorkflowLoadMoreButton}
                                        </button>
                                    )}
                                </div>
                            </div>
                        }
                    </form>
                </div>
            </section>
        </section>
    );
}

export default CreateWorkflow;
