import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import { useSelector } from "react-redux";
import { editUser } from "../../api/user";

import eyeIcon from "../../assets/eye-password-hide-icon.svg";
import TopBar from "../../components/navBar/TopBar";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import './userSettings.scss';


const UserSettings = () => {
  const { language } = useLanguage();
  const userInfo = useSelector(state => state.session.userInfo);
  const token = useSelector((state) => state.session.token);
  
  const [inputData, setInputData] = useState({});
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  const userData = {
    userName: userInfo.userName,
    email: userInfo.email,
    phoneNumber: userInfo.phoneNumber,
    profileImage: userInfo.profileImage,
    subscribenewsletter: userInfo.subscribenewsletter
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputData(prevData => ({ ...prevData, [name]: value }));
  };
  const handlePassword = (event) => {
    const { name, value } = event.target;
    if(name === 'oldpass') {
      setOldPassword(value);
    } else {
      setNewPassword(value);
    }
  }
  const newUserData = Object.entries(userData).map(([key, value]) => {
    let placeHolder = "";
    let label = "";

    if (key === "email") {
      placeHolder = languageTexts[language].userSettingsEmailPlaceholder;
      label = languageTexts[language].userSettingsEmail;
    } else if (key === "userName") {
      placeHolder = languageTexts[language].userSettingsNamePlaceholder;
      label = languageTexts[language].userSettingsDisplayName;
    } else if (key === "phoneNumber") {
      placeHolder = languageTexts[language].userSettingsPhoneNumberPlaceholder;
      label = ""
    } else if (key === "subscribenewsletter") {
      placeHolder = "";
      label = "Subscribe to our newsletter";
    }

    return {
      placeHolder,
      value: inputData[key] || value,
      label,
      key
    };
  }).filter(obj => obj.key !== "profileImage").filter(obj => obj.key !== "subscribenewsletter");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username: inputData.userName,
      email: inputData.email,
      phone_number: inputData.phoneNumber
    }

    const editUserForm = Object.entries({ ...data, oldPassword, newPassword })
    .reduce((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (Object.keys(editUserForm).length !== 0) {
      try {
        const response = await editUser(editUserForm, token);
        alert("Data successfully updated!")
        
        window.location.reload()
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    }
  };

  const handleToggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  return(
    <section className="settings">
      {!isMobile && <TopBar hasNavegation />}
      <section className="settings-container">
        <form onSubmit={handleSubmit} className="settings-form">
          <div className="settings-form-body">
          {newUserData.map((prop, index) => (
                <div key={index} className="field">
                  {prop.key === 'subscribenewsletter' ? (
                    <label className="radio">
                      {prop.label}
                      <input type="radio" name={prop.key} />
                      Yes
                      <input type="radio" name={prop.key} />
                      No
                    </label>
                  ) : prop.key === 'phoneNumber' ? (
                    <PhoneInput 
                      placeholder={languageTexts[language].userSettingsPhoneNumberPlaceholder}
                      value={inputData.phoneNumber || prop.value}
                      onChange={(value) => setInputData({ ...inputData, phoneNumber: value })}
                    />
                  ) : (
                    <>
                      <h3>{prop.label}</h3>
                      <div className="settings-form-field">
                        <label>{prop.value}</label>
                        <input
                          type="text"
                          className="input"
                          name={prop.key}
                          value={inputData[prop.key] || ''}
                          placeholder={prop.placeHolder}
                          onChange={handleInputChange}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}


            <h3>{languageTexts[language].userSettingsPassword}</h3>
            <div className="settings-form-field">
              <label>{languageTexts[language].userSettingsOldPassword}</label>
              <input
                name="oldpass"
                type={showOldPassword ? "text" : "password"}
                className="input"
                placeholder={languageTexts[language].userSettingsOldPasswordPlaceholder}
                value={oldPassword}
                onChange={handlePassword}
              />
              <img
                src={eyeIcon}
                alt="Toggle Password"
                className="eye-icon"
                onClick={handleToggleOldPassword}
              />
            </div>
            <div className="settings-form-field">
              <label>{languageTexts[language].userSettingsNewPassword}</label>
              <input
                name="newpass"
                type={showNewPassword ? "text" : "password"}
                className="input"
                placeholder={languageTexts[language].userSettingsNewPasswordPlaceholder}
                value={newPassword}
                onChange={handlePassword}
              />
              <img
                src={eyeIcon}
                alt="Toggle Password"
                className="eye-icon"
                onClick={handleToggleNewPassword}
              />
            </div>
          </div>
          <p className="errorMessage">{errorMessage}</p>
          <div className="control settings-form-footer">
            <button className="button settings-form-footer-button">{languageTexts[language].userSettingsSubmitButton}</button>
          </div>
        </form> 
      </section>
    </section>
  )
}

export default UserSettings;
