import React from "react";
import './navBarMobile.scss';
import { NavLink, useMatch } from 'react-router-dom';
import { useLanguage } from '../../../hooks/languageHook/LanguageHook';
import languageTexts from '../../../languajeConstant';

import { ReactComponent as CommunityIcon } from '../../../assets/newDesign/community-icon.svg';
import { ReactComponent as FlipNewsIcon } from '../../../assets/newDesign/flip-news-icon.svg';
import { ReactComponent as RecentsIcon } from '../../../assets/newDesign/recientes-icon.svg';
import { ReactComponent as TeamIcon } from '../../../assets/newDesign/team-icon.svg';


const NavBarMobileTop = () => {
    const { language } = useLanguage();
    const isActive = useMatch({
        path: '/',
        exact: true
      });
  
    return (
        <section className="navMobile top">
            <ul className="navMobile-tab">
                <li>
                    <NavLink className={`navMobile-tab-item community ${isActive ? 'active' : ''}`} to="/explore">
                        <div className="navMobile-tab-item-icon"><CommunityIcon /></div>
                        {languageTexts[language].navbarCommunity}
                    </NavLink>
                </li>
                <li>
                    <NavLink className={`navMobile-tab-item recent ${isActive ? 'active' : ''}`} to="/recents">
                        <div className="navMobile-tab-item-icon"><RecentsIcon /></div>
                        {languageTexts[language].navbarRecent}
                    </NavLink>
                </li>
                <li>
                    <NavLink className={`navMobile-tab-item menuTeam ${isActive ? 'active' : ''}`} to="/team">
                        <div className="navMobile-tab-item-icon"><TeamIcon /></div>
                        {languageTexts[language].navbarTeam}
                    </NavLink>
                </li>
                <li>
                    <NavLink className={`navMobile-tab-item menuNews ${isActive ? 'active' : ''}`} to="/news">
                        <div className="navMobile-tab-item-icon"><FlipNewsIcon /></div>
                        {languageTexts[language].navbarNews}
                    </NavLink>
                </li>
            </ul>
        </section>
    );
}


export default NavBarMobileTop;