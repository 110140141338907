import React, { useEffect, useState } from "react";
import { getCategories } from "../../api/categories";
import { ReactComponent as ArrowIcon } from '../../assets/newDesign/toparrow-icon.svg';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';


const CategoriesButtons = ({ 
  token, 
  applyFilters, 
  searchQuery, 
  setSelectedCategory, 
  selectedCategory,
  labelText}) => {
    const [categories, setCategories] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { language } = useLanguage();

    useEffect(() => {
        const fetchCategories = async () => {
          try {
            const categoriesResponse = await getCategories(token);
            setCategories(categoriesResponse);
          } catch (error) {
            console.error(error);
          }
        };
        fetchCategories();
      }, [token]);

      useEffect(() => {
        if (selectedIndex === -1 || !categories.length) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(categories[selectedIndex][`name_${language}`]);
            applyFilters(searchQuery, categories[selectedIndex][`name_${language}`]);
        }
    }, [selectedIndex, setSelectedCategory]);

    const handleArrowClick = (direction) => {
      setSelectedIndex(prevSelectedIndex => {
          if (direction === 'up') {
              return prevSelectedIndex > 0 ? prevSelectedIndex - 1 : categories.length - 1;
          } else {
              return prevSelectedIndex < categories.length - 1 ? prevSelectedIndex + 1 : 0;
          }
      });
  };

    return (
        <div className="explore-category-buttons">
            <label>{labelText}: </label>
            {selectedIndex !== -1 ? <span>{categories[selectedIndex][`name_${language}`]}</span> : <span>All</span>}
            <div>
              <ArrowIcon className='explore-category-buttons-button' onClick={() => handleArrowClick('up')} />
              <ArrowIcon className='explore-category-buttons-button explore-category-buttons-down' onClick={() => handleArrowClick('down')} />
            </div>
        </div>
    );
  };
  
export default CategoriesButtons;