const templatesTexts = { 
    es: {
        templatesTitle: 'Explora plantillas',
        templatesSubtitle: 'Explora miles de plantillas para iniciar tu próxima gran idea.'
    },
    en: {
        templatesTitle: 'Explore templates',
        templatesSubtitle: 'Explore thousands of templates to kickstart your next big idea.'
    }
}

export default templatesTexts;