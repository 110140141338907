const languageNavbar = {
    es: {
        navbarTitleFree: 'Actualizar',
        navbarTitlePro: 'Usuario Pro',
        navbarHome: 'Inicio',
        navbarUserSettings: 'Configuración de usuario',
        navbarTeamSettings: 'Configuración del equipo',
        navbarPricing: 'Precios',
        navbarLearnings: 'Aprendizaje',
        navbarLanguage: 'Idioma',
        navbarLogout: 'Cerrar sesión',
        navbarCommunity: 'Comunidad',
        navbarRecent: 'Reciente',
        navbarTeam: 'Equipo',
        navbarNews: 'Noticias',
        navbarFlipper: 'Flipper',
        navbarAppGenerator: 'Generador de Apps',
        navbarTemplates: 'Plantillas',
        navbarMyWorkspace: 'Mi espacio',
        navbarCreateApp: 'Crear App',
        navbarFreeMessageTitle: '¡Utiliza todo el potencial de flipando.ai!',
        navbarFreeMessageDescription: 'Te estás quedando sin créditos. Actualiza para utilizar todo el potencial de flipando.ai y pasar al siguiente nivel.',
        navbarFreeMessageBtn: 'Probar Pro gratis',
        navbarFreeMessageBtn2: 'Actualizar a Pro',
        navbarCreateNewApp: 'Crear nueva App',
        navbarWorkspace: 'ESPACIO DE TRABAJO',
        navbarWpWithFlipper: 'Whatsapp con Flipper',
        navbarDashboard: 'Panel',
        navbarStartFromScratch: 'Empezar desde cero',
        navbarUseTemplate: 'Usar una plantilla',
        navBarFavorites: 'Favoritos',
        navBarMyApps: 'Mis aplicaciones',
        navBarCredits: 'Créditos',
        navBarUserSettings: 'ConfIguración',
        navBarTeamSetttings: 'Configuración del equipo',
        navBarApiKeys: 'Claves API',
        navBarLogout: 'Cerrar sesión',
        navBarTeamWorkspace: 'Espacio de trabajo del equipo'
    },
    en: {
        navbarTitleFree: 'Upgate',
        navbarTitlePro: 'Pro User',
        navbarHome: 'Home',
        navbarUserSettings: 'User Settings',
        navbarTeamSettings: 'Team Settings',
        navbarPricing: 'Pricing',
        navbarLearnings: 'Learning Resources',
        navbarLanguage: 'Language',
        navbarLogout: 'Logout',
        navbarCommunity: 'Community',
        navbarRecent: 'Recents',
        navbarTeam: 'Teams',
        navbarNews: 'Flip News',
        navbarFlipper: 'Flipper',
        navbarAppGenerator: 'App generator',
        navbarTemplates: 'Templates',
        navbarMyWorkspace: 'My Workspace',
        navbarCreateApp: 'Create App',
        navbarFreeMessageTitle: 'Use the full potential of flipando.ai!',
        navbarFreeMessageDescription: 'You are running out of credits Update to use the full potential of flipando.ai and flip to the next level.',
        navbarFreeMessageBtn: 'Try Pro for free',
        navbarFreeMessageBtn2: 'Upgrade to Pro',
        navbarCreateNewApp: 'Create new App',
        navbarWorkspace: 'WORKSPACE',
        navbarWpWithFlipper: 'Whatsapp with Flipper',
        navbarDashboard: 'Dashboard',
        navbarStartFromScratch: 'Start from scratch',
        navbarUseTemplate: 'Use a Template',
        navBarFavorites: 'Favorites',
        navBarMyApps: 'My apps',
        navBarCredits: 'Credits',
        navBarUserSettings: 'Settings',
        navBarTeamSetttings: 'Team Settings',
        navBarApiKeys: 'API Keys',
        navBarLogout: 'Logout',
        navBarTeamWorkspace: 'Team Workspace'
    }
};

export default languageNavbar;
