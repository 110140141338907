const subscriptionsTexts = { 
    es: {
        subscriptionsSubtitle: 'Tenemos el plan perfecto para satisfacer tus necesidades.',
        subscriptionsFreeTitle: 'Gratis',
        subscriptionsFreeSubtitle: 'Starter',
        subscriptionsFreeDescription: 'Desata el poder de Gen-Ai.',
        subscriptionsBtn: 'Empezar',
        subscriptionsFreeContent1: 'Perfil público',
        subscriptionsFreeContent2: 'Creación limitada de apps',
        subscriptionsFreeContent3: 'Plantillas de apps',
        subscriptionsFreeContent4: 'Soporte de la comunidad',
        subscriptionsProTitle: 'Pro',
        subscriptionsProDescription: 'Lleva tus superpoderes de Gen-Ai al siguiente nivel con nuestro plan Pro.',
        subscriptionsProContent1: 'Perfil privado',
        subscriptionsProContent2: 'Creación ilimitada de apps',
        subscriptionsProContent3: 'Historial de versiones ilimitado',
        subscriptionsProContent4: 'Constructores de lógica avanzada',
        subscriptionsProBtn: 'por usuario por mes',
        subscriptionsTeamTitle: 'Equipos',
        subscriptionsTeamDescription: 'Potencia a tu organización con innovación de IA usando nuestros Equipos colaborativos.',
        subscriptionsTeamContent1: 'Librerías de toda la organización',
        subscriptionsTeamContent2: 'Perfil personalizado',
        subscriptionsTeamContent3: 'Analíticas del sistema',
        subscriptionsTeamContent4: 'Herramientas de colaboración',
        subscriptionsTeamContent5: 'Integraciones de API',
        subscriptionsTeamBtn: 'precio de inicio por equipo por mes',
        subscriptionsCustomTitle: 'Plan Personalizado',
        subscriptionsCustomDescription: 'Si tu negocio requiere soluciones a la medida y soporte sin igual, nuestro plan Enterprise es tu entrada a la excelencia de GenAi.',
        subscriptionsCustomPre: 'Todo en el plan Equipos, más:',
        subscriptionsCustomContent1: 'Onboarding y soporte experto',
        subscriptionsCustomContent2: 'Integraciones personalizadas',
        subscriptionsCustomContent3: 'Despliegue de hub privado',
        subscriptionsCustomContent4: 'Optimización de infra y nube',
        subscriptionsCustomBtn: 'Contáctanos',
        subscriptionsMonthly: 'Mensual',
        subscriptionsYearly: 'Anual',
        subscriptionsSave: 'Ahorra',
        subscriptionsEverithingIn: 'Todo en ',
        subscriptionsPlus: ' más:',
        subscriptionsCancel: 'Cancelar Suscripción',
        subscriptionsActiveUntil: 'Tienes un plan activo hasta',
        subscriptionsChangePlan: 'Cambiar Plan',
        subscriptionsPerMonth: 'por mes',
        subscriptionsPerTeam: 'por equipo',
        subscriptionsPerUser: 'por usuario',
        subscriptionsStarterPrice: 'precio de inicio',
        subscriptionsActivePlan: 'Plan Activo',
        subscriptionsContactUsModalTitle: '¡Contáctanos!',
        subscriptionsContactUsModalSuccess: '¡Email enviado exitosamente!',
        subscriptionsContactUsModalName: 'Nombre',
        subscriptionsContactUsModalNamePlaceholder: 'Tu Nombre',
        subscriptionsContactUsModalEmail: 'Email',
        subscriptionsContactUsModalEmailPlaceholder: 'Tu Email',
        subscriptionsContactUsModalCompanyName: 'Nombre de la Empresa',
        subscriptionsContactUsModalCompanyNamePlaceholder: 'Ingresa el nombre de tu empresa',
        subscriptionsContactUsModalMessage: 'Mensaje',
        subscriptionsContactUsModalMessagePlaceholder: 'Explícanos algunas de tus necesidades',
        subscriptionsContactUsModalSend: 'Enviar',
        subscriptionsContactUsModalCancel: 'Cancelar',
        subscriptionsDetailsTitle: 'Detalles de la Suscripción:',
        subscriptionsDetailsPlan: 'Plan:',
        subscriptionsDetailsName: 'Nombre:',
        subscriptionsDetailsPrice: 'Precio:',
        subscriptionsDetailsSeats: 'Asientos comprados:',
        subscriptionsDetailsBilling: 'Periodo de facturación:',
        subscriptionsDetailsPayment: 'Pago:',
        subscriptionsDetailsNextPayment: 'Siguente fecha de pago:',
        subscriptionsDetailsSubscriber: 'Subscriptor:',
        subscriptionsDetailsEmail: 'Email:',
        subscriptionsDetailsAddress: 'Direccion de envio:',
        subscriptionsDetailsAddressLine1: 'Direccion linea 1:',
        subscriptionsDetailsCity: 'Ciudad:',
        subscriptionsDetailsState: 'Estado:',
        subscriptionsDetailsCountry: 'Pais:',
        subscriptionsDetailsPostalCode: 'Codigo Postal:',
        subscriptionsDetailsStatus: 'Estado:',
        subscriptionsDetailsSubscriptionStatus: 'Estado de la Subscripcion:',
        subscriptionsDetailsStatusLastUpdated: 'Ultimo estado:',
        subscriptionsActiveSubscriptionRenewal: 'Renovación automática de la suscripción el ',
        subscriptionsActiveSubscriptionRenewalDate1: 'Su suscripción ha sido ',
        subscriptionsActiveSubscriptionRenewalDate2: ' pero permanecerá activa hasta ',
        subscriptionsActiveSubscription: 'Suscripción Activa',
        subscriptionsReactivateSubscription: 'Re-activar Suscripción',
        subscriptionsCancelSubscription: 'Cancelar Suscripción',
        subscriptionsActiveYourPlan: 'Tu plan incluye:',
        subscriptionsActiveYourMembership: 'Eres miembro del equipo ',
        subscriptionsActiveYourMembershipTeam: ', por favor contacta a tu administrador para cualquier pregunta',
        subscriptionsActiveYourPlanIs: 'Actualmente suscrito al plan ',
    },
    en: {
        subscriptionsSubtitle: 'We have the perfect plan to meet your needs.',
        subscriptionsFreeTitle: 'Free',
        subscriptionsFreeSubtitle: 'Starter',
        subscriptionsFreeDescription: 'Unleash the power of Gen-Ai.',
        subscriptionsBtn: 'Get started',
        subscriptionsFreeContent1: 'Public profile',
        subscriptionsFreeContent2: 'Limited app creation',
        subscriptionsFreeContent3: 'App templates',
        subscriptionsFreeContent4: 'Community support',
        subscriptionsProTitle: 'Pro',
        subscriptionsProDescription: 'Take your Gen-Ai superpowers to the next level with our Pro plan.',
        subscriptionsProContent1: 'Private profile',
        subscriptionsProContent2: 'Unlimited app creation',
        subscriptionsProContent3: 'Unlimited version history',
        subscriptionsProContent4: 'Public and Private spaces',
        subscriptionsProContent5: 'Advanced logic builders',
        subscriptionsProBtn: 'per user per month',
        subscriptionsTeamTitle: 'Teams',
        subscriptionsTeamDescription: 'Empower your organization with AI innovation using our collaborative Teams.',
        subscriptionsTeamContent1: 'Org-wide libraries',
        subscriptionsTeamContent2: 'Custom profile',
        subscriptionsTeamContent3: 'System analytics',
        subscriptionsTeamContent4: 'Collaboration tools',
        subscriptionsTeamContent5: 'API integrations',
        subscriptionsTeamBtn: 'starter price per team per month',
        subscriptionsCustomTitle: 'Custom Plan',
        subscriptionsCustomDescription: 'If your business requires tailored solutions and unparalleled support, our Enterprise plan is your gateway to GenAi excellence.',
        subscriptionsCustomPre: 'Everything in Teams, plus:',
        subscriptionsCustomContent1: 'Onboarding and expert support',
        subscriptionsCustomContent2: 'Custom integrations',
        subscriptionsCustomContent3: 'Private hub deployment',
        subscriptionsCustomContent4: 'Infra and cloud optimization',
        subscriptionsCustomBtn: 'Contact Us',
        subscriptionsMonthly: 'Monthly',
        subscriptionsYearly: 'Yearly',
        subscriptionsSave: 'Save',
        subscriptionsEverithingIn: 'Everything in ',
        subscriptionsPlus: ' plus:',
        subscriptionsCancel: 'Cancel Subscription',
        subscriptionsActiveUntil: 'You have an active plan until',
        subscriptionsChangePlan: 'Change Plan',
        subscriptionsPerMonth: 'per month',
        subscriptionsPerTeam: 'per team',
        subscriptionsPerUser: 'per user',
        subscriptionsStarterPrice: 'starter price',
        subscriptionsActivePlan: 'Active Plan',
        subscriptionsContactUsModalTitle: 'Contact us!',
        subscriptionsContactUsModalSuccess: 'Email sent successfully!',
        subscriptionsContactUsModalName: 'Name',
        subscriptionsContactUsModalNamePlaceholder: 'Your Name',
        subscriptionsContactUsModalEmail: 'Email',
        subscriptionsContactUsModalEmailPlaceholder: 'Your Email',
        subscriptionsContactUsModalCompanyName: 'Company Name',
        subscriptionsContactUsModalCompanyNamePlaceholder: 'Enter your company name',
        subscriptionsContactUsModalMessage: 'Message',
        subscriptionsContactUsModalMessagePlaceholder: 'Explain us some of your needs',
        subscriptionsContactUsModalSend: 'Submit',
        subscriptionsContactUsModalCancel: 'Cancel',
        subscriptionsDetailsTitle: 'Subscription Details:',
        subscriptionsDetailsPlan: 'Plan:',
        subscriptionsDetailsName: 'Name:',
        subscriptionsDetailsPrice: 'Price:',
        subscriptionsDetailsSeats: 'Seats purchased:',
        subscriptionsDetailsBilling: 'Billing period:',
        subscriptionsDetailsPayment: 'Payment:',
        subscriptionsDetailsNextPayment: 'Next payment due:',
        subscriptionsDetailsSubscriber: 'Subscriber:',
        subscriptionsDetailsEmail: 'Email:',
        subscriptionsDetailsAddress: 'Shipping Address:',
        subscriptionsDetailsAddressLine1: 'Address line 1:',
        subscriptionsDetailsCity: 'City:',
        subscriptionsDetailsState: 'State:',
        subscriptionsDetailsCountry: 'Country:',
        subscriptionsDetailsPostalCode: 'Postal Code:',
        subscriptionsDetailsStatus: 'Status:',
        subscriptionsDetailsSubscriptionStatus: 'Subscription status:',
        subscriptionsDetailsStatusLastUpdated: 'Status last updated:',
        subscriptionsActiveSubscriptionRenewal: 'Automatic renewal of subscription on ',
        subscriptionsActiveSubscriptionRenewalDate1: 'Your subscription has been ',
        subscriptionsActiveSubscriptionRenewalDate2: ' but will remain active until ',
        subscriptionsActiveSubscription: 'Active Subscription',
        subscriptionsReactivateSubscription: 'Re-activate Subscription',
        subscriptionsCancelSubscription: 'Cancel Subscription',
        subscriptionsActiveYourPlan: 'Your plan includes:',
        subscriptionsActiveYourMembership: 'You are a Member of ',
        subscriptionsActiveYourMembershipTeam: ' team, please contact your admin for any question',
        subscriptionsActiveYourPlanIs: 'Currently subscribed to ',
    }
}

export default subscriptionsTexts;