import React from 'react';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';

import VoiceInput from '../../components/voiceInput/VoiceInput';
import languageTexts from '../../languajeConstant';

import { ReactComponent as ArrowBottom } from '../../assets/arrow-bottom.svg';

const AppGenerator = ({
    transcription,
    result, 
    setAudio,
    setTextAreaContent,
    setAppGeneratorPrivate,
    handleClearButton,
    handleSubmit,
    userInTeam,
    freeUser,
    error,
    loading}) => {
    const { language } = useLanguage();
    return (
        <section className='appGenerator'>
            <h1>{languageTexts[language].appGeneratorTitle}</h1>
            <p>{languageTexts[language].appGeneratorDescription}</p>
            <div className='isGenerator-form'>
                <p>{languageTexts[language].appGeneratorFormTitle}</p>
                <form>
                    <VoiceInput setAudio={setAudio} setTextAreaContent={setTextAreaContent} />
                    <div>
                        {transcription && <p className='audio-transcript'><strong>{languageTexts[language].appGeneratorAudioTranscript}</strong> {transcription}</p>}
                    </div>
                    <div className='isGenerator-form-buttons'>
                        <div className="select-container">
                            <select onChange={(event) => setAppGeneratorPrivate(event.target.value)} className="custom-select" disabled={freeUser}>
                                <option value={false}>{userInTeam ? languageTexts[language].appGeneratoTeam : languageTexts[language].appGeneratorPublic}</option>
                                <option value={true}>{languageTexts[language].appGeneratorPrivate}</option>
                            </select>
                            <ArrowBottom className="select-arrow" />
                        </div>
                        <button onClick={handleClearButton} className='isGenerator-form-buttons-clear' disabled={loading}>{languageTexts[language].appGeneratorClear}</button>
                        <button onClick={handleSubmit} className='isGenerator-form-buttons-submit' disabled={loading}>{languageTexts[language].appGeneratorSubmit} {freeUser ? languageTexts[language].appGeneratoCredits : ''}</button>
                    </div>
                    {result && <div className='isGenerator-form-result' dangerouslySetInnerHTML={{ __html: result.results.results }} ></div> }
                    {error && <p className='message is-danger'>{error}</p>}
                </form>
            </div>
        </section>
    );
};

export default AppGenerator;
