import React from 'react';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { ReactComponent as LanguageIcon } from '../../assets/newDesign/globe-icon.svg';
import './languageSwitch.scss';

function LanguageSwitcher() {
  const { changeLanguage, language } = useLanguage();
  return (
    <div className="language-switcher">
      <LanguageIcon />
      <button className={`language-button ${language === 'es' ? 'active' : ''}`} onClick={() => changeLanguage('es')}>ESP</button>
      /
      <button className={`language-button ${language === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')}>ENG</button>
    </div>
  );
}

export default LanguageSwitcher;
