import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';


const SubscriptionDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const transactionDetails = location.state?.transactionDetails;
  const selectedPlan = location.state?.selectedPlan;
  const { language } = useLanguage();

  if (!transactionDetails || !selectedPlan) {
    navigate('/');
    return null;
  }

  return (
    <div className="transaction-details">
      <h2>{languageTexts[language].subscriptionsDetailsTitle}</h2>

      <div className="details-section">
        <h3>{languageTexts[language].subscriptionsDetailsPlan}</h3>
        <ul>
          <li>{languageTexts[language].subscriptionsDetailsName} {selectedPlan.type}</li>
          <li>{languageTexts[language].subscriptionsDetailsPrice} U$S {selectedPlan.seat_price}</li>
          <li>{languageTexts[language].subscriptionsDetailsSeats} {transactionDetails.quantity}</li>
          <li>{languageTexts[language].subscriptionsDetailsBilling} {selectedPlan.period}</li>
        </ul>
      </div>

      <div className="details-section">
        <h3>{languageTexts[language].subscriptionsDetailsPayment}</h3>
        <ul>
          <li>{languageTexts[language].subscriptionsDetailsNextPayment} {new Date(transactionDetails.billing_info.next_billing_time).toLocaleString()}</li>
        </ul>
      </div>

      <div className="details-section">
        <h3>{languageTexts[language].subscriptionsDetailsSubscriber}</h3>
        <ul>
          <li>{languageTexts[language].subscriptionsDetailsName} {transactionDetails.subscriber.name.given_name} {transactionDetails.subscriber.name.surname}</li>
          <li>{languageTexts[language].subscriptionsDetailsEmail} {transactionDetails.subscriber.email_address}</li>
        </ul>
      </div>

      <div className="details-section">
        <h3>{languageTexts[language].subscriptionsDetailsAddress}</h3>
        <ul>
          <li>{languageTexts[language].subscriptionsDetailsAddressLine1} {transactionDetails.subscriber.shipping_address.address.address_line_1}</li>
          <li>{languageTexts[language].subscriptionsDetailsCity} {transactionDetails.subscriber.shipping_address.address.admin_area_2}</li>
          <li>{languageTexts[language].subscriptionsDetailsState} {transactionDetails.subscriber.shipping_address.address.admin_area_1}</li>
          <li>{languageTexts[language].subscriptionsDetailsCountry} {transactionDetails.subscriber.shipping_address.address.country_code}</li>
          <li>{languageTexts[language].subscriptionsDetailsPostalCode} {transactionDetails.subscriber.shipping_address.address.postal_code}</li>
        </ul>
      </div>

      <div className="details-section">
        <h3>{languageTexts[language].subscriptionsDetailsStatus}</h3>
        <ul>
          <li>{languageTexts[language].subscriptionsDetailsSubscriptionStatus} {transactionDetails.status}</li>
          <li>{languageTexts[language].subscriptionsDetailsStatusLastUpdated} {new Date(transactionDetails.status_update_time).toLocaleString()}</li>
        </ul>
      </div>
    </div>
  );
}

export default SubscriptionDetails;
