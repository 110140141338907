import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import { ReactComponent as CheckIconPink } from '../../assets/check-icon-pink.svg';
import { ReactComponent as ArrowIcon } from '../../assets/right-arrow-icon.svg';



const Option = ({planDetails, handlePlanChange, userSubscription, handleCancelSubscription, handleChangeSubscription}) => {
    const [featuresList, setFeaturesList] = useState([]);
    const { language } = useLanguage();
    const nextRenewalDate = userSubscription ? moment(userSubscription.next_renewal_date).format('YYYY-MM-DD HH:mm:ss') : null;
    const isFree = planDetails.type === 'Starter';
    const buttonColor = {
        background: isFree ? 'linear-gradient(174deg, #9E9C9C 0%, rgba(254, 247, 247, 0.00) 100%' : 'linear-gradient(175deg, #FF42FF 0%, rgba(255, 66, 255, 0.00) 100%)',
        margin: isFree ? '0 0 4.3rem 0' : '0 0 2rem 0',
    };
    const textColor = {
        color: isFree ? '#333333' : '#FF42FF',
    };

    useEffect(() => {
        const features = planDetails[`features_${language}`]?.split(",") || [];
        const featuresArray = features.map(feature => ({ alias: feature }));
        setFeaturesList(featuresArray);
      }, [planDetails.features]);
  return (
    <section className={`subscription-content-plan ${userSubscription && (planDetails.type === userSubscription?.plan.type) ? 'active-plan' : ''}`}>
        {userSubscription && (planDetails.type === userSubscription?.plan.type) &&
            <p className='title'>{languageTexts[language].subscriptionsActivePlan}</p>
        }
        <div className={`${userSubscription && (planDetails.type === userSubscription?.plan.type) ? 'active-plan-content' : ''}`}>
            <h1 style={textColor}>{planDetails.type}</h1>
            {!(planDetails.type === userSubscription?.plan.type) &&
            <h2>
                {planDetails.type !== "Starter" ? `$${planDetails.seat_price * planDetails.seats}` : languageTexts[language].subscriptionsFreeTitle}
                {planDetails.type !== "Starter" && (
                    <span className='subscription-content-plan-details'>
                        {planDetails.type === "Teams" && languageTexts[language].subscriptionsStarterPrice} <br />
                        {planDetails.type === "Pro" ? languageTexts[language].subscriptionsPerUser : languageTexts[language].subscriptionsPerTeam} <br />
                        {planDetails.type !== "Starter" &&  languageTexts[language].subscriptionsPerMonth}
                    </span>
                )}
            </h2>
            }
            {/* <p className='subscription-content-plan-description'>{planDetails.description}</p> */}
            {!(planDetails.type === userSubscription?.plan.type) &&
            <button className={`button ${isFree ? "isfree" : ""}`} 
                style={buttonColor} onClick={() => handlePlanChange(planDetails.paypal_plan_id)} 
                disabled={isFree || userSubscription}>
               {languageTexts[language].subscriptionsBtn}<ArrowIcon />
            </button>
            }
            <ul>
            {!isFree &&
            <p>{languageTexts[language].subscriptionsEverithingIn} {planDetails.type === 'Pro' && 'Starter Plan'}{planDetails.type === 'Teams' && 'Pro'}, {languageTexts[language].subscriptionsPlus}</p>
            }
            {featuresList.map((option, key) => (
                <li key={key}>
                    {isFree ? <CheckIcon/> : <CheckIconPink/> } {option.alias}
                </li>
            ))}
            </ul>
            {userSubscription && (planDetails.type === userSubscription?.plan.type) && 
                <div className='active-plan-buttons'>
                    <button className='active-plan-change-plan' onClick={handleChangeSubscription}>{languageTexts[language].subscriptionsChangePlan} <ArrowIcon /></button>
                    <button 
                    className='active-plan-cancel' 
                    onClick={() => handleCancelSubscription(true)}
                    disabled={userSubscription.status === 'CANCELLED'}
                    {...(userSubscription.status === 'CANCELLED' ? { "data-tooltip": `${languageTexts[language].subscriptionsActiveUntil} ${nextRenewalDate}` } : {})}
                    >{languageTexts[language].subscriptionsCancel} <ArrowIcon /></button>
                </div>
            }
        </div>
    </section>
  );
}

export default Option;
