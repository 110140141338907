import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import AppCardMini from "../../components/appCardMini/AppCardMini";
import Loader from "../../components/loader/Loader";

const InternalView = ({ apps, favoriteApps, onUpdateFavorites }) => {
    const [filteredApps, setFilteredApps] = useState(apps);
    useEffect(() => {
        setFilteredApps(apps);
    }, [apps]);
    return(
        <div className="my-app-sections">
            { filteredApps ? (
                <>
                    {<section className="my-app-sections-container">
                        <div className="my-app-sections-container-columns">
                            { filteredApps.map((app) => (
                                <div key={app.id} className="my-app-sections-container-columns-column">
                                    <Link to={`./${app.id}`}><AppCardMini appInformation={app} favoriteApp={favoriteApps.includes(app.id)} onUpdateFavorites={onUpdateFavorites}  /></Link>
                                </div>                   
                            ))}
                        </div>
                    </section>}
                </>
            ): <Loader />}
        </div>
    )
}

export default InternalView;
