const editWorkflowTexts = { 
    es: {
        editWorkflowError: "Por favor asegúrese de que no se deje sin usar ninguna salida de las aplicaciones intermedias.",
        editWorkflowInfo: "Información del flujo de trabajo",
        editWorkflowName: "Nombre del flujo de trabajo",
        editWorkflowDescription: "Descripción del flujo de trabajo",
        editWorkflowCreateMsg: "Puede probar este flujo de trabajo o editarlo si está listo",
        editWorkflowSelectFirstApp: "Seleccione la aplicación para iniciar el flujo de trabajo",
        editWorkflowSelectedApp: "Agregar aplicaciones a este flujo de trabajo",
        editWorkflowEditButton: "Editar flujo de trabajo",
        editWorkflowLoadMoreButton: "Cargar más",
        editWorkflowCardBy: "Por",
        editWorkflowCardShowPrompt: "Mostrar prompt",
        editWorkflowCardFileName: "Nombre de archivo",
        editWorkflowCardFileUploaded: "Archivo subido: ",
        editWorkflowCardUploadFile: "Subir archivo",
        editWorkflowCardOutputApp: "Output de la app",
        editWorkflowCardUserInput: "Escribir input",
        editWorkflowEditedFlag: "(Editado)",
        editWorkflowDiscardChanges: "Descartar cambios",
    },
    en: {
        editWorkflowError: "Please ensure that no output from intermediate apps is left unused.",
        editWorkflowInfo: "Workflow Information",
        editWorkflowName: "Workflow Name",
        editWorkflowDescription: "Workflow Description",
        editWorkflowCreateMsg: "You can test this workflow or edit it if it is ready",
        editWorkflowSelectFirstApp: "Select the app to start the workflow",
        editWorkflowSelectedApp: "Add apps to this workflow",
        editWorkflowEditButton: "Edit Workflow",
        editWorkflowLoadMoreButton: "Load more",
        editWorkflowCardBy: "By",
        editWorkflowCardShowPrompt: "Show prompt",
        editWorkflowCardFileName: "File Name",
        editWorkflowCardFileUploaded: "File Uploaded: ",
        editWorkflowCardUploadFile: "Upload File",
        editWorkflowCardOutputApp: "Output App",
        editWorkflowCardUserInput: "Write input",
        editWorkflowEditedFlag: "(Edited)",
        editWorkflowDiscardChanges: "Discard changes",
    }
}

export default editWorkflowTexts;