const dashboardTexts = {
    es: {
        dashboardTitle: 'Panel',
        dashboardTimeFrame: 'Periodo de tiempo',
        dashboardNewApps: 'Nuevas Apps',
        dashboardAppUsage: 'Total de Apps Usadas',
        dashboardLastActivity: 'Última Actividad',
        dashboardMostUsed: 'Más Usadas',
    },
    en: {
        dashboardTitle: 'Dashboard',
        dashboardTimeFrame: 'Timeframe',
        dashboardNewApps: 'New Apps',
        dashboardAppUsage: 'Total App Usage',
        dashboardLastActivity: 'Last Activity',
        dashboardMostUsed: 'Most Used',
    },
};

export default dashboardTexts;
