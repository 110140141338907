import React from "react";
import './navBarMobile.scss';
import { NavLink, useMatch } from 'react-router-dom';
import { useLanguage } from '../../../hooks/languageHook/LanguageHook';
import languageTexts from '../../../languajeConstant';

import { ReactComponent as FlipperIcon } from '../../../assets/newDesign/flipper-icon.svg';
import { ReactComponent as AppGeneratorIcon } from '../../../assets/newDesign/appgenerator-icon.svg';
import { ReactComponent as TemplateIcon } from '../../../assets/newDesign/template-icon.svg';
import { ReactComponent as WorkflowIcon } from '../../../assets/newDesign/workflow-icon.svg';


const NavBarMobileBottom = () => {
    const { language } = useLanguage();
    const isActive = useMatch({
        path: '/',
        exact: true
      });

    return (
        <section className="navMobile bottom">
            <ul className="navMobile-tab">
                <li>
                    <NavLink className={`navMobile-tab-item ${isActive ? 'active' : ''}`} to="https://wa.me/14156897473">
                        <div className="navMobile-tab-item-icon"><FlipperIcon /></div>
                        {languageTexts[language].navbarFlipper}
                    </NavLink>
                </li>
                <li>
                    <NavLink className={`navMobile-tab-item menuGenerator ${isActive ? 'active' : ''}`} to="/explore/4">
                        <div className="navMobile-tab-item-icon"><AppGeneratorIcon /></div>
                        {languageTexts[language].navbarAppGenerator}
                    </NavLink>
                </li>
                <li>
                    <NavLink className={`navMobile-tab-item menuTemplate ${isActive ? 'active' : ''}`} to="/templates">
                        <div className="navMobile-tab-item-icon"><TemplateIcon /></div>
                        {languageTexts[language].navbarTemplates}
                    </NavLink>
                </li>
                <li>
                    <NavLink className={`navMobile-tab-item menuWorkflow ${isActive ? 'active' : ''}`} to="/workspace">
                        <div className="navMobile-tab-item-icon"><WorkflowIcon /></div>
                        {languageTexts[language].navbarMyWorkspace}
                    </NavLink>
                </li>
            </ul>
        </section>
    );
}


export default NavBarMobileBottom;