import React from 'react';
import Flicking from "@egjs/react-flicking";
import { useNavigate } from 'react-router-dom';
import AppCard from "../appCard/AppCard";
import AppCardMini from '../appCardMini/AppCardMini';
import './extendedCarousel.scss';

const ExtendedCarousel = ({ cards, miniCards, favoriteApps }) => {
  const navigate = useNavigate();
  const handleNavigate = (cardId) => {
    navigate(`./${cardId}`);
  };
  return (
    <div className="carousel">
        <Flicking circular={true} resizeOnContentsReady={true} align={'prev'}>
          {cards && cards.map((card, index) => (
            <div key={index}>
              <div className='carousel-spacing' onClick={() => handleNavigate(card.id)}>{miniCards ? <AppCardMini appInformation={card} favoriteApp={favoriteApps.includes(card.id)} /> : <AppCard appInformation={card} favoriteApp={favoriteApps.includes(card.id)} />}</div>
            </div>
          ))}
        </Flicking>
    </div>
  );
};

export default ExtendedCarousel;
