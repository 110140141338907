import axios from "axios";


export async function editUser(dataForm, token) {
  const config = {
    headers: {
      accept: "application/json",
      authorization: "Bearer " + token
    }
  }

  const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/edit-user-data`, dataForm, config);
  try {
    return response.data.results
  }
  catch (error) {
    throw new Error('Error while editing user data');
  }
}


export async function getUserByEmail(email) {
  const config = {headers: {accept: "application/json"}}
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/by-email?email=${email}`, config);
  try {
    return response.data.results
  }
  catch (error) {
    throw new Error('Error while editing user data');
  }
}

export async function sendEmail(email) {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/forgot-password`, { email: email })
      .then((response) => {
        if (response.data.results) {
          alert("A reset link has been sent to your email.");
        } else {
          alert("An error occurred. Please try again later.");
        }
      })
      .catch((error) => {
        if (error.request.status === 404) {
          const response = JSON.parse(error.request.response)
          alert(response.message)
        } else {
          alert("An error occurred. Please try again later.");          
        }
      });
}

export async function editPassword(token, newPassword) {
    const body = {
      token: token,
      new_password: newPassword
    }

    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/reset-password`, body)
      .then((response) => {
        if (response.status === 201) {
          return response;
        } else {
          alert("An error occurred. Please try again later.");
        }
      })
      .catch((error) => {
        if (error.response.status === 401){
          return error.response
        } else {
          console.error(error);
          alert("An error occurred. Please try again later.");
        }
      });
}

export async function getUserFavoriteAppIds(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/favorites/id`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function getUserFavoriteApps(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/favorites`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function isAppFavoritedByUser(token, applicationId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/favorites/${applicationId}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export async function sendUserInformation(name, email, role, country, teamSize, teamDepartment, companyName, companyIndustry, selectedOption, customCaseText, token) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  const body = {
    name: name,
    email: email,
    role: role,
    country: country,
    team_size: teamSize,
    team_department: teamDepartment,
    company_name: companyName,
    company_industry: companyIndustry,
    message: selectedOption + customCaseText
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/user-information`, body, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
