import React, { useState } from "react";
import { sendEmail } from '../../../api/user';

import DecorationBanner from "../../../components/decorationBanner/DecorationBanner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
      await sendEmail(email);
  };

  return (
    <section className="login">
        <div className="banner-top">
          <DecorationBanner text={'Learn Build Share Showcase'} />
        </div>
        <div className="login-content">
            <h1 className="login-content-title forgot-password-title">Forgot Password</h1>
            <p className="login-forgot-password-subtitle">Please enter your email associated with your account.</p>
            <form className="login-content-form" onSubmit={handleSubmit}>
                <div className="field login-content-form-field">
                    <label className="label">Email Address</label>
                    <div className="control">
                        <input type="email" 
                        placeholder="Enter email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        className="input"
                        required />
                    </div>
                </div>
                <button className="button is-primary login-content-submit" type="submit">Submit</button>
            </form>
        </div>
        <div className="banner-bottom">
          <DecorationBanner text={'Learn Build Share Showcase'} />
        </div>
    </section>
  );
};

export default ForgotPassword;
