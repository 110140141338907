import React, {useState} from "react";
import PostRegisterStepThree from "./PostRegisterStepThree";


const PostRegisterStepTwo = ({name, email, role, country, isStepOneOpen, closeModal}) => {
    const [teamSize, setTeamSize] = useState('');
    const [teamDepartment, setTeamDepartment] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyIndustry, setCompanyIndustry] = useState('');
    const [isStepTwoOpen, setIsStepTwoOpen] = useState(!isStepOneOpen);
    const [isStepThreeOpen, setIsStepThreeOpen] = useState(!isStepOneOpen);

    const handleContinue = () => {
        setIsStepThreeOpen(true);
        setIsStepTwoOpen(false);
    };
  
    return(
        <div className="modal-content box register-post">
            {isStepTwoOpen && (
            <>
            <div className="slider-indicator">
                <div className="indicator active"></div>
                <div className="indicator active"></div>
                <div className="indicator"></div>
            </div>
            <header className="register-post-modal-header">
                <h1>About Your Team and Company:</h1>
            </header>
            <div className="register-modal-body">
                    <div className="field register-modal-field">
                        <label className="label">Team Size</label>
                        <input type="number" className="input" onChange={(event) => setTeamSize(event.target.value)} />
                    </div>
                    <div className="field register-modal-field">
                        <label className="label">Team’s Department</label>
                        <input type="text" className="input" onChange={(event) => setTeamDepartment(event.target.value)} />
                    </div>
                    <div className="field register-modal-field">
                        <label className="label">Company Name</label>
                        <input type="text" className="input" onChange={(event) => setCompanyName(event.target.value)} />
                    </div>
                    <div className="field register-modal-field">
                        <label className="label">Company Industry</label>
                        <input type="text" className="input" onChange={(event) => setCompanyIndustry(event.target.value)} />
                    </div>
                    <button className="button register-post-modal-continue-button" onClick={handleContinue}>Continue</button>
                </div>
            </>
            )}
            {isStepThreeOpen && !isStepTwoOpen && (
                <PostRegisterStepThree name={name} email={email} role={role} country={country} teamSize={teamSize} teamDepartment={teamDepartment} companyName={companyName} companyIndustry={companyIndustry} setIsModalOpen={setIsStepThreeOpen} closeModal={closeModal} />
            )}
        </div>
    )
}

export default PostRegisterStepTwo;
