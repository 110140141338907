import React, { useState } from "react";
import { NavLink, useMatch } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import "./navBar.scss";

import { ReactComponent as TeamsIcon } from '../../assets/newDesign/team-icon.svg';
import { ReactComponent as MyAppsIcon } from '../../assets/newDesign/mywork-icon.svg';
import { ReactComponent as TopArrow } from '../../assets/newDesign/menuarrow-icon.svg';
import { ReactComponent as NewApp } from '../../assets/newDesign/newapp-icon.svg';
import { ReactComponent as TemplateIcon } from '../../assets/newDesign/template-icon.svg';
import { ReactComponent as AppGeneratoIcon } from '../../assets/newDesign/appgenerator-icon.svg';
import { ReactComponent as FavouriteIcon } from '../../assets/newDesign/favourite-icon.svg';

import languageTexts from '../../languajeConstant';
import { SUPERUSER, TEAMADMIN, TEAMMEMBER, USER } from '../../constants';

const NavBarSubMenu = ({team}) => {
    const { language } = useLanguage();
    const user = useSelector((state) => state.session.userInfo);
    const isActive = useMatch({
      path: '/',
      exact: true
    });
    const tabs = [
        {
        Title: languageTexts[language].navbarCreateNewApp,
        Content: (
        <ul>
            <li>
                <NavLink className={`navbar-item team-section`} to="/newapp">
                    <NewApp />
                    {languageTexts[language].navbarStartFromScratch}
                </NavLink>
            </li>
            <li>
                <NavLink className={`navbar-item team-section`} to="/templates">
                    <TemplateIcon />
                    {languageTexts[language].navbarUseTemplate}
                </NavLink>
            </li>
            <li>
                <NavLink className={`navbar-item team-section`} to="/explore/4">
                    <AppGeneratoIcon />
                    {languageTexts[language].navbarAppGenerator}
                </NavLink>
            </li>
        </ul>)
        },
        {
        Title: languageTexts[language].navbarWorkspace,
        Content: (
        <ul>
            <li>
                <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/workspace/myapps">
                    <MyAppsIcon />
                    {languageTexts[language].navBarMyApps}
                </NavLink>
            </li>
            {team && (user.role === SUPERUSER || user.role === TEAMADMIN || user.role === TEAMMEMBER) &&
            <li>
                <NavLink className={`navbar-item team-settings ${isActive ? 'active' : ''}`} to="/workspace/teams">
                    <TeamsIcon />
                    {languageTexts[language].useAppTeam}
                </NavLink>
            </li>
            }
            {user.role !== USER &&
            <li>
                <NavLink className={`navbar-item fav-navbar ${isActive ? 'active' : ''}`} to="/workspace/favorites">
                    <FavouriteIcon />
                    {languageTexts[language].navBarFavorites}
                </NavLink>
            </li>
            }
        </ul>)
        }
    ];
    const [openPanels, setOpenPanels] = useState(Array(tabs.length).fill(false));

    const togglePanel = (index) => {
      const newOpenPanels = [...openPanels];
      newOpenPanels[index] = !newOpenPanels[index];
      setOpenPanels(newOpenPanels);
    };
   
    return(
        <>
            {tabs.map((item, index) => (
            <div key={index} className={`submenu ${openPanels[index] ? 'open' : ''}`}>
                <button className="submenu-button" onClick={() => togglePanel(index)}>
                <TopArrow />
                {item.Title}
                </button>
                <div className="submenu-content">
                {openPanels[index] && item.Content}
                </div>
            </div>
            ))}
        </>
    )
}

export default NavBarSubMenu;
