import axios from "axios";


export async function getApiKeys(token) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": "Bearer " + token,
  
      }
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/api-key/active`, requestOptions);
      return response.data.results;
    } catch (error) {
      throw new Error(error.message);
    }
};

export async function deleteApiKey(token, id) {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "authorization": "Bearer " + token,
  
      }
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/api-key/${id}`, requestOptions);
      return response.data.results;
    } catch (error) {
      throw new Error(error.message);
    }
};

export async function createApiKey(token, apiKeyName) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": "Bearer " + token,
  
      }
    }
    const body = {
        name: apiKeyName
    };
    try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/api-key`, body, requestOptions);
      return response.data.results;
    } catch (error) {
      throw new Error(error.message);
    }
};