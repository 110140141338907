const appGeneratorTexts = {
    es: {
        appGeneratorTitle: 'Generador de Apps',
        appGeneratorDescription: 'Genera una app especialmente para ti con texto \n o audio. Potenciado por los super poderes de la IA de Flipando',
        appGeneratorFormTitle: 'Describe tu idea y déjanos construirla para ti',
        appGeneratorAudioTranscript: 'Transcripción de Audio: ',
        appGeneratoTeam: 'Cualquiera en mi equipo puede usar esta app',
        appGeneratorPublic: 'Cualquiera puede usar esta app',
        appGeneratorPrivate: 'Esta app es privada para mi',
        appGeneratorClear: 'Borrar',
        appGeneratorSubmit: 'Generar mi App',
        appGeneratoCredits: ' (10 Créditos)',
    },
    en: {
        appGeneratorTitle: 'App Generator',
        appGeneratorDescription: 'Generates an app especially for you with simple \ntext or audio. Powered by Flipando\'s AI super powers',
        appGeneratorFormTitle: 'Describe your idea and let us build it for you',
        appGeneratorAudioTranscript: 'Audio Transcript:',
        appGeneratoTeam: 'Anyone in my team can use this app',
        appGeneratorPublic: 'Anyone can use this app',
        appGeneratorPrivate: 'This app is private for me',
        appGeneratorClear: 'Clear',
        appGeneratorSubmit: 'Generate my App',
        appGeneratoCredits: ' (10 Credits)',
    },
};

export default appGeneratorTexts;
