const userSettingsTexts = {
    es: {
        userSettingsDisplayName: 'Nombre',
        userSettingsNamePlaceholder: 'Ingrese nuevo nombre de usuario',
        userSettingsEmail: 'Email',
        userSettingsEmailPlaceholder: 'Ingrese nuevo email',
        userSettingsPhoneNumberPlaceholder: 'Agregar teléfono',
        userSettingsPassword: 'Contraseña del usuario',
        userSettingsOldPassword: 'Contraseña anterior',
        userSettingsNewPassword: 'Contraseña nueva',
        userSettingsOldPasswordPlaceholder: 'Insertar contraseña anterior',
        userSettingsNewPasswordPlaceholder: 'Insertar contraseña nueva',
        userSettingsSubmitButton: 'Enviar',
    },
    en: {
        userSettingsDisplayName: 'Display Name',
        userSettingsNamePlaceholder: 'Enter new display name',
        userSettingsEmail: 'User Email Address',
        userSettingsEmailPlaceholder: 'Enter new email address',
        userSettingsPhoneNumberPlaceholder: 'Enter phone number',
        userSettingsPassword: 'User Password',
        userSettingsOldPassword: 'Old Password',
        userSettingsNewPassword: 'New Password',
        userSettingsOldPasswordPlaceholder: 'Insert old password',
        userSettingsNewPasswordPlaceholder: 'Insert new password',
        userSettingsSubmitButton: 'Submit',
    },
};

export default userSettingsTexts;
