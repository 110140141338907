import axios from "axios";


export async function getSession() {
    const token = localStorage.getItem("flipandoToken");
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        },
    };
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me`, requestOptions);
    return response.data.results;
}

export async function createUser(dataForm) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/signup`, dataForm);
        localStorage.setItem("flipandoToken", response.data.results.access_token);
        return response.data.results;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
}

export async function submitLogin(userData, googleAuth) {
    const data = new FormData();
    data.append('username', userData.username);
    data.append('password', userData.password);
    const params = {params: {google_auth: googleAuth}}

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/token`, data, params);
    if (response.status === 200) {
        localStorage.setItem("flipandoToken", response.data.results.access_token);
        return response.data.results;
    } else {
        throw new Error('Error al login');
    }
} 

export async function sendUserSignupConfirmation(email, userName, password, phone) {
    const body = {
      email: email,
      username: userName,
      hashed_password: password,
      phone_number: phone,
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/regular-signup`, body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };


export async function activateUser(confirmationCode, token) {
    const body = {
        token: token,
        confirmation_code: confirmationCode,
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/activate-account`, body);
        localStorage.setItem("flipandoToken", response.data.results.access_token);
        return response.data.results;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};


export async function resendCode(token) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/resend-code?token=${token}`);
        return response.data.results;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};
