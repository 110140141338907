  import React, { useEffect, useState } from "react";
  import { useSelector } from "react-redux";
  import { Link, useNavigate } from 'react-router-dom';
  import { useLanguage } from '../../hooks/languageHook/LanguageHook';
  import "./explore.scss";

  import { getApps } from "../../api/applications";
  import { getUserFavoriteAppIds } from "../../api/user";
  import { ReactComponent as NewApp } from '../../assets/newDesign/newapp-icon.svg';
  import { ReactComponent as AppGeneratoIcon } from '../../assets/newDesign/appgenerator-icon.svg';
  import { ReactComponent as FlipperIcon } from '../../assets/newDesign/flipper-icon.svg';
  import { ReactComponent as SuperAppsIcon } from '../../assets/newDesign/superapps-icon.svg';
  import { ReactComponent as Arrowscon } from '../../assets/newDesign/openarrow-icon.svg';

  import AllAppsSection from "../../components/allAppsSection/AllAppsSection";
  //import CategoriesButtons from "../../components/categoriesButtons/CategoriesButtons";
  import ErrorMessage from "../../components/errorMessage/ErrorMessage";
  import ExtendedCarousel from "../../components/extendedCarousel/ExtendedCarousel";
  import Searcher from "../../components/searcher/Searcher";
  import Loader from "../../components/loader/Loader";
  import TopBar from "../../components/navBar/TopBar";
  import languageTexts from '../../languajeConstant';
  import FlipperModal from "../../components/flipperModal/FlipperModal";

  const Explore = () => {
      const token = useSelector((state) => state.session.token);
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      const [selectedTab, setSelectedTab] = useState('mostUsed');
      const [errorMessage, setErrorMessage] = useState("");
      const [selectedCategory, setSelectedCategory] = useState(null);
      const [searchQuery, setSearchQuery] = useState("");
      const [recentApps, setRecentApps] = useState([]);
      const [mostUsedApps, setMostUsedApps] = useState([]);
      const [showAllApps, setShowAllApps] = useState(false);
      const [filteredRecentApps, setFilteredRecentApps] = useState([]);
      const [filteredMostUsedApps, setFilteredMostUsedApps] = useState([]);
      const [favoriteAppIds, setFavoriteAppIds] = useState([]);
      const [showFlipperModal, setShowFlipperModal] = useState(false);
      const [templateApps, setTemplateApps] = useState([]);
      const [filteredTemplateApps, setFilteredTemplateApps] = useState([]);

      const navigate = useNavigate();
      const { language } = useLanguage();
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      const isMobile = windowWidth <= 768;
      
      const handleSearch = (query) => {
        setSearchQuery(query);
        applyFilters(query, selectedCategory);
      };

      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      useEffect(() => {
        async function fetchData() {
          try {
            const recentsResponse = await getApps('latest_created');
            const mostUsedResponse = await getApps('most_used');
            const templateApps = await getApps(token);
      
            const recentAppsArray = recentsResponse && recentsResponse.results ? Object.values(recentsResponse.results) : [];
            const mostUsedAppsArray = mostUsedResponse && mostUsedResponse.results ? Object.values(mostUsedResponse.results) : [];
      
            const filteredRecentApps = recentAppsArray.filter(app => !app.is_private);
            const filteredMostUsedApps = mostUsedAppsArray.filter(app => !app.is_private);
      
            setRecentApps(filteredRecentApps);
            setMostUsedApps(filteredMostUsedApps);
            setTemplateApps(templateApps.results.filter((app) => app.is_template));
      
            const favoriteAppsResponse = await getUserFavoriteAppIds(token);
            setFavoriteAppIds(favoriteAppsResponse);
          } catch (error) {
            setErrorMessage("Error to load the apps. " + error.message);
          }
        }
        fetchData();
      }, [token]);
      

      const applyFilters = (query, category) => {
        let newFilteredRecentApps = [...recentApps];
        let newFilteredMostUsedApps = [...mostUsedApps];
        let newFilteredTemplateApps = [...templateApps];

        if (query) {
          const normalizedQuery = removeAccents(query.toLowerCase());
          newFilteredRecentApps = newFilteredRecentApps.filter((app) =>
              removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
          );
          newFilteredMostUsedApps = newFilteredMostUsedApps.filter((app) =>
              removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
          );
          newFilteredTemplateApps = newFilteredTemplateApps.filter((app) =>
              removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
          );
        }
        if (category) {
          newFilteredRecentApps = newFilteredRecentApps.filter((app) => app.category[`name_${language}`] === category);
          newFilteredMostUsedApps = newFilteredMostUsedApps.filter((app) => app.category[`name_${language}`] === category);
          newFilteredTemplateApps = newFilteredTemplateApps.filter((app) => app.category[`name_${language}`] === category);
        }
        setFilteredRecentApps(newFilteredRecentApps);
        setFilteredMostUsedApps(newFilteredMostUsedApps);
        setFilteredTemplateApps(newFilteredTemplateApps);
      };

      useEffect(() => {
        applyFilters(searchQuery, selectedCategory);
      }, [recentApps, mostUsedApps,templateApps, searchQuery, selectedCategory]);

      const renderApps = () => {
          const appsToRender = selectedTab === 'recent' ? filteredMostUsedApps : filteredRecentApps;          
          return (
            <ExtendedCarousel 
              key={selectedTab}
              cards={appsToRender.slice(0, 10)} 
              favoriteApps={favoriteAppIds} 
              miniCards={!isMobile}
            />
          );
      };
      
      return (
        <>
        <main className={`explore`}>
          {!isMobile && <TopBar />}
          <section className="explore-container">
          {showAllApps ? 
          <AllAppsSection 
            mostUsedApps={mostUsedApps}
            recentApps={recentApps}
            exploreMostUsedText={languageTexts[language].exploreMostUsed}
            exploreRecentText={languageTexts[language].exploreRecent} /> :
          <>
          <ErrorMessage message={errorMessage} />
          {!isMobile ?
            <header className="explore-header">
              <h1>{languageTexts[language].exploreTitle}</h1>
              <div className="explore-header-navegation">
                <Link to={'/newapp'} className="explore-header-navegation-button">
                  <i className='button-newapp'><NewApp /></i>
                  <Arrowscon className='arrow-deco' />
                  <span>{languageTexts[language].exploreCreateApp}</span>
                  {languageTexts[language].exploreCreateAppSubtitle}
                </Link>
                <button className="explore-header-navegation-button" onClick={() => (setShowFlipperModal(!showFlipperModal))}>
                  <i className='button-flipper'><FlipperIcon /></i>
                  <Arrowscon className='arrow-deco' />
                  <span>{languageTexts[language].exploreFlipper}</span>
                  {languageTexts[language].exploreFlipperSubtitle}
                </button>
                <Link to={'/explore/4'} className="explore-header-navegation-button">
                  <i className='button-appgenerator'><AppGeneratoIcon /></i>
                  <Arrowscon className='arrow-deco' />
                  <span>{languageTexts[language].exploreAppGenerator}</span>
                  {languageTexts[language].exploreAppGeneratorSubtitle}
                </Link>
                <span data-tooltip={languageTexts[language].tooltipComingSoon} className="explore-header-navegation-button">
                  <i className='button-superapps'><SuperAppsIcon /></i>
                  <Arrowscon className='arrow-deco' />
                  <span>{languageTexts[language].exploreSuperApps}</span>
                  {languageTexts[language].exploreSuperAppsSubtitle}
                </span>
              </div>
              <Searcher onSearch={handleSearch} placeholder="Search" />
            </header>
            :
            <header className="explore-header">
              <Searcher onSearch={handleSearch} placeholder="Search" />
            </header>
            }
              <section className="explore-most-used">
                <header className='explore-most-used-title'>
                    <div className="explore-most-used-tabs">
                      <button
                            onClick={() => setSelectedTab('mostUsed')}
                            className={`showAllApps-apps-tabs-button ${selectedTab === 'mostUsed' ? 'active' : ''}`}
                        >
                            {languageTexts[language].exploreMostRecent}
                        </button>
                        <button
                            onClick={() => setSelectedTab('recent')}
                            className={`showAllApps-apps-tabs-button ${selectedTab === 'recent' ? 'active' : ''}`}
                        >
                            {languageTexts[language].exploreMostUsed}
                        </button>
                    </div>
                    <button className="button explore-subtitle-button" onClick={() => setShowAllApps(true)}>{languageTexts[language].exploreShowAll}</button>
                  </header>
                  {renderApps()}
              </section>
              <section className="explore-templates">
                <h2 className="explore-subtitle">
                  {languageTexts[language].exploreTemplateTitle}
                  <button className="button explore-subtitle-button" onClick={() => navigate('/templates')}>{languageTexts[language].exploreShowAll}</button>
                </h2>
                {filteredTemplateApps ? (
                  <ExtendedCarousel cards={filteredTemplateApps.slice(0, 10)} favoriteApps={favoriteAppIds} miniCards/>
                ) : (
                  <Loader />
                )}
              </section>
              </>
          }
          </section>
        </main>
        {showFlipperModal && <FlipperModal setFlipperModal={setShowFlipperModal} />}
        </>
      );
    };
    
  export default Explore;