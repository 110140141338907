import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';

import { getPreviewCompletions } from "../../api/applications";
import { checkForResult } from "../../api/backgroundTasks";

import Loader from "../../components/loader/Loader";
import { outputFormats } from '../../constants';
import languageTexts from '../../languajeConstant';
import { lang } from "moment";


const AppPreview = ({ previewInputs, initialLogic, modelId, documentChecked }) => {
  const [result, setResult] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const [error, setError] = useState("Logic must have content");
  const [, setTextToCopy] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileDescription, setFileDescription] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFormat, setSelectedFormat] = useState('general');
  const { language } = useLanguage();

  const token = useSelector((state) => state.session.token);

  const handleFileUpload = (event) => {
    setUploadedFile(event.target.files[0]);
  }
  const handleRemoveFile = () => {
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }
  useEffect(() => {
    if (!documentChecked) {
      setUploadedFile(null);
    } 
  }, [documentChecked]);   
  const handleChangeInputs = (index, event) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = {
      ...updatedInputValues[index],
      value: event.target.value,
    };
    setInputValues(updatedInputValues);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let value = initialLogic;
    previewInputs.forEach((input, index) => {
      const placeholder = `{${input.name}}`;
      const replacement = inputValues[index].value;
      value = value.split(placeholder).join(replacement);
    });
    try {
      const response = await getPreviewCompletions(value, modelId, uploadedFile, fileDescription, selectedFormat, token);
      const data = response.data.results;
      if (data && data.id) {
        setTimeout(() => {
          checkForResult(data.id, setLoading, setResult, setError, setTextToCopy, 3000);
        }, 750);
      }
    } catch (error) {
      console.error('Error starting task processing:', error);
      setLoading(false);
      setError(error.message);
    }
  };
  useEffect(() => {
    if (initialLogic === "" || previewInputs.some((input) => input.name === "")) {
      setError(languageTexts[language].newAppNoLogicNorVariables);
    } else {
      setError("");
    }

    setInputValues(
      previewInputs.map((input) => ({
        name: input.name,
        value: input.value,
        placeholder: input.value,
      }))
    );
  }, [previewInputs, initialLogic, token]);

  return (
    <section className="column new-app-preview">
      <h1>{languageTexts[language].newAppPreview}</h1>
      <form className="new-app-form" onSubmit={handleSubmit}>
      {documentChecked && (
          <div className="new-app-form-field">
            <label className="label">File Description</label>
            <input
              required
              className="input"
              type="text"
              name="file_description"
              placeholder="Describe what this file is about"
              onChange={(event) => setFileDescription(event.target.value)}
            />
            <input
              type='file'
              id='file'
              name='file'
              accept='.pdf, .docx, .csv'
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            {uploadedFile ?
              <div className='new-app-form-file-uploaded'>
                <p>File uploaded: {uploadedFile.name} <button type="button" className='button new-app-form-remove-file' onClick={handleRemoveFile}>X</button></p>
              </div> :
              <label
                htmlFor="file"
                className="button new-app-form-upload-button"
              >
                Upload File
              </label>
            }            
          </div>
        )}
        {previewInputs.map((inputObj, index) => (
          <div key={index} className="new-app-form-field">
            <label className="label">{inputObj.name}</label>
            <input
              required
              className="input"
              type="text"
              name={inputObj.name}
              placeholder={inputObj.placeholder}
              value={inputValues[index]?.value || ""}
              onChange={(event) => handleChangeInputs(index, event)}
            />
          </div>
        ))}
        <div className='field'>
          <label className='label'>{languageTexts[language].newAppOutputFormat}</label>
          <div className="select-container">
            <select
              className='select'
              value={selectedFormat}
              onChange={(event) => setSelectedFormat(event.target.value)}
            >
              {outputFormats.map((format) => (
                <option key={format.value} value={format.value}>{format.label[language]}</option>
              ))}
            </select>
          </div>
        </div>
        <button
          className="button new-app-submit-button"
          type="submit"
          disabled={loading}
        >
          {languageTexts[language].newAppSubmitPreviewButton}
        </button>
        {loading && <Loader />}
        {error && <p>{error}</p>}
        {result && <div dangerouslySetInnerHTML={{ __html: result.results.results }} />}
      </form>
    </section>
  );
};    

export default AppPreview;
