import React, { useState, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Plan from './Option';

import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import { cancelSubscription, getActiveSubscription } from "../../api/subscriptions";
import ToggleButton from "../../components/toggleButton/ToggleButton"
import ContactUsModal from "./ContactUsModal";
import SubscriptionButtonWrapper from './SubscriptionButtonWrapper';
import Loader from '../../components/loader/Loader';
import { ReactComponent as ArrowIcon } from '../../assets/right-arrow-icon.svg';
import { initializeUser } from '../../redux/actions/authActions';
import { feedbackEmail } from '../../api/subscriptions';

import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';

const PlanSelectionAndSubscription = ({
    plans, 
    setSelectedPlan, 
    setShowModalBackground,
    selectedPlan,
    token,
    setSubscriptionCompleted,
    setErrorMessage,
    userSubscription,
    setUserSubscription,
    userInfo}) => {
        
    const [teamMembers, setTeamMembers] = useState(10);
    const [teamName, setTeamName] = useState("");
    const [teamColor, setTeamColor] = useState("#000");
    
    const [proceedToPaymentClicked, setProceedToPaymentClicked] = useState(false);
    const [customPlanClicked, setCustomPlanClicked] = useState(false);
    const [yearlyPeriod, setYearlyPeriod] = useState(false);
    const [modalPlanActive, setModalPlanActive] = useState(false);
    const [modalSwitchPlan, setModalSwitchPlan] = useState(false);
    const [modalCancelPlan, setModalCancelPlan] = useState(false);
    const [modalFeedback, setModalFeedback] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState('');
    const { language } = useLanguage();

    const dispatch = useDispatch();

    const handleCancelSubscription = async (forfeit) => {
        setLoading(true);
        if (!forfeit){
            await handleFeedbackInfo();
        }
        try {
        const result = await cancelSubscription(token, userSubscription.paypal_subscription_id, forfeit);
        const subscriptionResponse = await getActiveSubscription(token);
        dispatch(initializeUser());
        setUserSubscription(subscriptionResponse);
        setModalCancelPlan(false);
        setModalSwitchPlan(false);
        setModalFeedback(false);
        setLoading(false);

    } catch (error) {
        console.error("Failed to cancel subscription: ", error.message);
        }
    };

    const handleFeedbackInfo = async () => {
        feedbackEmail(userInfo.userName, userInfo.email, feedback, token)
            .then(() => {
                alert(languageTexts[language].subscriptionsContactUsModalSuccess);
                handleCancelButton();
            })
            .catch(error => {
                setErrorMessage('Error sending contact-us email. ' + error.message);
                console.error('Something went wrong when sending contact-us email. ' + error.message);
            });
    }

    const openCancelSucscriptionModal = () => {
        setModalCancelPlan(true);
    }

    const handleChangeSubscription = async () => {
        setModalSwitchPlan(true);
    };

    const teamMembersOptions = [
        {
            name: "Tier1",
            value: 10,
            label: "Tier 1 - 1-10 seats - $150 monthly / $120 billed yearly"
        }, 
        {
            name: "Tier2",
            value: 25,
            label: "Tier 2 - 11-25 seats - $300 monthly / $240 billed yearly"
        },
        {
            name: "Tier3",
            value: 100,
            label: "Tier 3 - 26-100 seats - $1000 monthly / $800 billed yearly"
        },
        {
            name: "Tier4",
            value: 300,
            label: "Tier 4 - 101 - 300 seats - $2400 monthly / $1920 billed yearly"
        },
    ]
    const handlePlanChange = (paypalPlanId) => {
        if(!userSubscription) {
            setSelectedPlan(currentPlans.find(plan => plan.paypal_plan_id === paypalPlanId));
            setProceedToPaymentClicked(false);
            setShowModalBackground(true);
        }
        else {
            setModalPlanActive(true);
        }
    }
    const handleCustomPlan = () => {
      setShowModalBackground(true);
      setCustomPlanClicked(true);
    }
    const handleProceedToPayment = () => {
        if (teamMembers !== "" && teamName !== "" && teamColor !== "") {
            setProceedToPaymentClicked(true);
            setErrorMessage("");
        } else {
            setErrorMessage("Please complete all the required fields to create a new team.")
        }
        };
        const handleGoBack = () => {
        setProceedToPaymentClicked(false);
    };

    const handleCancelButton = () => {
        setShowModalBackground(false);
        setCustomPlanClicked(false);
        setModalPlanActive(false);
        setModalSwitchPlan(false);
        setModalCancelPlan(false);
        setModalFeedback(false);
        setSelectedPlan("");
    };
    
    const handleOpenModalFeedback = () => {
        setModalFeedback(true);
        setModalCancelPlan(false);

    }

    const currentPlans = useMemo(() => {
        return yearlyPeriod ? plans.yearly : plans.monthly;
    }, [yearlyPeriod, plans]);
    return (
        <>
        {loading && <Loader />}
        <section className="subscription-content active-subscription">
        <header className="subscription-content-header">
          <h2>{languageTexts[language].subscriptionsSubtitle}</h2>
          <div className="subscription-content-header-toggle">
            <span className="subscription-content-header-toggle-label">{languageTexts[language].subscriptionsMonthly}</span>
            <ToggleButton isOn={yearlyPeriod} setIsOn={setYearlyPeriod} />
            <span className="subscription-content-header-toggle-label">{languageTexts[language].subscriptionsYearly} ({languageTexts[language].subscriptionsSave} 20%) </span>
          </div>
        </header>
        <div className="subscription-content-plans">
        {currentPlans?.map((plan, index) => (
            <Plan 
            key={index} 
            planDetails={plan} 
            handlePlanChange={handlePlanChange} 
            userSubscription={userSubscription} 
            handleCancelSubscription={openCancelSucscriptionModal}
            handleChangeSubscription={handleChangeSubscription}
            />
        ))}
        </div>
        <div className="subscription-content-customPlan">
            <div className="subscription-content-customPlan-column">
                <h2>{languageTexts[language].subscriptionsCustomTitle}</h2>
                <p>{languageTexts[language].subscriptionsCustomDescription}</p>
                <button className="button" onClick={() => handleCustomPlan()}>{languageTexts[language].subscriptionsCustomBtn}</button>
            </div>
            <div className="subscription-content-customPlan-column">
                <p>{languageTexts[language].subscriptionsCustomPre}</p>
                <ul>
                    <li><CheckIcon/> {languageTexts[language].subscriptionsCustomContent1}</li>
                    <li><CheckIcon/> {languageTexts[language].subscriptionsCustomContent2}</li>
                    <li><CheckIcon/> {languageTexts[language].subscriptionsCustomContent3}</li>
                    <li><CheckIcon/>  {languageTexts[language].subscriptionsCustomContent4}</li>
                </ul>
            </div>
        </div>
    </section>

    {selectedPlan.type === "Teams" && !proceedToPaymentClicked && (
        <form onSubmit={handleProceedToPayment} className="modal subscription-modal special-height">
        <h2>New Team Data</h2>
        <div className="field">
            <label>Team Name</label>
            <input
            className="input"
            type="text"
            placeholder="Team Name"
            value={teamName} 
            onChange={(e) => setTeamName(e.target.value)}
            required
            />
        </div>
        <div className="field">
            <label>Team Members</label>
            <select className="input" value={teamMembers} onChange={(e) => setTeamMembers(e.target.value)}>
            {teamMembersOptions?.map((option, key) => (
                <option key={key} value={option.value}>
                    {option.label}
                </option>
            ))}
            </select>
        </div>
        <div className="field">
            <label>Team Color</label>
            <input
            className="input"
            type="color"
            placeholder="Team Color"
            value={teamColor} 
            onChange={(e) => setTeamColor(e.target.value)}
            required
            />
        </div>
        {!teamName && <p className="help is-danger">Please Add the name of the team</p>}
        <div className="subscription-modal-buttons">
            <button className="button proceed-btn" disabled={!teamName || !teamMembers} type="submit">Proceed to Payment</button>
            <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>
        </div>
        </form>
    )}

    {proceedToPaymentClicked && selectedPlan.type === "Teams" && (
        <div className="modal subscription-modal">
        <h2>Team Data Summary</h2>
            <p>Name: {teamName}</p>         
            <p>Members: {teamMembers}</p>
        <p>
            Color:{" "}
            <span className="team-color-box" style={{ backgroundColor: teamColor }}></span>
            {teamColor}
        </p>
        <div className="button-wrapper">            
            <button onClick={handleGoBack} className="button reset-btn">Reset</button>
            <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>
        </div>
        <PayPalScriptProvider
            options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            components: "buttons",
            intent: "subscription",
            vault: true
            }}
        >
            <SubscriptionButtonWrapper 
            selectedPlan={selectedPlan} 
            teamMembers={teamMembers} 
            teamName={teamName} 
            teamColor={teamColor} 
            token={token} 
            setSubscriptionCompleted={setSubscriptionCompleted} 
            setErrorMessage={setErrorMessage} />
        </PayPalScriptProvider>
        
        </div>
    )}
    {selectedPlan &&
        (selectedPlan.type !== "Teams") && (
            <div className="modal subscription-modal modal-pro">
                <h2>Proceed to Payment</h2>
                <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>

                <PayPalScriptProvider
                    options={{
                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    components: "buttons",
                    intent: "subscription",
                    vault: true
                    }}
                >
                    <SubscriptionButtonWrapper 
                    selectedPlan={selectedPlan} 
                    teamMembers={teamMembers} 
                    teamName={teamName} 
                    teamColor={teamColor} 
                    token={token} 
                    setSubscriptionCompleted={setSubscriptionCompleted} 
                    setErrorMessage={setErrorMessage} />
                </PayPalScriptProvider>
            </div>
        )}
        {customPlanClicked &&
        <ContactUsModal handleCancelButton={handleCancelButton} />
        }
        {modalPlanActive &&
            <div className="modal subscription-modal small-modal">
                <h1>You need to cancel your Current Subscription First.</h1>
            <button className='active-plan-cancel modal-button' onClick={openCancelSucscriptionModal}>Cancel Subscription <ArrowIcon /></button>
                <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>
            </div>
        }
        {modalSwitchPlan &&
            <div className="modal subscription-modal subscription-switch">
                <header className="modal-head">
                    <h1>Thinking about switching plans?</h1>
                    <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>
                </header>
                <div className="modal-body">
                    <p>You can upgrade or downgrade to Free, Pro, or Teams right away, but please be aware that any remaining time on your current plan will be forfeited. Make sure you're ready for the switch before proceeding.</p>
                    <button className='active-plan-cancel modal-button' onClick={() => handleCancelSubscription(true)}>Ready to explore new horizons? <span>Go ahead and choose your desired plan!</span></button>
                </div>
            </div>
        }
        {modalCancelPlan &&
            <div className="modal subscription-modal subscription-switch">
                <header className="modal-head">
                    <h1>Are you sure about ending your Flipando journey?</h1>
                    <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>
                </header>
                <div className="modal-body">
                    <p>Please note that any projects created using Pro features will become inaccessible once the subscription is canceled. Your subscription will remain active until {userSubscription.next_renewal_date}, ensuring you get the most out of the remaining time you've already paid for.</p>
                    <button className='active-plan-cancel modal-button' onClick={handleOpenModalFeedback}>Proceed with cancellation</button>
                </div>
            </div>
        }
        {modalFeedback &&
            <div className="modal subscription-modal subscription-switch subscription-feedback">
                <header className="modal-head">
                    <h1>Your Feedback Matters</h1>
                    <button onClick={handleCancelButton} className="modal-close is-large">Cancel</button>
                </header>
                <div className="modal-body">
                    <p>Before you go, please share your reason for canceling your subscription. Your insights will help us enhance Flipando for everyone!</p>
                    <textarea className="input"onChange={(event) => setFeedback(event.target.value)} />
                    <p>Thank you for sharing your thoughts!</p>
                    <button className='active-plan-cancel modal-button' onClick={() => handleCancelSubscription(false)}>Submit</button>
                </div>
            </div>
        }
        </>
    );
  };

  export default PlanSelectionAndSubscription;