const recentTexts = { 
    es: {
        recentNotApps: 'Recientes es tu historial personal de apps. Te ayuda a encontrar rápidamente las apps que usaste recientemente.',
        recentNotAppsSubtitle: '¿Listo para sumergirte en nuevas experiencias? ¡empieza ahora mismo!',
        recentNotAppsBtn: 'Explora todas las apps',
        recentTitle: 'Últimas apps utilizadas:',
    },
    en: {
        recentNotApps: 'Recents is your personal history of apps. It helps you quickly find the apps you recently used.',
        recentNotAppsSubtitle: 'Ready to dive into new experiences? Start right now!',
        recentNotAppsBtn: 'Explore all apps',
        recentTitle: 'Latest apps used:',
    }
}

export default recentTexts;