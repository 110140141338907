const learningTexts = {
    es: {
        learningNewTitle: 'Recursos de Aprendizaje',
        learningTitle: 'Preguntas Frecuentes',
        learningQuestion1: '¿Qué es Flipando?',
        learningAnswer1: 'Flipando es una plataforma GenAI fácil de usar, diseñada para construir, almacenar, compartir, versionar, controlar y mantener tus aplicaciones GenAI personalizadas sin necesidad de programar.',
        learningQuestion2: '¿Qué es una aplicación GenAI en Flipando?',
        learningAnswer2: 'Una aplicación GenAI en Flipando es como una caja de herramientas mágica. Es una aplicación web que toma tus inputs y, utilizando la lógica y el modelo específico que elijas, los transforma en outputs significativos. Estas aplicaciones aumentan la productividad y la eficiencia aprovechando la inteligencia artificial para abordar microtareas y aumentar la inteligencia.',
        learningQuestion3: '¿Necesito tener conocimientos de Inteligencia Artificial o programación para usar Flipando.ai?',
        learningAnswer3: '¡No se requiere programación ni experiencia en IA! Flipando ofrece un enfoque sin código, lo que te permite expresar tus ideas en tu lenguaje natural. Traduciremos tus conceptos en código sin esfuerzo, y nuestro equipo está disponible para ayudarte (support@flipando.ai).',
        learningQuestion4: '¿Qué tan precisa es la respuesta de las aplicaciones generadas?',
        learningAnswer4: 'Las aplicaciones generadas por Flipando son útiles y sorprendentes, pero el control humano sobre las herramientas de IA es fundamental. No olvides usar las aplicaciones de manera responsable y verificar la información crítica con fuentes confiables. 🔍🧠 A veces los modelos de generación de texto alucinan generando diferentes respuestas no verificadas, con mejores prompts y uso, tus respuestas se volverán cada vez más precisas y eficientes. 🚀',
        learningQuestion5: '¿Qué sucede si encuentro un error o problema en mi aplicación de Flipando?',
        learningAnswer5: 'Si el problema es técnico, escríbenos (support@flipando.ai) y lo resolveremos lo antes posible. Si deseas modificar tu aplicación porque el resultado no fue el esperado, no hay problema. Simplemente ve a la aplicación y haz clic en **Editar mi aplicación**, puedes editar y seguir probando hasta que la respuesta te deje satisfecho. Si deseas modificar tu aplicación porque el resultado no fue el esperado, no hay problema. Simplemente ve a la aplicación y haz clic en Editar mi aplicación, puedes editar y seguir probando hasta que la respuesta te deje satisfecho.',
        learningQuestion6: '¿Qué tipo de soporte técnico se ofrece en caso de dificultades?',
        learningAnswer6: 'Para cualquier usuario Pro proporcionamos un Account Manager específico para ayudarte, con quien puedes comunicarte y él o ella te brindará el soporte técnico necesario. 🤝 También puedes contactar con [tech@flipando.ai](mailto:tech@flipando.ai) y te responderemos lo antes posible. 📧🏃‍♂️😊',
        learningQuestion7: '¿Quién puede ver y usar las aplicaciones de Flipando?',
        learningAnswer7: 'Si eres un usuario Pro, solo quienes pertenecen a tu Equipo pueden usar tus aplicaciones de Flipando. 👥 Si lo deseas, también puedes compartirlas para cualquier usuario dentro de Flipando.🌐 En conclusión, como usuario Pro, tú eliges quién puede ver y usar tus aplicaciones. Si no tienes la suscripción Pro, cualquier usuario de Flipando puede ver y usar tus aplicaciones. 😊 Gracias por compartir. 🙏',
        learningQuestion8: '¿Cómo se garantiza la privacidad y confidencialidad de los datos en la plataforma Flipando.ai?',
        learningAnswer8: 'La plataforma cumple con los más altos estándares de seguridad, incluida la encriptación de datos y el uso de medidas de seguridad avanzadas que impiden cualquier acceso no autorizado o fuga de información. 🔒🛡️💻 Flipando garantiza que los datos no se compartirán con terceros y solo se utilizarán para los fines específicos de la organización. 📋',
        learningQuestion9: '¿Cómo crear una aplicación en Flipando.ai?',
        learningAnswer9_1: 'Mira este video a continuación para ver la creación en vivo de una APP.',
        learningAnswer9_2: 'De lo contrario, tienes a continuación una Guía de Usuario para crear tu app',
        learningAnswer9_3: 'Desde la página principal de la plataforma ',
        learningAnswer9_4: 've a CREAR NUEVA APP donde hay diferentes etiquetas para completar.',
        learningAnswer9_5: 'Sección de información de la aplicación.',
        learningAnswer9_6: 'En esta sección puedes definir el nombre de tu aplicación y una descripción de lo que hace. En esta sección también decides qué modelo usar, ya sea el generador de texto Chat GPT u otros que vendrán.',
        learningAnswer9_7: 'Recuerda categorizar la aplicación en la sección que le corresponde para mantener organizada y efectiva tu biblioteca de aplicaciones.',
        learningAnswer9_8: 'Sección de variables',
        learningAnswer9_9: 'Aquí debes pensar en qué información vas a modificar según el caso específico. Tienes que decidir el nombre de las variables y también tienes una sección con una descripción que sirve de guía para saber qué escribir en esa variable y cómo.',
        learningAnswer9_10: '¡Puedes poner todas las variables que creas necesarias! Simplemente ve a Agregar campo, y se creará una nueva variable.',
        learningAnswer9_11: 'Un ejemplo claro de variables para guiarte podría ser en el caso de una aplicación que te ayude a planificar unas vacaciones, en este caso las variables que usaríamos podrían ser:',
        learningAnswer9_12: 'Variable 1: Duración del viaje Descripción: Ej. 1 semana',
        learningAnswer9_13: 'Variable 2: Destino elegido Descripción: Lugares que estás pensando visitar',
        learningAnswer9_14: 'Variable 3: Tipo de viaje Descripción: ¿Viajas solo? ¿Con familia? ¿Con amigos?',
        learningAnswer9_15: 'Una vez que hayamos determinado las variables, vamos a la tercera y última sección de nuestra futura aplicación. LÓGICA Aquí escribiremos el prompt para nuestra aplicación, y agregaremos las variables creadas según corresponda. Las variables aparecerán debajo del cuadro donde estarás haciendo el prompt, y según corresponda haces clic en la variable para agregarla automáticamente a tu lógica de prompt.',
        learningAnswer9_16: 'Por ejemplo, en la aplicación de viajes que estábamos viendo antes, una Lógica podría ser:',
        learningAnswer9_17: 'Generar una guía de viaje que dure (AQUÍ HACES CLIC EN VARIABLE 1 - DURACIÓN). Voy a estar viajando a (CLIC EN VARIABLE 2 - DESTINO), y el tipo de viaje será (CLIC EN VARIABLE 3 - TIPO DE VIAJE).',
        learningAnswer9_18: 'En el lado izquierdo de tu pantalla podrás ver cómo se ven las variables con sus descripciones, y podrás probar tu aplicación antes de publicarla. Si completas cada una de las variables y haces clic en Publicar, podrás ver cómo tu prompt se transforma en el deseado.',
        learningAnswer9_19: 'Una vez que sientas que la aplicación está lista, ve a PUBLICAR, abajo y a la derecha, ¡y listo! ¡Flip out!',
        learningAnswer9_20: 'No olvides que siempre puedes volver a tu aplicación y editar/mejorarla así que no tengas miedo de equivocarte!',
        learningAnswer9_21: 'No olvides probar y sumergirte en la creación de las aplicaciones, es la mejor manera de aprender. Y cualquier duda que surja puedes escribirle a Mica (mica@flipando.ai), que estará encantada de ayudarte!',
        learningQuestion10: '¿Qué son las aplicaciones plantilla?',
        learningAnswer10: 'Son aplicaciones creadas por Flipando que pueden ayudarte con tareas específicas. Explora la galería de aplicaciones plantilla y seguro que te sorprenderás. También puedes crear una aplicación a partir de una aplicación plantilla. Esto puede ayudarte a tener una guía para tus nuevas aplicaciones, o para generar con la misma base de las aplicaciones plantilla más específicas para ti y tu equipo. Puedes encontrar todas las plantillas en el menú de la izquierda de tu pantalla, bajo Plantillas',
        learningQuestion11: '¿Cómo creo una aplicación a partir de una aplicación plantilla?',
        learningAnswer11: 'Desde la plantilla elegida simplemente ve a Usar plantilla, arriba y a la izquierda de tu pantalla. Una vez que hagas clic en ella, se abrirá una pantalla con todas las variables, y la lógica utilizada en la creación de esta plantilla. Siéntete libre de agregar y modificar las variables y el prompt según se adapte mejor a tus necesidades.',
        learningQuestion12: '¿Cómo edito mis aplicaciones?',
        learningAnswer12: 'Primero ve a la sección donde encontrarás todas las aplicaciones generadas por ti. Ve a la sección Mis aplicaciones en el menú de la izquierda de la pantalla principal. Luego selecciona la aplicación que deseas editar. Una vez dentro de la aplicación, si vas a la parte superior derecha puedes seleccionar Editar mi aplicación, haz clic en ella, y se mostrarán todas las variables y la lógica que utilizaste para que puedas editarlas.',
        learningQuestion13: '¿Puedo eliminar mis aplicaciones?',
        learningAnswer13: '¡Sí, por supuesto! Ve a la aplicación que has creado, y en la parte superior derecha haz clic en Eliminar mi aplicación, y listo.',
        learningVideos: 'Videos',
    },
    en: {
        learningNewTitle: 'Learning Resources',
        learningTitle: 'FAQ\'s',
        learningQuestion1: 'What is Flipando?',
        learningAnswer1: 'Flipando is a user-friendly GenAI Platform, designed for building, storing, sharing, versioning, controlling, and maintaining your personalized GenAI Apps without any need for coding.',
        learningQuestion2: 'What is a GenAI App in Flipando?',
        learningAnswer2: 'A GenAI App in Flipando is like a magic toolbox. It\'s a web application that takes your inputs and, using the specific logic and model you choose, transforms them into meaningful outputs. These apps enhance productivity and efficiency by leveraging artificial intelligence to tackle microtasks and boost intelligence.',
        learningQuestion3: 'Do I need to have knowledge of Artificial Intelligence or programming to use Flipando.ai?',
        learningAnswer3: 'No coding or AI expertise required! Flipando offers a no-code approach, allowing you to express your ideas in your natural language. We\'ll translate your concepts into code effortlessly, and our team is available to assist you (support@flipando.ai).',
        learningQuestion4: 'How accurate is the performance of the generated applications?',
        learningAnswer4: 'The applications generated by Flipando are useful and amazing, but human control over AI tools is fundamental. Do not forget to use the applications responsibly and to verify critical information with reliable sources. 🔍🧠 Sometimes the text generation models hallucinate by generating different unverified responses, with better prompts and use, your responses will become increasingly precise and efficient. 🚀',
        learningQuestion5: 'What happens if I find an error or problem in my Flipando\'s App?',
        learningAnswer5: 'If the problem is technical, write to us (support@flipando.ai) and we will solve it as soon as possible. If you want to modify your app because the result was not what you expected, no problem. Just go to the app and click on **Edit My App**, you can edit and keep testing until the response leaves you pleased. If you want to modify your app because the result was not what you expected, no problem. Just go to the app and click on Edit My App, you can edit and keep testing until the response leaves you pleased.',
        learningQuestion6: 'What kind of technical support is offered in case of difficulties?',
        learningAnswer6: 'For any Pro user we provide a specific Account Manager to help you, with whom you can communicate and he or she will provide you with the necessary technical support. 🤝 You can also contact [tech@flipando.ai](mailto:tech@flipando.ai) and we will get back to you as soon as possible. 📧🏃‍♂️😊',
        learningQuestion7: 'Who can see and use your Flipando\'s Apps?',
        learningAnswer7: 'If you are a Pro user, only those who belong to your Team can use your Flipando\'s Apps. 👥 If you wish, you can also share them for any user within Flipando.🌐 In conclusion, as a Pro user, you choose who can see and use your apps. If you do not have the Pro subscription, any Flipando user can see and use your apps. 😊 Thank you for sharing. 🙏',
        learningQuestion8: 'How is the privacy and confidentiality of data ensured on the Flipando.ai platform?',
        learningAnswer8: 'The platform complies with the highest security standards, including data encryption and the use of advanced security measures that prevent any unauthorized access or information leakage. 🔒🛡️💻 Flipando guarantees that the data will not be shared with third parties and will only be used for the specific purposes of the organization. 📋',
        learningQuestion9: 'How to create an app in Flipando.ai?',
        learningAnswer9_1: 'Watch this video below to see the live creation of an APP.',
        learningAnswer9_2: 'Otherwise, you have below a User Guide for creating your app',
        learningAnswer9_3: 'From the main page of the platform ',
        learningAnswer9_4: 'go to CREATE NEW APP where there are different labels to complete.',
        learningAnswer9_5: 'App info Section.',
        learningAnswer9_6: 'In this section you can define the name of your app and a description of what it does. In this section you also decide which model to use, whether it is the Chat GPT text generator or others to come.',
        learningAnswer9_7: 'Remember to categorize the app in the section that corresponds to it in order to keep your app library organized and effective.',
        learningAnswer9_8: 'Variables Section',
        learningAnswer9_9: 'Here you should think about what information you are going to modify according to the specific case. You have to decide the name of the variables and you also have a section with a description that serves as a guide to know what to write in that variable and how.',
        learningAnswer9_10: 'You can put all the variables that you think are necessary! Simply go to Add field, and a new variable will be created.',
        learningAnswer9_11: 'A clear example of variables to guide you could be in the case of an app that helps you plan a vacation, in this case the variables we would use could be:',
        learningAnswer9_12: 'Variable 1: Duration of the trip Description: Ex. 1 week',
        learningAnswer9_13: 'Variable 2: Chosen destination Description: Places you are thinking of visiting',
        learningAnswer9_14: 'Variable 3: Type of trip Description: Are you traveling alone? With family? With friends?',
        learningAnswer9_15: 'Once we have determined the variables, we go to the third and final section of our future app. LOGIC Here we will write the prompt for our app, and we will be adding the created variables as appropriate. The variables will appear below the box where you will be making the prompt, and as appropriate you click on the variable to automatically add it to your prompt logic.',
        learningAnswer9_16: 'For example, in the travel app we were looking at before, a Logic could be:',
        learningAnswer9_17: 'Generate a travel guide that lasts (HERE YOU CLICK ON VARIABLE 1 - DURATION). I\'m going to be traveling to (CLICK ON VARIABLE 2 - DESTINATION), and the type of trip will be (CLICK ON VARIABLE 3 - TYPE OF TRIP).',
        learningAnswer9_18: 'On the left side of your screen you will be able to see how the variables with their descriptions look, and you will be able to test your app before publishing it. If you fill in each of the variables and click on Publish, you will be able to see how your prompt is transformed into the desired one.',
        learningAnswer9_19: 'Once you feel that the app is ready, go to PUBLISH, below and to the right, and you\'re done! Flip out!',
        learningAnswer9_20: 'Don\'t forget that you can always go back to your app and edit/improve it so don\'t be afraid to make mistakes!',
        learningAnswer9_21: 'Don\'t forget to try and dive in to the creation of the apps, it\'s the best way to learn. And any questions that arise you can write to Mica (mica@flipando.ai), who will be happy to help you!',
        learningQuestion10: 'What are template apps?',
        learningAnswer10: 'They are apps created by Flipando that can help you with specific tasks. Explore the gallery of template apps and you will surely be surprised. You can also create an app from a template app. This can help you have a guide for your new apps, or to generate with the same base of the templates apps more specific ones for you and your team. You can find all the templates in the menu on the left side of your screen, under Templates',
        learningQuestion11: 'How do I create an app from a template app?',
        learningAnswer11: 'From the chosen template simply go to Use template, above and to the left of your screen. Once you click on it, a screen will open with all the variables, and the logic used in the creation of this template. Feel free to add and modify the variables and prompt as best fits your needs.',
        learningQuestion12: 'How do I edit my apps?',
        learningAnswer12: 'First go to the section where you will find all the apps generated by you. Go to the My Apps section in the left-hand menu of the main screen. Then select the App you want to edit. Once inside the app, if you go to the top right you can select Edit my App, click on it, and all the variables and Logic you used will be displayed so you can edit them.',
        learningQuestion13: 'Can I delete my apps?',
        learningAnswer13: 'Yes of course! Go to the app you have created, and at the top right click on Delete my app, and thats it.',
        learningVideos: 'Videos',
    },
};

export default learningTexts;
