import React, { useEffect, useState } from 'react';
import { ReactComponent as HeartSVG } from '../../assets/favorite-heart.svg';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import './heartButton.scss';


const HeartButton = ({ isFavorited, onFavorite, className, canFavApps, onUpdateFavorites }) => {
  const [favorite, setFavorite] = useState(isFavorited);
  const { language } = useLanguage();
  
  useEffect(() => {
    setFavorite(isFavorited);
  }, [isFavorited]);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onFavorite(!favorite);
    if (onUpdateFavorites) onUpdateFavorites();
    setFavorite(!favorite);
};


  return (
    <button onClick={handleClick} 
      className={`heartButton ${className}`}
      disabled={!canFavApps}
      {...(!canFavApps ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}>
      <HeartSVG style={{ fill: favorite ? 'black' : 'none' }}/>
    </button>
  );
};

export default HeartButton;
