import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import TopBar from "../../components/navBar/TopBar";
import './lookerDash.scss';


const LookerStudioDashboard = () => {
  const userId = useSelector((state) => state.session.userInfo.userId);
  const [embedUrl, setEmbedUrl] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userId) {
      const encodedParams = encodeURIComponent(JSON.stringify({ "dso.userid": userId }));
      const url = `${process.env.REACT_APP_LOOKER_DASH_URL}?params=${encodedParams}`;
      setEmbedUrl(url);
    }
  }, [userId]);

  if (!embedUrl) {
    return <div>Loading...</div>;
  }

  const isMobile = windowWidth <= 768;

  return (
    <section className="lookerDash">
      {!isMobile && <TopBar hasNavegation />}
      <div className="lookerDash-content">
        <iframe
          src={embedUrl}
          className="lookerDash-content fullscreen-iframe"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

export default LookerStudioDashboard;
