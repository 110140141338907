import React from 'react';
import './decorationBanner.scss';

const DecorationBanner = ({ text }) => {
    const textCopies = new Array(50).fill(text);
    return (
        <div className="banner-loop">
        <div className="banner-loop-content">
            {textCopies.map((item, index) => (
            <span key={index}>{item} – </span>
            ))}
        </div>
        </div>
    );
}
export default DecorationBanner;