const authTexts = {
    es: {
        authLogin: 'Ingresa en tu cuenta de Flipando',
        authRegister: 'Comencemos',
        authEmail: 'Correo electrónico',
        authEmailPlaceholder: 'Ingresa tu correo electrónico',
        authPassword: 'Contraseña',
        authPasswordPlaceholder: 'Ingresa tu contraseña',
        authConfirmPassword: 'Confirmar contraseña',
        authConfirmPasswordPlaceholder: 'Confirma tu contraseña',
        authName: 'Nombre',
        authNamePlaceholder: 'Ingresa tu nombre',
        authPhoneNumber: 'Número de teléfono',
        authPhoneNumberPlaceholder: 'Ingresa tu número de teléfono',
        authOptional: '(Opcional para comunicaciones por WhatsApp)',
        authLoginButton: 'Ingresa',
        authRegisterButton: 'Registrarse',
        authForgotPassword: '¿Olvidaste tu contraseña?',
        authNewPassword: 'Nueva contraseña',
        authNewPasswordButton: 'Cambiar contraseña',
        authNewPasswordDescription: 'Ingresa tu nueva contraseña',
        authNewPasswordConfirmation: 'Confirma tu nueva contraseña',
        authGoogle: 'Continuar con Google',
        authGoogleRegister: 'Registrarse con Google',
        authNoAccount: '¿No tienes una cuenta?',
        authCreateOne: 'Crea una',
        authConfirmEmail: 'Confirma tu correo electrónico',
        authCheckEmail: 'Revisa tu correo electrónico para tu confirmación',
        authTermsAndConditions1: 'Acepto los ',
        authTermsAndConditions2: 'Términos y Condiciones',
        authTermsAndConditions3: ' y la ',
        authTermsAndConditions4: 'Política de Privacidad',
        authHaveAccount: '¿Ya tienes una cuenta?',
        authCompleteRecaptcha: 'Por favor completa el reCAPTCHA.',
    },
    en: {
        authLogin: 'Log in to your Flipando account',
        authRegister: 'Let\'s get started',
        authEmail: 'Email Address',
        authEmailPlaceholder: 'Enter your email address',
        authPassword: 'Password',
        authPasswordPlaceholder: 'Enter your password',
        authConfirmPassword: 'Confirm Password',
        authConfirmPasswordPlaceholder: 'Confirm your password',
        authName: 'Name',
        authNamePlaceholder: 'Enter your name',
        authPhoneNumber: 'Phone number',
        authPhoneNumberPlaceholder: 'Enter your phone number',
        authOptional: '(Optional for WhatsApp communications)',
        authLoginButton: 'Log in',
        authRegisterButton: 'Sign up',
        authForgotPassword: 'Forgot password?',
        authNewPassword: 'New password',
        authNewPasswordButton: 'Change password',
        authNewPasswordDescription: 'Enter your new password',
        authNewPasswordConfirmation: 'Confirm your new password',
        authGoogle: 'Continue with Google',
        authGoogleRegister: 'Sign up with Google',
        authNoAccount: 'Don\'t have an account?',
        authCreateOne: 'Create one',
        authConfirmEmail: 'Confirm your email',
        authCheckEmail: 'Check your email for your confirmation',
        authTermsAndConditions1: 'I agree to the ',
        authTermsAndConditions2: 'Terms and Conditions',
        authTermsAndConditions3: ' and ',
        authTermsAndConditions4: 'Privacy Policy',
        authHaveAccount: 'Do you already have an account?',
        authCompleteRecaptcha: 'Please complete the reCAPTCHA.',
    },
};

export default authTexts;
