import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../../assets/cancel-icon.svg';
import { sendUserInformation } from '../../../api/user';

const PostRegisterStepThree = ({
  name,
  email,
  role,
  country,
  teamSize,
  teamDepartment,
  companyName,
  companyIndustry,
  setIsModalOpen,
  closeModal
}) => {
  const options = [
    "Learn about AI and Flipando",
    "Build AI Apps and Tools for My Team",
    "Provide a Centralized AI Platform to Share, Store, and Version AI Solution",
    "Specific AI Use Case:"
  ];

  const [selectedOption, setSelectedOption] = useState("");
  const [customCaseText, setCustomCaseText] = useState("");

  const token = useSelector((state) => state.session.token);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleCancelButton = () => {
    setIsModalOpen(false);
    closeModal();
  };
    const sendRegisterEmail = (e) => {
        e.preventDefault();
        sendUserInformation(name, email, role, country, teamSize, teamDepartment, companyName, companyIndustry, selectedOption, customCaseText, token)
        .then(() => {
            alert('Email sent successfully!');
            handleCancelButton();
        })
        .catch(error => {
            console.error('Something went wrong when sending email. ' + error.message);
        });
  };

  return (
    <>
        <div className="slider-indicator">
            <div className="indicator active"></div>
            <div className="indicator active"></div>
            <div className="indicator active"></div>
        </div>
        <header className="register-post-modal-header">
            <h1>How Can We Help You?</h1>
            <button className="header-cancel" onClick={handleCancelButton}>
                <CancelIcon/>
            </button>
        </header>
        <div className="register-post-modal-body">
        {options.map((option, index) => (
            <div key={index} className="field">
                <label className="radio">
                    <input
                    type="radio"
                    name="selectedOption"
                    value={option}
                    checked={selectedOption === option}
                    onChange={handleOptionChange}
                    />
                    {option}
                </label>
            </div>
        ))}
        {selectedOption === "Specific AI Use Case:" && (

        <div className="field register-modal-field">
            <input type="text" className="input" placeholder="Please Describe" onChange={(event) => setCustomCaseText(event.target.value)} />
        </div>
        )}
        <button className="button register-post-modal-continue-button" onClick={sendRegisterEmail}>
            Submit
        </button>
        </div>
    </>
  );
};

export default PostRegisterStepThree;
