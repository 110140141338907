import React, { useState, useEffect, useRef } from 'react';
import './voiceInput.scss';
import { ReactComponent as RecordIcon } from '../../assets/record-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/newDesign/trash-icon.svg';
import { ReactComponent as SendIcon } from '../../assets/newDesign/send-icon.svg';
import { ReactComponent as PlayIcon } from '../../assets/play-icon.svg';
import { ReactComponent as ContinueRecordingIcon } from '../../assets/newDesign/recording-icon.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause-icon.svg';
import { ReactComponent as PauseRecordingIcon } from '../../assets/newDesign/pause-icon.svg';
import { ReactComponent as RecorderSvg } from '../../assets/newDesign/recorded-image.svg';
import loadingAnimation from '../../assets/voice-gif.gif';
import recordedImg from '../../assets/recordedImg.png';
import { useAudioRecorder } from 'react-audio-voice-recorder';

function VoiceInput({ setAudio, setTextAreaContent }) {
    const [state, setState] = useState(0);
    const [audioSrc, setAudioSrc] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [audioBlobs, setAudioBlobs] = useState([]);

    const audioRef = useRef(null);
    const { 
        startRecording, 
        stopRecording, 
        togglePauseResume,
        recordingBlob,
        isPaused,
        error 
    } = useAudioRecorder();

    useEffect(() => {
        if (error) {
            setErrorMessage('Error al grabar el audio. Por favor, intenta de nuevo.');
            setState(0);
        } else if (recordingBlob) {
            if (recordingBlob.size === 0) {
                setErrorMessage('La grabación del audio falló o fue demasiado corta. Por favor, intenta de nuevo.');
                setState(0);
            } else {
                const url = URL.createObjectURL(recordingBlob);
                setAudioSrc(url);
                setAudio(recordingBlob);
                setErrorMessage('');

                if (!audioRef.current) {
                    audioRef.current = new Audio(url);
                } else {
                    audioRef.current.src = url;
                }
            }
        }
    }, [recordingBlob, setAudio, error]);

    const startVoiceRecording = () => {
        startRecording();
        setState(1);
        setErrorMessage('');
    };

    const cancelVoiceRecording = () => {
        stopRecording();
        if (audioSrc) {
            URL.revokeObjectURL(audioSrc);
            setAudioSrc(null);
        }
        setState(0);
        setErrorMessage('');
    };
      
    const saveVoiceRecording = (e) => {
        e.preventDefault();
        stopRecording();
        const combinedBlob = new Blob(audioBlobs, { 'type' : 'audio/wav' });
        setAudio(combinedBlob);
        setState(2);
    };

    const togglePlayPause = () => {
        if (!audioSrc) return;

        if (!isPlaying) {
            audioRef.current.play().catch(e => {
                setErrorMessage('Error al reproducir el audio. Por favor, revisa el archivo e intenta de nuevo.');
            });
        } else {
            audioRef.current.pause();
        }
        setIsPlaying(!isPlaying);
    };
    const handleTogglePauseResume = () => {
        togglePauseResume();
    };

    useEffect(() => {
        if (recordingBlob) {
            setAudioBlobs([...audioBlobs, recordingBlob]);
        }
    }, [recordingBlob]);
 
    
    return (
        <div className="voice-input">
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {state === 0 && (
                <>
                    <textarea onChange={(event) => setTextAreaContent(0, event)} />
                    <button onClick={startVoiceRecording}>
                        <i className='pink-icon'><RecordIcon /></i>
                    </button>
                </>
            )}
            {state === 1 && (
                <div className="recording">
                    <div className='recording-animation'>
                        {isPaused ?    
                        <div className='voice-input-final-play'>
                            <button type='button' className='voice-input-final-pause-button'  onClick={togglePlayPause}>
                                {isPlaying ? <PauseIcon /> : <PlayIcon />}
                            </button>
                            {audioSrc && <audio src={audioSrc} controls preload="metadata" />}
                            <RecorderSvg />
                        </div>
                        
                        :<img src={loadingAnimation} alt="loading animation" />}            
                    </div>
                    <div className='recording-buttons'>            
                        <button className='recording-cancel' onClick={cancelVoiceRecording}><i className='pink-icon'><DeleteIcon /></i></button>
                        <button type='button' className='recording-pause-button' onClick={handleTogglePauseResume}>
                            {isPaused ? <ContinueRecordingIcon /> : <PauseRecordingIcon />}
                        </button>
                        <button onClick={saveVoiceRecording}><i className='pink-icon'><SendIcon /></i></button>
                    </div>
                </div>
            )}
            {state === 2 && (
                <div className="final">
                    <div className='voice-input-final-play'>
                        <button type='button' className='voice-input-final-pause-button'  onClick={togglePlayPause}>
                            {isPlaying ? <i className='recording-pause-icon'><PauseIcon /></i> : <i className='recording-play-icon'><PlayIcon /></i>}
                        </button>
                        {audioSrc && <audio src={audioSrc} controls preload="metadata" />}
                        <div className='voice-input-final-play-image'><RecorderSvg /></div>
                    </div> 
                </div>
            )}
        </div>
    );
}

export default VoiceInput;
