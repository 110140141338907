import moment from "moment";
import React from 'react';

import { ReactComponent as TrashIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/i.svg';
import { ReactComponent as PlusIcon } from '../../../assets/plus-workflow-icon.svg';
import { ReactComponent as StarsIcon } from '../../../assets/stars-white-icon.svg';
import { useLanguage } from "../../../hooks/languageHook/LanguageHook";
import languageTexts from "../../../languajeConstant";


function CardWorkflow({
    workflowStep,
    firstSelected,
    app,
    inWorkflow,
    canDelete,
    handleDeleteApp,
    setHasStartApp,
    selectedApps,
    setSelectedApps,
    fileInputRefs,
    uploadedFiles,
    setUploadedFiles,
    setError
}
) {
    const { language } = useLanguage();

    const handleFileUpload = (event, inputName) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFiles(prevFiles => ({
                ...prevFiles,
                [`${workflowStep}-${inputName}`]: file
            }));
        }
    };

    const handleRemoveFile = (inputName) => {
        setUploadedFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            delete updatedFiles[`${workflowStep}-${inputName}`];
            return updatedFiles;
        });
        if (fileInputRefs.current[`${workflowStep}-${inputName}`]) {
            fileInputRefs.current[`${workflowStep}-${inputName}`].value = '';
        }
    }

    const handleAddApp = () => {
        if (firstSelected) {
            setHasStartApp(true);
        }

        const step_inputs = {};
        app.inputs.forEach(input => {
            step_inputs[input.name] = { type: 'user_input', value: input.value };
        });

        if (app.has_docs) {
            step_inputs['FileName'] = { type: 'doc_input', value: "" };
        }

        setSelectedApps(prevSteps => [
            ...prevSteps,
            { ...app, step_inputs: step_inputs }
        ]);
    };


    const handleInputChange = (name, value) => {
        const updatedSelectedApps = [...selectedApps];
        const updatedStep = { ...updatedSelectedApps[workflowStep] };
        updatedStep.step_inputs[name] = { type: 'user_input', value: value };
        updatedSelectedApps[workflowStep] = updatedStep;
        setSelectedApps(updatedSelectedApps);
        setError("");
    };


    const handleSelectChange = (name, value) => {
        const updatedSelectedApps = [...selectedApps];
        const updatedStep = { ...updatedSelectedApps[workflowStep] };
        if (value === "user_input") {
            updatedStep.step_inputs[name] = { type: value, value: "" };
        }
        else {
            updatedStep.step_inputs[name] = { type: 'step_output', value: value };
        }
        updatedSelectedApps[workflowStep] = updatedStep;
        setSelectedApps(updatedSelectedApps);
        setError("");
    }

    return (
        <section className={`new-workflow-card ${canDelete ? 'is-active' : ''}`}>
            <div className='new-workflow-card-left'>
                <h2>{app.name}</h2>
                <p className='app-by'>{languageTexts[language].createWorkflowCardBy} {app.owner}</p>
                <p className='app-information'>Run {app.interactions} times. Created {moment(app.creation_date).fromNow()}</p>
                <div className='new-workflow-card-left-buttons'>
                    <span className='show-info' data-tooltip={app.description}><InfoIcon /></span>
                    <span className='show-prompt' data-tooltip={app.logic}><StarsIcon />{languageTexts[language].createWorkflowCardShowPrompt}</span>
                    {!inWorkflow && <button type='button' className='button add-app' onClick={() => handleAddApp()}><PlusIcon /></button>}
                    {canDelete && <button type='button' className='button delete-app' onClick={() => handleDeleteApp(workflowStep)}><TrashIcon /></button>}
                </div>
            </div>
            <div className='new-workflow-card-right'>
                {app.has_docs && (
                    <div className="field">
                        <label className="label">{languageTexts[language].createWorkflowCardFileName}</label>
                        <div>
                            <input
                                type='file'
                                id={`${workflowStep}-FileName`}
                                name='file'
                                accept='.pdf, .docx, .csv, .xlsx, .xls, .txt'
                                onChange={(e) => handleFileUpload(e, 'FileName')}
                                ref={el => fileInputRefs.current[`${workflowStep}-FileName`] = el}
                                style={{ display: 'none' }}
                                disabled={!inWorkflow}
                            />
                            {uploadedFiles[`${workflowStep}-FileName`] ?
                                <div className='new-workflow-card-right-file-uploaded'>
                                    <p>{languageTexts[language].createWorkflowCardFileUploaded} {uploadedFiles[`${workflowStep}-FileName`].name} <button type="button" className='button useApp-form-remove-file' onClick={handleRemoveFile}>X</button></p>
                                </div> :
                                <label
                                    htmlFor={`${workflowStep}-FileName`}
                                    className={`button new-workflow-card-right-upload-button ${!inWorkflow ? 'disabled' : ''}`}
                                >
                                    {languageTexts[language].createWorkflowCardUploadFile}
                                </label>
                            }
                        </div>
                    </div>
                )}
                {workflowStep === 0 || !inWorkflow ?
                    app.inputs.map((inputObj, index) => (
                        <div key={index} className='field'>
                            <label className='label'>{inputObj.name}</label>
                            <input
                                type="text"
                                name={inputObj.name}
                                placeholder={inputObj.value}
                                onChange={(e) => handleInputChange(inputObj.name, e.target.value)}
                            />
                        </div>
                    ))
                    : app.inputs.map((inputObj, index) => (
                        <div key={index} className='field'>
                            <label className='label'>{inputObj.name}</label>
                            <select
                                name={inputObj.name}
                                onChange={(e) => handleSelectChange(inputObj.name, e.target.value)}
                                value={selectedApps[workflowStep].step_inputs[inputObj.name].value}
                            >
                                <option value="user_input">{languageTexts[language].createWorkflowCardUserInput}</option>
                                {selectedApps.map((step, stepIndex) => {
                                    if (stepIndex !== workflowStep) {
                                        return (
                                            <option key={stepIndex} value={stepIndex + 1}>
                                                {languageTexts[language].createWorkflowCardOutputApp} {stepIndex + 1} - {step.name}
                                            </option>
                                        );
                                    }
                                    return null;
                                })}
                            </select>
                            {selectedApps[workflowStep].step_inputs[inputObj.name].type === "user_input" && (
                                <input
                                    type="text"
                                    name={inputObj.name}
                                    value={selectedApps[workflowStep].step_inputs[inputObj.name].value}
                                    onChange={(e) => handleInputChange(inputObj.name, e.target.value)}
                                    placeholder={inputObj.placeholder}
                                />
                            )}
                        </div>
                    ))}
            </div>
        </section>
    );
}

export default CardWorkflow;