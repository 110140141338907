const workspace = {
    es: {
        workspaceTitle: 'Mi Espacio de Trabajo',
        workspaceSearchIn: 'Buscar en el espacio de ',
        workspaceSpace: '',
        workspaceRecentlyModified: 'Recientemente modificados',
        workspaceMyWork: 'Mi trabajo',
        workspaceMyApps: 'Mis aplicaciones',
        workspacePrivate: 'Privado',
        workspaceTeam: 'Equipo',
        workspaceFavorite: 'Favoritas',
    },
    en: {
        workspaceTitle: 'My Workspace',
        workspaceSearchIn: 'Search in ',
        workspaceSpace: ' workspace',
        workspaceRecentlyModified: 'Recently modified',
        workspaceMyWork: 'My work',
        workspaceMyApps: 'My apps',
        workspacePrivate: 'Private',
        workspaceTeam: 'Team',
        workspaceFavorite: 'Favorites',
    },
};

export default workspace;
