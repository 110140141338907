import axios from 'axios';

export function checkForResult(taskId, setLoading, setResult, setError, setTextToCopy, requestFrequency) {
  let attempts = 0;
  let resultRetrieved = false; // Declare and initialize the variable here

  const maxAttempts = 150000 / requestFrequency; // 2 minutes maximum

  const sendRequest = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/tasks/${taskId}`)
      .then(response => {
        const { data, status } = response;
        if (status === 202) {
          console.log('Task is still processing');
          // No action needed, continue polling
        } else if (status === 200 && data) {
          clearInterval(interval);
          clearTimeout(timeout);
          setLoading(false);
          setResult(data);
          setTextToCopy(data); // Assuming you want to copy the entire data object
          resultRetrieved = true; // Update the flag when the result is successfully retrieved
        }
      })
      .catch(error => {
        clearInterval(interval);
        clearTimeout(timeout);
        setLoading(false);
        const errorMessage = error.response?.data?.message || "Unexpected error occurred";
        setError(errorMessage);
      });

    attempts++;
    if (attempts > maxAttempts) {
      clearInterval(interval);
      clearTimeout(timeout);
      setLoading(false);
      if (!resultRetrieved) {
        setError("Timeout. Unable to get result.");
      }
    }
  };

  sendRequest();

  // Setup polling
  const interval = setInterval(sendRequest, requestFrequency);

  // Setup timeout
  const timeout = setTimeout(() => {
    clearInterval(interval);
    if (!resultRetrieved) {
      setLoading(false);
      setError("Timeout. Unable to get result.");
    }
  }, maxAttempts * requestFrequency);

  // Function to clear the interval and timeout
  const clearTimer = () => {
    clearTimeout(timeout);
    clearInterval(interval);
  };

  return clearTimer;
}
