import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link, NavLink, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import "./navBar.scss";

import { logout } from "../../redux/actions/authActions";
import { ReactComponent as Logo } from '../../assets/newDesign/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/newDesign/home.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logout-icon.svg';
import { ReactComponent as TeamsIcon } from '../../assets/newDesign/team-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/newDesign/user-icon.svg';
import { ReactComponent as LearnIcon } from '../../assets/newDesign/learning-icon.svg';
import { ReactComponent as SubscriptionsIcon } from '../../assets/newDesign/card-icon.svg';
import { ReactComponent as DashboardIcon } from '../../assets/newDesign/dashboard-icon.svg';
import FlipperIcon  from '../../assets/newDesign/flipperdesktop-icon.png';
//import { ReactComponent as WorkflowIcon } from '../../assets/workflow-icon.svg';
import { getTeamById } from "../../api/teams";
import UserAvatar from "../userAvatar/UserAvatar";
import profileImg from '../../assets/profile-user.svg';

import languageTexts from '../../languajeConstant';
import { SUPERUSER, TEAMADMIN, PROUSER, USER, TEAMMEMBER } from '../../constants';
import NavBarMobileTop from "./navBarMobile/NavBarMobileTop";
import NavBarMobileBottom from "./navBarMobile/NavBarMobileBottom";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";
import NavBarSubMenu from "./NavBarSubMenu";
import FlipperModal from "../flipperModal/FlipperModal";

const NavBarInternal = () => {
  const { language } = useLanguage();
    const [team, setTeam] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    const [showCreditMessage, setShowCreditMessage] = useState(false);
    const [showFlipperModal, setShowFlipperModal] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.session.userInfo);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowWidth <= 768;
    const shortName = user?.userName.split(' ')[0] + ' ' + user?.userName?.split(' ')[1]?.charAt(0) + '.';

    const isActive = useMatch({
      path: '/',
      exact: true
    });

    const isAppGenerator = useMatch({
      path: '/explore/4',
      exact: true
    });

    const isTemplates = useMatch({
      path: '/templates',
      exact: true
    });

    const isMyApps = useMatch({
      path: '/workspace',
      exact: true
    });

    const handleLogout = () => {
        dispatch(logout());
        localStorage.clear();
    }

    const handleImageError = (e) => {
      e.target.src = profileImg;
    }
    const handleToggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
    const handleCloseeMenu = () => {
        setMenuOpen(false);
  } ;

    useEffect(() => {
      const fetchTeam = async () => {
          try {
              const teamResponse = await getTeamById(user.team);
              setTeam(teamResponse);
          } catch (error) {
              console.error(error);
          }
      };
      if(user.team) {
        fetchTeam();
      }
    }, [user.team]);

    useEffect(() => {
      if ((user.role === USER || (user.role === USER  && user.credits < 10))) {
        setShowCreditMessage(true);
      }
    }, [user.role, user.credits]);
    return(
      <>
      {isMobile && <NavBarMobileBottom />}
      <aside className={`menu menu-internal ${menuOpen ? "open" : ""}`} >
        <section className="mobile-menu-internal">
        <div className="mobile-menu-internal-left">
          <div className={`menu-toggle ${menuOpen ? "open" : ""}`} onClick={handleToggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
          </div>
          <HomeIcon className="mobile-menu-internal-left-home" />
          {isMobile && (
            <Link className="logo logo-mobile" to="/" onClick={handleCloseeMenu}>
              <Logo />
            </Link>
          )}
        </div>

        {isMobile &&
          <Link className="mobile-menu-internal-right-user" to="/settings" onClick={handleCloseeMenu}>
          {user.profileImage ? 
            <img src={user.profileImage} className="menu-internal-sup-user-img" onError={handleImageError} alt="profile" />
            :
            <UserAvatar name={user.userName ? shortName :"default"} />
          }
          <p className="mobile-menu-internal-right-user-description">
            {shortName}
            {team &&
              <span className="menu-internal-sup-user-team">{team?.name}</span>
            }
            {user.role === PROUSER &&
              <span className="menu-internal-sup-user-team">{languageTexts[language].navbarTitlePro}</span>
            }
            {user.role === USER &&
              <span className="menu-internal-sup-user-credits">{languageTexts[language].navbarTitleFree}</span>
            }
          </p>
        </Link>
        }
        <div className={`menu-internal-content ${menuOpen ? "open" : ""}`}>
          <div className="menu-internal-sup">
          {!isMobile && (
            <NavLink className="navbar-item logo logo-desktop" to="/">
              <Logo />
            </NavLink>
          )}
            <ul className="menu-internal-content-fristlevel">
              {!isMobile ? (
                <>
                <li>
                  <NavBarSubMenu team={team} />
                </li>
                <li>
                  <button className="menu-internal-content-flipper" onClick={() => (setShowFlipperModal(!showFlipperModal))}>
                    <img src={FlipperIcon} alt="flipper"/>
                    {languageTexts[language].navbarWpWithFlipper}
                  </button>
                </li>
                <li>
                  <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/dashboard" >
                    <DashboardIcon className='menuicon'/>
                    {languageTexts[language].navbarDashboard}
                  </NavLink>
                </li>
                <li>
                  <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/learningresources">
                    <LearnIcon className='menuicon'/>
                    {languageTexts[language].navbarLearnings}
                  </NavLink>
                </li>
                </>
              ) :
              (
              <>
                <li>
                <NavLink className={`navbar-item team-section ${isActive ? 'active' : ''}`} to="/explore" onClick={handleCloseeMenu}>
                  <HomeIcon />
                  {languageTexts[language].navbarHome}
                </NavLink>
                </li>
                <li>
                  <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/settings" onClick={handleCloseeMenu}>
                    <SettingsIcon />
                    {languageTexts[language].navbarWorkspace}
                  </NavLink>
                </li>
                {team && isMobile && (user.role === SUPERUSER || user.role === TEAMADMIN || user.role === TEAMMEMBER) &&
                  <li>
                    <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/teamsettings" onClick={handleCloseeMenu}>
                      <TeamsIcon />
                      {languageTexts[language].navbarTeamSettings}
                    </NavLink>
                  </li>
                  }
                  <li>
                    <NavLink className={`navbar-item menu-internal-subscription ${isActive ? 'active' : ''}`} to="/subscriptions" onClick={handleCloseeMenu}>
                      <SubscriptionsIcon/>
                      {languageTexts[language].navbarPricing}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className={`navbar-item ${isActive ? 'active' : ''}`} to="/learningresources" onClick={handleCloseeMenu}>
                      <LearnIcon/>
                      {languageTexts[language].navbarLearnings}
                    </NavLink>
                  </li>
                  <li>
                    <LanguageSwitch />
                  </li>
              </>
              )
              }
            </ul>
          </div>
          <div> 
            {isMobile ?
              <button className="button menu-internal-logout" onClick={handleLogout}>
                <LogoutIcon />
                {languageTexts[language].navbarLogout}
              </button>
            :
            <>
            <NavLink className={`navbar-item menu-internal-subscription ${isActive ? 'active' : ''}`} to="/subscriptions">
              <SubscriptionsIcon className='menuicon'/>
              {languageTexts[language].navbarPricing}
            </NavLink>
            <button className="button menu-internal-logout" onClick={handleLogout}>
              <LogoutIcon className='menuicon'/>
              {languageTexts[language].navbarLogout}
            </button>
            </>
            }
          </div>
        </div>
        </section>
      </aside>
      {isMobile && !isAppGenerator && !isTemplates && !isMyApps && <NavBarMobileTop />}
      {showFlipperModal && ReactDOM.createPortal(
        <FlipperModal setFlipperModal={setShowFlipperModal} />,
        document.getElementById('modal-root')
      )}
      </>
    )
}

export default NavBarInternal;
