import axios from "axios";


export async function getCategoryById(id, token) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": "Bearer " + token,
  
      }
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories/${id}`, requestOptions);
      return response.data.results;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  export async function getCategories(token) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": "Bearer " + token,
  
      }
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories/`, requestOptions);
      return response.data.results;
    } catch (error) {
      throw new Error(error.message);
    }
  };