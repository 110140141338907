const WorkflowTexts = { 
    es: {
        myWorkflowsTitle: 'Mis Flujos de Trabajo',
        myWorkflowsDescription: 'Los flujos de trabajo son una nueva funcionalidad emocionante en flipando.ai que te permite automatizar procesos complejos combinando múltiples aplicaciones. Con los flujos de trabajo, puedes crear una secuencia de aplicaciones que están encadenadas, donde la salida de una aplicación sirve como entrada para la siguiente.',
        useWorkflowDeleteConfirmation: '¿Estás seguro de que quieres eliminar esta flujo de trabajo? Todos los datos se perderán.',
        newWorkflowButton: 'Crear Nuevo flujo de trabajo',
        workflowName: 'Nombre',
        workflowCreatedBy: 'Creado por',
        workflowLastUsed: 'Último uso',
        useWorkflowBy: 'Por:',
        useWorkflowCreated: 'Creado',
        useWorkflowClearButton: 'Borrar',
        useWorkflowSubmitButton: 'Enviar',
        useWorkflowResults: 'Resultado del Flujo de Trabajo',
        useWorkflowShowIntermediateResults: 'Mostrar Resultados Intermedios',
        useWorkflowHideIntermediateResults: 'Ocultar Resultados Intermedios',
        deleteWorkflow: 'Eliminar Flujo de Trabajo',
        editWorkflow: 'Editar Flujo de Trabajo',
        useWorkflowCardOutputApp: "Output de la app",
        useWorkflowCardFileUploaded: "Archivo subido: ",
        useWorkflowCardUploadFile: "Subir archivo",
    },
    en: {
        myWorkflowsTitle: 'My workflows',
        myWorkflowsDescription: 'Workflows are an exciting new feature on flipando.ai that allows you to automate complex processes by combining multiple apps together. With workflows, you can create a sequence of apps that are chained together, where the output of one app serves as the input to the next.',
        useWorkflowDeleteConfirmation: 'Are you sure you want to delete this workflow? All data will be lost.',
        newWorkflowButton: 'Create New Workflow',
        workflowName: 'Name',
        workflowCreatedBy: 'Created by',
        workflowLastUsed: 'Last Used',
        useWorkflowBy: 'By:',
        useWorkflowCreated: 'Created',
        useWorkflowClearButton: 'Clear',
        useWorkflowSubmitButton: 'Submit',
        useWorkflowResults: 'Workflow Result',
        useWorkflowShowIntermediateResults: 'Show Intermediate Results',
        useWorkflowHideIntermediateResults: 'Hide Intermediate Results',
        deleteWorkflow: 'Delete Workflow',
        editWorkflow: 'Edit Workflow',
        useWorkflowCardOutputApp: "Output App",
        useWorkflowCardFileUploaded: "File Uploaded: ",
        useWorkflowCardUploadFile: "Upload File",
    }
}

export default WorkflowTexts;