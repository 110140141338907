import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import ErrorMessage from "../../../components/errorMessage/ErrorMessage";
import { createUserAction } from '../../../redux/actions/authActions';
import googleIcon from '../../../assets/google-icon.svg';


const SignupWithGoogle = ({ token, teamId, openInformationModal, label}) => {
  const [profile, setProfile] = useState(null);
  const errorMessage = useSelector(state => state.session.errorMessage);
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async respose => {
      try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            "Authorization": `Bearer ${respose.access_token}`
          }
        })
        const profile = {
          email: res.data.email,
          name: res.data.name,
        };

        setProfile(profile);

      } catch (err) {
        console.log(err)

      }

    },
    onFailure: async response => {
      console.error(response)
    }
  });

  const registerUser = async () => {
    const formData = {
      email: profile.email,
      username: profile.name,
      role_id: teamId ? 4 : 2,
      active_role: teamId ? 4 : 2,
      token: token,
      team_id: teamId
    };

    try {
      await dispatch(createUserAction(formData));  
      openInformationModal()
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  useEffect(() => {
    if (profile) {
      registerUser();
    }
  }, [profile]);


  return (
    <div>
      <button
          className="button is-primary register-modal-google-submit"
          onClick={login}
        >
          <img src={googleIcon} alt="Google Icon" className="google-logo" />
          {label}
        </button><ErrorMessage message={errorMessage} />
    </div>
  );
};

export default SignupWithGoogle;
