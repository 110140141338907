import React, { useState } from "react";
import PhoneInput from 'react-phone-number-input';
import QRCode from 'react-qr-code';
import { useSelector } from "react-redux";
import { editUser } from "../../api/user";
import languageTexts from '../../languajeConstant';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';

import './flipperModal.scss';

const FlipperModal = ({ setFlipperModal }) => {
    const { language } = useLanguage();
    const [newPhoneNumber, setNewPhoneNumber] = useState(null);
    
    const token = useSelector((state) => state.session.token);
    const user = useSelector((state) => state.session.userInfo);
    const [phoneNumberSaved, setPhoneNumberSaved] = useState(!!user.phoneNumber);



    const handleCloseFlipperModal = () => {
        setFlipperModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
          phone_number: newPhoneNumber
        }
    
        const editUserForm = Object.entries({ ...data, newPhoneNumber})
        .reduce((acc, [key, value]) => {
          if (value !== '') {
            acc[key] = value;
          }
          return acc;
        }, {});
    
        if (Object.keys(editUserForm).length !== 0) {
          try {
            await editUser(editUserForm, token);
            setPhoneNumberSaved(true);
            
            window.location.reload()
          } catch (error) {
            console.error(error.response.data.message);
          }
        }
      };
    return(
        <>
        <div className="modal-background"></div>
        <section className={`flipper-modal ${phoneNumberSaved ? 'wide-modal' : ''}`}>
          <h1>{user.phoneNumber ? languageTexts[language].flipperModalWhatsApp : languageTexts[language].flipperModalAddPhoneNumber} </h1>
          <button onClick={handleCloseFlipperModal} className="modal-close is-large">{languageTexts[language].flipperModalCancelButton}</button>
          {!phoneNumberSaved ?
            <>
            <div className="flipper-modal-content">
                <p>{languageTexts[language].flipperModalContent1}</p>
                <p>{languageTexts[language].flipperModalContent2}</p>
            </div>
            <h2>{languageTexts[language].flipperModalPhoneNumber}</h2>
            <form className="flipper-modal-form">
                <PhoneInput
                    placeholder={languageTexts[language].flipperModalPhoneNumberPlaceholder}
                    value={newPhoneNumber}
                    onChange={(value) => setNewPhoneNumber(value)} />
                <div className="flipper-modal-form-buttons">
                    <button type="button" className="button flipper-modal-cancel" onClick={handleCloseFlipperModal}>{languageTexts[language].flipperModalDiscardButton}</button>
                    <button type="button" className="button flipper-modal-submit" onClick={handleSubmit}>{languageTexts[language].flipperModalSubmitButton}</button>
                </div>
            </form>
            </>
          :
          <div className="flipper-modal-content">
            <div className="flipper-modal-content-left">
              <h3>{languageTexts[language].flipperModalSteps}</h3>
              <ol>
                <li>{languageTexts[language].flipperModalStep1}</li>
                <li>{languageTexts[language].flipperModalStep2}</li>
                <li>{languageTexts[language].flipperModalStep3}</li>
              </ol>
              <p>{languageTexts[language].flipperModalFinalStep}</p>
            </div>
            <div className="flipper-modal-content-right">
              <QRCode 
              size={192}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value="wa.link/vzde0f"
              viewBox={`0 0 192 192`} />
            </div>
          </div>
          }
        </section>
        </>
    );
};

export default FlipperModal;