import React, { useState, useEffect, useRef } from "react";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useLanguage } from '../../../hooks/languageHook/LanguageHook';
import ReCAPTCHA from "react-google-recaptcha";
import './register.scss';

import ErrorMessage from "../../../components/errorMessage/ErrorMessage";
import eyeIcon from '../../../assets/eye-password-hide-icon.svg';
import { sendUserSignupConfirmation } from "../../../api/session";
import { createUserAction } from "../../../redux/actions/authActions";
import DecorationBanner from "../../../components/decorationBanner/DecorationBanner";
import GoogleRegister from "../../../pages/authenticate/register/GoogleRegister";
import languageTexts from '../../../languajeConstant';


const CredentialsRegister = ({openInformationModal}) => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [termsAgree, setTermsAgree] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [checkYourEmail, setCheckYourEmail] = useState(false);
    const [recaptchaStyle, setRecaptchaStyle] = useState({});
    const [recaptchaValue, setRecaptchaValue] = useState(null);


    const recaptchaRef = useRef(null);
    const { language } = useLanguage();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const teamId = params.get('team_id');
    const isProduction = process.env.NODE_ENV === 'IS_PRODUCTION';

    const dispatch = useDispatch();

    useEffect(() => {
      setError(!termsAgree);
    }, [termsAgree]);
    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
      const recaptchaWidth = document.querySelector('.g-recaptcha')?.parentNode?.offsetWidth || 0;
      if (recaptchaWidth < 302 && recaptchaWidth > 0) {
          const scale = recaptchaWidth / 302;
          setRecaptchaStyle({
              transform: `scale(${scale})`,
              transformOrigin: '0 0',
          });
      } else {
          setRecaptchaStyle({});
      }
  }, [windowWidth]);

    const handleSubmit = async event => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      event.preventDefault();
      if (isProduction && !recaptchaValue) {
          setErrorMessage(languageTexts[language].authCompleteRecaptcha);
          return;
      }
      if (password === confirmationPassword && password.length > 5 && regex.test(email)) {
        try {
          if (teamId && token) {
            const formData = {
              email: email,
              username: username,
              hashed_password: password,
              role_id: 4,
              active_role: 4,
              token: token,
              team_id: teamId,
              phone: phone
            };

            await dispatch(createUserAction(formData));
            openInformationModal()
          } else {
            const response = await sendUserSignupConfirmation(email, username, password, phone);
            setCheckYourEmail(true);
          }
        }
        catch (error) {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage("Ensure that the passwords match and are greater than 5 characters")
      }
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmationPassword = () => {
        setShowConfirmationPassword(!showConfirmationPassword);
    };

    return(
        <section className="login">
          <div className="banner-top">
            <DecorationBanner text={'Learn Build Share Showcase'} />
          </div>
            <div className="login-content">
              {checkYourEmail 
              ? 
              <>
                <h1 className="login-content-title">{languageTexts[language].authConfirmEmail}</h1>
                <p>{languageTexts[language].authCheckEmail}</p>
              </>
              :
              <>
              <h1 className="login-content-title register-title">{languageTexts[language].authRegister}</h1>
              <form className="login-content-form" onSubmit={handleSubmit}>
                <div className="field login-content-form-field">
                  <label className="label">{languageTexts[language].authName}</label>
                  <div className="control">
                    <input type="text"
                      placeholder={languageTexts[language].authNamePlaceholder}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="input"
                      required />
                  </div>
                </div>
                <div className="field login-content-form-field">
                  <label className="label">{languageTexts[language].authEmail}</label>
                  <div className="control">
                    <input type="email"
                      placeholder={languageTexts[language].authEmailPlaceholder}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input"
                      required />
                  </div>
                </div>
                <div className="field login-content-form-field">
                  <label className="label">{languageTexts[language].authPhoneNumber}</label>
                  <div className="control">
                    <PhoneInput
                      placeholder={languageTexts[language].authPhoneNumberPlaceholder}
                      value={phone}
                      onChange={setPhone}/>

                    <p className="login-content-form-field-description">{languageTexts[language].authRegister}</p>
                  </div>
                </div>
                <div className="field login-content-form-field">
                  <label className="label">{languageTexts[language].authPassword}</label>
                  <div className="control">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder={languageTexts[language].authPasswordPlaceholder}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input"
                      required />
                    <img
                      src={eyeIcon}
                      alt="Toggle Password"
                      className="eye-icon"
                      onClick={handleTogglePassword} />
                  </div>
                </div>
                <div className="field login-content-form-field">
                  <label className="label">{languageTexts[language].authConfirmPassword}</label>
                  <div className="control">
                    <input
                      type={showConfirmationPassword ? "text" : "password"}
                      placeholder={languageTexts[language].authPasswordPlaceholder}
                      value={confirmationPassword}
                      onChange={(e) => setConfirmationPassword(e.target.value)}
                      className="input"
                      required />
                    <img
                      src={eyeIcon}
                      alt="Toggle Password"
                      className="eye-icon"
                      onClick={handleToggleConfirmationPassword} />
                  </div>
                </div>
                <label className="checkbox login-content-form-field">
                  <input
                    type="checkbox"
                    onChange={(e) => setTermsAgree(e.target.checked)} />
                  {languageTexts[language].authTermsAndConditions1} <a href="/termsandconditions">{languageTexts[language].authTermsAndConditions2}</a> {languageTexts[language].authTermsAndConditions3} <a href="/privacy">{languageTexts[language].authTermsAndConditions4}</a>
                </label>
                <ErrorMessage message={errorMessage} />
                <ReCAPTCHA 
                  ref={recaptchaRef} 
                  onChange={(value) => setRecaptchaValue(value)} 
                  style={recaptchaStyle} 
                  className="g-recaptcha" 
                  sitekey={process.env.REACT_APP_SITE_KEY} />
                <button className="button is-primary login-content-submit register-submit" type="submit" disabled={error}>{languageTexts[language].authRegisterButton}</button>
                <GoogleRegister
                    token={token}
                    teamId={teamId}
                    openInformationModal={openInformationModal}
                    label={languageTexts[language].authGoogleRegister}
                />
              </form>
              </>
              }
            </div>
            <p className="login-footer">{languageTexts[language].authHaveAccount} <a href="/login">{languageTexts[language].authLoginButton}</a></p>

            <div className="banner-bottom">
              <DecorationBanner text={'Learn Build Share Showcase'} />
            </div>
        </section>
    )
}

export default CredentialsRegister;
