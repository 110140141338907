import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';


import './myApp.scss';
import { getUserRecentsApps, getTeamApps, getUserApps } from "../../api/applications";
import { getUserFavoriteAppIds, getUserFavoriteApps } from "../../api/user";
import TopBar from "../../components/navBar/TopBar";
import Searcher from "../../components/searcher/Searcher";
import Loader from "../../components/loader/Loader";
import languageTexts from '../../languajeConstant';
import { USER, PROUSER } from '../../constants';
import ExtendedCarousel from "../../components/extendedCarousel/ExtendedCarousel";

import { ReactComponent as GlobalIcon } from '../../assets/newDesign/mywork-icon.svg';
import { ReactComponent as RightArrow } from '../../assets/newDesign/rightarrow-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/newDesign/global-icon.svg';
import { ReactComponent as FavouriteIcon } from '../../assets/newDesign/favourite-icon.svg';
import InternalView from "./InternalView";

const MyApp = () => {
    const token = useSelector((state) => state.session.token);
    const user = useSelector((state) => state.session.userInfo);
    const [teamApps, setTeamApps] = useState([]);
    const [recentsApps, setRecentsApps] = useState([]);
    const [myApps, setMyApps] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [filteredApps, setFilteredApps] = useState(null);
    const [areFavoriteAppsFetched, setAreFavoriteAppsFetched] = useState(false);
    const [favoriteApps, setFavoriteApps] = useState([]);
    const [showMyApps, setShowMyApps] = useState(false);
    const [showTeamApps, setShowTeamApps] = useState(false);
    const [showFavoriteApps, setShowFavoriteApps] = useState(false);
    const [completeFavoriteApps, setCompleteFavoriteApps] = useState([])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        setShowMyApps(false);
        setShowTeamApps(false);
        setShowFavoriteApps(false);
        if (location.pathname.includes('/workspace/favorites')) {
            setShowMyApps(false);
            setShowTeamApps(false);
            setShowFavoriteApps(true);
        } else if (location.pathname.includes('/workspace/teams')) {
            setShowTeamApps(true);
            setShowMyApps(false);
            setShowFavoriteApps(false);
        } else if (location.pathname.includes('/workspace/myapps')) {
            setShowMyApps(true);
            setShowTeamApps(false);
            setShowFavoriteApps(false);
        }
    }, [location]);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const { language } = useLanguage();

    useEffect(() => {
        async function fetchData() {
            try {
                const teamApps = await getTeamApps(token);
                const myapps = await getUserApps(token);
                const recents = await getUserRecentsApps(token);
                setMyApps(myapps.results);
                setRecentsApps(recents.results);

                const filteredTeamApps = teamApps.results.filter(app => !app.is_private);
                setTeamApps(filteredTeamApps);
                setFilteredApps(recents.results);
            } catch (error) {
                setErrorMessage("Error to load the apps");
            }
        };
        const fetchFavoriteApps = async () => {
            try {
                const favoritesApps = await getUserFavoriteAppIds(token);
                const favoritesAppsComplete = await getUserFavoriteApps(token);   
                setCompleteFavoriteApps(favoritesAppsComplete);
                setFavoriteApps(favoritesApps);
                setAreFavoriteAppsFetched(true)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        fetchFavoriteApps();
    }, [token]);

    const handleSearch = query => {
        let appsToFilter = teamApps;
        if (showMyApps) {
            appsToFilter = myApps;
        } else if (showTeamApps) {
            appsToFilter = teamApps;
        } else if (showFavoriteApps) {
            appsToFilter = completeFavoriteApps;
        } else {
            appsToFilter = recentsApps;
        }
    
        const filtered = appsToFilter.filter(app =>
            app.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredApps(filtered);
    };
    const updateFilteredApps = (appsToShow) => {
        setFilteredApps(appsToShow);
    };
    
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowWidth <= 768;
    const updateFavorites = async () => {
        try {
            const favoritesAppsUpdated = await getUserFavoriteAppIds(token);
            setFavoriteApps(favoritesAppsUpdated);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (showFavoriteApps) {
            setFilteredApps(completeFavoriteApps);
        } else if (showTeamApps) {
            setFilteredApps(teamApps);
        } else if (showMyApps) {
            setFilteredApps(myApps);
        }
    }, [showFavoriteApps, showTeamApps, showMyApps, myApps, teamApps, completeFavoriteApps]);
    
    return(
        <main className="my-app">
            {!isMobile && <TopBar hasNavegation />}
            <section className="my-app-container">
                <h1 className="my-app-container-title">{languageTexts[language].workspaceTitle}</h1>
                <p  className="my-app-container-search-title">{languageTexts[language].workspaceSearchIn}{user.userName}{languageTexts[language].workspaceSpace}</p>
                <Searcher onSearch={handleSearch} />
                <div className="my-app-container-tabs">
                    <div className="my-app-container-tabs-container">
                        <Link to="/workspace/myapps"
                        className={`button ${showMyApps ? 'active' : ''}`}>
                            <span><GlobalIcon /></span>{languageTexts[language].workspaceMyApps}<RightArrow />
                        </Link>
                        {(user.role !== USER && user.role !== PROUSER) ?
                            <Link to="/workspace/teams"
                            className={`button team-tab ${showTeamApps ? 'active' : ''}`}>
                                <span><TeamIcon /></span>{languageTexts[language].workspaceTeam}<RightArrow />
                            </Link>
                            : null
                        }
                        {user.role !== USER &&
                        <Link to="/workspace/favorites"
                        className={`button ${showFavoriteApps ? 'active' : ''}`}
                        disabled={user.role === USER}
                        {...(user.role === USER ? { "data-tooltip": languageTexts[language].unlockFeature } : {})}>
                            <span><FavouriteIcon /></span>{languageTexts[language].workspaceFavorite}<RightArrow />
                        </Link>}
                        
                    </div>
                </div>
                {showMyApps ? <InternalView apps={filteredApps} favoriteApps={favoriteApps} subtitle={languageTexts[language].workspaceMyApps} icon={<GlobalIcon />} onUpdateFavorites={updateFavorites} /> 
                : showTeamApps ? <InternalView apps={filteredApps} favoriteApps={favoriteApps} subtitle={languageTexts[language].workspaceTeam} icon={<TeamIcon />} onUpdateFavorites={updateFavorites} />
                : showFavoriteApps ? <InternalView apps={filteredApps} favoriteApps={favoriteApps} subtitle={languageTexts[language].workspaceFavorite} icon={<FavouriteIcon />} onUpdateFavorites={updateFavorites} />

                : filteredApps && areFavoriteAppsFetched ? (
                    <div className="my-app-container-carousel">
                        <h2>{languageTexts[language].workspaceRecentlyModified}</h2>
                        <ExtendedCarousel cards={filteredApps.slice(0, 10)} favoriteApps={favoriteApps}  miniCards={!isMobile}/>
                    </div>
                ): <Loader />}
                {errorMessage}
            </section>

        </main>
    )
}

export default MyApp;