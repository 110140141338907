import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { Link } from 'react-router-dom';
import "./newTeam.scss";

import { getTeamApps, getUserApps } from "../../api/applications";
import { getTeamById } from "../../api/teams";
import { getUserFavoriteAppIds } from "../../api/user";

import AllAppsSection from "../../components/allAppsSection/AllAppsSection";

import CategoriesButtons from "../../components/categoriesButtons/CategoriesButtons";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import ExtendedCarousel from "../../components/extendedCarousel/ExtendedCarousel";
import Searcher from "../../components/searcher/Searcher";
import TopBar from "../../components/navBar/TopBar";
import languageTexts from '../../languajeConstant';

const NewTeam = () => {
    const token = useSelector((state) => state.session.token);
    const userInfo = useSelector((state) => state.session.userInfo);

    const [teamName, setTeamName] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [recentApps, setRecentApps] = useState([]);
    const [userApps, setUserApps] = useState([]);
    const [favoriteAppIds, setFavoriteAppIds] = useState([]);
    const [showAllApps, setShowAllApps] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [filteredRecentApps, setFilteredRecentApps] = useState([]);
    const [filteredUserApps, setFilteredUserApps] = useState([]);

    const { language } = useLanguage();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowWidth <= 768;
    const checkSticky = () => {
      const heightToChange = 96;
      const currentScroll = window.scrollY;
      if (currentScroll >= heightToChange) {
          setIsSticky(true);
      } else {
          setIsSticky(false);
      }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkSticky);

        return () => {
            window.removeEventListener('scroll', checkSticky);
        };
    }, []);
    
    const handleSearch = (query) => {
      setSearchQuery(query);
      applyFilters(query, selectedCategory);
    };

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    useEffect(() => {
      async function fetchData() {
        try {
          const team = await getTeamById(userInfo.team)
          const recentsResponse = await getTeamApps(token);
          const userAppsResponse = await getUserApps(token);
          const favoriteAppsResponse = await getUserFavoriteAppIds(token);
          setRecentApps(recentsResponse.results);
          setUserApps(userAppsResponse.results);
          setFavoriteAppIds(favoriteAppsResponse);
          setTeamName(team.name)
        } catch (error) {
          setErrorMessage("Error to load the apps. " + error.message);
        }
      }
      fetchData();
    }, [token]);

    const applyFilters = (query, category) => {
      let newFilteredRecentApps = [...recentApps];
      let newFilteredUserApps = [...userApps];

      if (query) {
        const normalizedQuery = removeAccents(query.toLowerCase());
        newFilteredRecentApps = newFilteredRecentApps.filter((app) =>
            removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
        );
        newFilteredUserApps = newFilteredUserApps.filter((app) =>
            removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
        );
      }
      if (category) {
        newFilteredRecentApps = newFilteredRecentApps.filter((app) => app.category[`name_${language}`] === category);
        newFilteredUserApps = newFilteredUserApps.filter((app) => app.category[`name_${language}`] === category);
      }
      setFilteredRecentApps(newFilteredRecentApps);
      setFilteredUserApps(newFilteredUserApps);
    };

    useEffect(() => {
      applyFilters(searchQuery, selectedCategory);
    }, [recentApps, userApps, searchQuery, selectedCategory]);

    return (
      <main className={`newTeam`}>
        {!isMobile && <TopBar hasNavegation />}
        <section className="newTeam-container">
          {userInfo.team ? (
            recentApps.length > 0 ? (
              showAllApps ? (
                <AllAppsSection 
                  mostUsedApps={recentApps}
                  recentApps={userApps}
                  exploreMostUsedText={`${languageTexts[language].teamLatestApps} ${teamName}`}
                  exploreRecentText={`${languageTexts[language].teamUserApps} ${teamName}`} />
              ) : (
                <>
                  <ErrorMessage message={errorMessage} />
                  <header className="newTeam-header">
                    <Searcher onSearch={handleSearch} placeholder="Search" />
                  </header>
                  <CategoriesButtons
                    token={token}
                    applyFilters={applyFilters}
                    searchQuery={searchQuery}
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    labelText={languageTexts[language].teamCategoryList} />
                  <section className="newTeam-most-used">
                    <h2 className="newTeam-subtitle">
                      {languageTexts[language].teamLatestApps} {teamName}:
                      <button className="button newTeam-subtitle-button" onClick={() => setShowAllApps(true)}>{languageTexts[language].exploreShowAll}</button>
                    </h2>
                    <ExtendedCarousel cards={filteredRecentApps.slice(0, 10)} favoriteApps={favoriteAppIds} />
                  </section>
                  <section className="newTeam-recent">
                    <h2 className="newTeam-subtitle">
                      {languageTexts[language].teamUserApps} {teamName}:
                      <button className="button newTeam-subtitle-button" onClick={() => setShowAllApps(true)}>{languageTexts[language].exploreShowAll}</button>
                    </h2>
                    <ExtendedCarousel cards={filteredUserApps.slice(0, 10)} favoriteApps={favoriteAppIds} />
                  </section>
                </>
              )
            ) : (
              <p>{languageTexts[language].teamNoAppsYet}</p>
            )
          ) : (
            <div className="newTeam-missing">
              <h2>{languageTexts[language].missingTeam}</h2>
              <p>{languageTexts[language].missingTeamSubtitle}</p>
              <ul>
                <li>{languageTexts[language].librariesForTeam}</li>
                <li>{languageTexts[language].personalizedProfile}</li>
                <li>{languageTexts[language].systemAnalysis}</li>
                <li>{languageTexts[language].collaborationTools}</li>
                <li>{languageTexts[language].apiIntegrations}</li>
              </ul>
              
            <Link className="button newTeam-missing-btn" to="/subscriptions">
                {languageTexts[language].missingTeamButton}
            </Link>
            </div>
          )}
        </section>
      </main>
    );    
  };
  
export default NewTeam;