import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './subscriptions.scss';

import { getPlans } from "../../api/plans";
import { getTeamById } from "../../api/teams";
import { getActiveSubscription } from "../../api/subscriptions";

import TopBar from "../../components/navBar/TopBar";
import PlanSelectionAndSubscription from "./PlanSelectionAndSubscription";


const Subscriptions = () => {
    const [plans, setPlans] = useState([]);
    const [userSubscription, setUserSubscription] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [showModalBackground, setShowModalBackground] = useState(false);
    const [subscriptionCompleted, setSubscriptionCompleted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [, setTeam] = useState(null);
    const token = useSelector((state) => state.session.token);
    const userInfo = useSelector((state) => state.session.userInfo);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowWidth <= 768;

    useEffect(() => {
        const fetchUserActiveSubscriptions = async () => {
          try {
            const subscriptionResponse = await getActiveSubscription(token);
            setUserSubscription(subscriptionResponse)
          } catch (error) {
            console.error(error);
          }
    
        }
        const fetchPlans = async () => {
          try {
            const plansResponse = await getPlans(token);
            setPlans(plansResponse);
          } catch (error) {
            console.error(error);
          }
        };
        fetchPlans();
        fetchUserActiveSubscriptions();
      }, [token]);
      useEffect(() => {
        if (userInfo.role === 4 && userInfo.team !== null) {
          const fetchTeam = async () => {
            try {
              const teamResponse = await getTeamById(userInfo.team);
              setTeam(teamResponse);
            } catch (error) {
              console.error(error);
            }
          }
          fetchTeam()
        };
      }, [userInfo.role, userInfo.team]);
    return (
        <main className={`subscription ${showModalBackground ? "modal-hidde" : ""}`}> 
        {!isMobile && <TopBar hasNavegation />}
          <section className="subscription-container">
          {showModalBackground && <div className="background-show-modal"></div>}
            <PlanSelectionAndSubscription
              plans={plans}
              setSelectedPlan={setSelectedPlan}
              setShowModalBackground={setShowModalBackground}
              selectedPlan={selectedPlan}
              token={token}
              setSubscriptionCompleted={setSubscriptionCompleted}
              setErrorMessage={setErrorMessage}
              userSubscription={userSubscription}
              setUserSubscription={setUserSubscription}
              subscriptionCompleted={subscriptionCompleted}
              userInfo={userInfo}
            />
            {errorMessage}
          </section>
        </main>
      );
}

export default Subscriptions;