import axios from "axios";


export async function getTeams(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function getTeamById(teamId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function addUsersToTeam(teamId, userEmails, token) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  const body = {
    emails: userEmails
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}/users`, body, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function joinTeam(teamId, token) {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  }
  const body = {
    token: token,
    team_id: teamId
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/join`, body, config);
    return response.data.results;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export async function createTeam(teamForm, token) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token
    }
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/`, teamForm, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function editTeam(teamId, teamForm, token) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }

  try {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}`, teamForm, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function getTeamUsers(teamId, token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}/users`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function getTeamPendingUsers(teamId, token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}/users/pending-approval`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};
export async function deleteUserTeam(userId, teamId, token) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  try {
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}/users/${userId}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};


export async function promoteUser(userId, teamId, token) {
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,
    }
  }
  const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/teams/${teamId}/users/${userId}/promote`, {} , config);
  try {
    return response.data.results;
  }
  catch (error) {
    throw new Error('Error while editing user role');
  }
}