const appCardTexts = {
    es: {
        appCardsCancelText: '¿Estás seguro que quieres eliminar esta aplicación?',
        appCardsEdit: 'Editar App',
        appCardsDelete: 'Eliminar App',
        appCardsTemplate: 'Plantilla',
        appCardsTeamApp: 'App de equipo',
        appCardsPrivateApp: 'App privada',
        appCardsSuperApp: 'Super App',
        appCardsDuplicateApp: 'Duplicar App',
        appCardsLiveApp: 'App en vivo',
    },
    en: {
        appCardsCancelText: 'Are you sure you want to delete this application?',
        appCardsEdit: 'Edit App',
        appCardsDelete: 'Delete App',
        appCardsTemplate: 'Template',
        appCardsTeamApp: 'Team App',
        appCardsPrivateApp: 'Private App',
        appCardsSuperApp: 'Super App',
        appCardsDuplicateApp: 'Duplicate App',
        appCardsLiveApp: 'Live App',
    },
};

export default appCardTexts;
