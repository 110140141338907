const useAppTexts = { 
    es: {
        useAppTemplate: 'Plantilla',
        useAppDeleteConfirmation: '¿Estás seguro de que quieres eliminar esta aplicación? Todos los datos se perderán.',
        useAppInformation: 'Información de la app:',
        useAppTime: 'vez',
        useAppTimes: 'veces',
        useAppTeam: 'Equipo',
        useAppPrompt: 'Prompt',
        useLogic: 'Lógica: ',
        useAppCopyText: 'Copiar Texto',
        useAppDownloadPdf: 'Descargar como PDF',
        useAppDownloadDoc: 'Descargar como DOCx',
        useAppCopySuccess: '¡Texto copiado al portapapeles!',
        useAppFileDescription: 'Descripción del archivo',
        useAppFileUploaded: 'Archivo subido:',
        useAppFileUpload: 'Subir archivo',
        useAppURLMessage: 'La búsqueda por URL no tendrá efecto ya que es una funcionalidad pro y eres un usuario regular.',
        useAppOutputFormat: 'Formato de salida',
        useAppClear: 'Borrar',
        useAppCreditUse10: 'Cuesta 10 créditos',
        useAppCreditUse5: 'Cuesta 5 créditos',
        useAppCreditUse3: 'Cuesta 3 créditos',
        useAppCreditUse1: 'Cuesta 1 crédito',
        useAppSubmit: 'Enviar',
        useAppHistory: 'Usos anteriores:',
        useAppSelectDate: 'Selecciona una fecha',
    },
    en: {
        useAppTemplate: 'Template',
        useAppDeleteConfirmation: 'Are you sure you want to delete this application? All data will be lost.',
        useAppInformation: 'App info:',
        useAppTime: 'time',
        useAppTimes: 'times',
        useAppTeam: 'Team',
        useAppPrompt: 'Prompt',
        useLogic: 'Logic: ',
        useAppCopyText: 'Copy Text',
        useAppDownloadPdf: 'Download as PDF',
        useAppDownloadDoc: 'Download as DOCx',
        useAppCopySuccess: 'Text copied to clipboard!',
        useAppFileDescription: 'File description',
        useAppFileUploaded: 'File uploaded:',
        useAppFileUpload: 'Upload file',
        useAppURLMessage: 'URL search won\'t have any effect since it\'s a pro feature and you are a regular user.',
        useAppOutputFormat: 'Output format',
        useAppClear: 'Clear',
        useAppCreditUse10: 'Costs 10 credits',
        useAppCreditUse5: 'Costs 5 credits',
        useAppCreditUse3: 'Costs 3 credits',
        useAppCreditUse1: 'Costs 1 credit',
        useAppSubmit: 'Submit',
        useAppHistory: 'History uses:',
        useAppSelectDate: 'Select a date',
    }
}

export default useAppTexts;