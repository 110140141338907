import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { checkForResult } from '../../../api/backgroundTasks';
import { deleteWorkflow, getWorkflow, runWorkflow } from '../../../api/workflow';
import TopBar from "../../../components/navBar/TopBar";
import './useWorkflow.scss';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as CancelIcon } from '../../../assets/newDesign/cancel-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/newDesign/edit-icon.svg';
import { ReactComponent as TeamIcon } from '../../../assets/newDesign/team-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/newDesign/trash-icon.svg';
import ConfirmationDialog from "../../../components/confirmationDialog/ConfirmationDialog";
import { useLanguage } from "../../../hooks/languageHook/LanguageHook";
import languageTexts from "../../../languajeConstant";
import CardWorkflow from './CardWorkflow';


function UseWorkflow() {
    const { language } = useLanguage();
    const userInfo = useSelector((state) => state.session.userInfo)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentWorkflow, setCurrentWorkflow] = useState({});
    const [workflowId, setWorkflowId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [createdAgo, setCreatedAgo] = useState('');
    const [result, setResult] = useState();
    const [inputsState, setInputsState] = useState({});
    const [files, setFiles] = useState([]);
    const [textToCopy, setTextToCopy] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [hasTeamBadge, setHasTeamBadge] = useState(false);
    const [availableToEdit, setAvailableToEdit] = useState(false);
    const [availableToDelete, setAvailableToDelete] = useState(false);
    const [showIntermediateResults, setShowIntermediateResults] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const fileInputRefs = useRef({});

    const location = useLocation();
    const { pathname } = location;
    const token = useSelector((state) => state.session.token);
    const contentRef = useRef(null);
    const navigate = useNavigate();

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowWidth <= 768;

    useEffect(() => {
        async function fetchData() {
            if (!workflowId) {
                setLoading(true);
                return
            } else { setLoading(false); }
            try {
                const workflow = await getWorkflow(token, workflowId);
                setCurrentWorkflow(workflow);
                setHasTeamBadge(workflow.user.team_id === userInfo.team && userInfo.team !== null);
                setAvailableToDelete(
                    userInfo.userId === workflow.user.id || userInfo.role === 1 ||
                    (workflow.user.team_id === userInfo.team && userInfo.role === 3)
                );
                setAvailableToEdit(
                    userInfo.userId === workflow.user.id || userInfo.role === 1 ||
                    (userInfo.team && workflow.user.team_id === userInfo.team)
                );

                setCreatedAgo(moment(workflow.creation_date).fromNow());

            } catch (error) {
                setLoading(false);
                setError(error.mesage);
            }
        }
        fetchData();
    }, [token, workflowId]);
    useEffect(() => {
        if (currentWorkflow.structure) {
            const newInputsState = {};
            currentWorkflow.structure.forEach(inputObj => {
                newInputsState[inputObj.step] = Object.keys(inputObj.inputs).reduce((acc, key) => {
                    acc[key] = "";
                    return acc;
                }, {});
            });
            setInputsState(newInputsState);
        }
    }, [currentWorkflow.structure]);

    useEffect(() => {
        const parts = pathname.split('/');
        const appPart = parts[parts.length - 1];
        const parsedAppId = parseInt(appPart, 10);

        if (!isNaN(parsedAppId)) {
            setWorkflowId(parsedAppId);
        }
    }, [pathname]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const updatedStructure = currentWorkflow.structure.map(step => ({
            ...step,
            inputs: inputsState[step.step]
        }));

        const workflowInputs = {
            app_inputs: updatedStructure
        };

        try {
            const response = await runWorkflow(workflowId, workflowInputs, files, token);
            const data = response.data.results;
            if (data && data.id) {
                setTimeout(() => {
                    checkForResult(data.id, setLoading, setResult, setError, setTextToCopy, 3000);
                }, 750);
            }
        } catch (error) {
            console.error('Error starting workflow task processing:', error);
            setLoading(false);
            setError(error.message);
            setFiles([]);
        }
    }

    const handleClearButton = () => {
        setInputsState({});
        setFiles([]);
        setUploadedFiles({});
        setResult(null);
    };

    const confirmDeleteWorkflow = (workflowId) => {
        deleteWorkflow(token, workflowId)
            .then(() => {
                navigate('/myworkflows');
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const handleCancelButtonClick = () => {
        navigate('/myworkflows');
    }

    const handleEditWorkflow = () => {
        navigate(`./edit`);
    }

    const toggleIntermediateResults = () => {
        setShowIntermediateResults(!showIntermediateResults);
    };

    return (
        <section className='useWorkflow'>
            {!isMobile && <TopBar hasNavegation />}
            <section className='useWorkflow-content'>
                {showConfirmation && (
                    <ConfirmationDialog
                        message={languageTexts[language].useWorkflowDeleteConfirmation}
                        onConfirm={() => confirmDeleteWorkflow(currentWorkflow.id)}
                        onCancel={() => setShowConfirmation(false)}
                    />
                )}
                <section className='useWorkflow-content-container'>
                    <header className='useWorkflow-content-header'>
                        <div className="card-header-badges">
                            {hasTeamBadge && <div className="card-header-badge team-badge" data-tooltip={languageTexts[language].appCardsTeamApp}><TeamIcon /></div>}
                        </div>
                        <div className='useWorkflow-content-actions'>
                            {availableToDelete
                                && <button className='useWorkflow-content-actions-delete' data-tooltip={languageTexts[language].deleteWorkflow} onClick={() => setShowConfirmation(true)}><DeleteIcon /></button>
                            }
                            {availableToEdit
                                && <button className='useWorkflow-content-actions-delete' data-tooltip={languageTexts[language].editWorkflow} onClick={handleEditWorkflow}><EditIcon /></button>
                            }
                            <button className='useWorkflow-content-actions-cancel' onClick={handleCancelButtonClick}><CancelIcon /></button>
                        </div>
                    </header>

                    <main className='useWorkflow-content-main'>
                        <h1>{currentWorkflow.name}</h1>
                        <h2>{currentWorkflow.description}</h2>
                        <p>{languageTexts[language].useWorkflowBy} {currentWorkflow?.user?.username}</p>
                        <p>{languageTexts[language].useWorkflowCreated} {createdAgo}</p>
                        <form className='useWorkflow-form' onSubmit={handleSubmit}>
                            {currentWorkflow.structure?.map((inputObj, index) => (
                                <CardWorkflow
                                    step={inputObj.step}
                                    appId={inputObj.app_id}
                                    workflowInputs={inputObj.inputs}
                                    token={token}
                                    inputValues={inputsState[inputObj.step]}
                                    setFiles={setFiles}
                                    onInputChange={(step, name, value) => {
                                        setInputsState(prev => ({
                                            ...prev,
                                            [step]: {
                                                ...prev[step],
                                                [name]: value
                                            }
                                        }));
                                    }}
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                    fileInputRefs={fileInputRefs}
                                />
                            ))}
                            <div className="useWorkflow-form-buttons">
                                <button className="button useWorkflow-form-clear"
                                    type="button" onClick={handleClearButton}>
                                    {languageTexts[language].useWorkflowClearButton}
                                </button>
                                <button
                                    className="button useWorkflow-form-submit"
                                    type="submit" disabled={loading}>
                                    {languageTexts[language].useWorkflowSubmitButton}
                                </button>
                            </div>
                        </form>
                        {result &&
                            <div>
                                <h2>{languageTexts[language].useWorkflowResults}</h2>
                                <div ref={contentRef} className='useWorkflow-results' dangerouslySetInnerHTML={{ __html: result.results.results }} />
                                <div className="useWorkflow-step-results">
                                    <button className="button useWorkflow-step-results toggle-intermediate-results" onClick={toggleIntermediateResults}>
                                        {showIntermediateResults ? languageTexts[language].useWorkflowHideIntermediateResults : languageTexts[language].useWorkflowShowIntermediateResults}
                                    </button>
                                    {showIntermediateResults &&
                                        <div>
                                            {Object.entries(result.results.step_results)
                                                .sort((a, b) => {
                                                    const stepNumberA = parseInt(a[0].match(/\d+/)[0], 10);
                                                    const stepNumberB = parseInt(b[0].match(/\d+/)[0], 10);
                                                    return stepNumberA - stepNumberB;
                                                })
                                                .slice(0, -1)
                                                .map(([step, result], index) => (
                                                    <details key={index} className="useWorkflow-step-results step-detail">
                                                        <summary>{step.replace(/_/g, ' : ')}</summary>
                                                        <div dangerouslySetInnerHTML={{ __html: result }} />
                                                    </details>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {error}
                    </main>
                </section>
            </section>
        </section>
    );
}

export default UseWorkflow;

