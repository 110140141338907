import { 
  LOGIN_SUCCESS, 
  LOGIN_FAILURE, 
  LOGOUT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_CREDITS,
  INITIALIZE_USER } from '../constants/actionTypes';
import initialState from './initialState';

const userReducer = (state = initialState.session, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.access_token,
        isLoggingIn: true,
        userInfo: {
            userId: action.payload.user.id,
            userName: action.payload.user.username,
            email: action.payload.user.email,
            role: action.payload.user.active_role,
            team: action.payload.user.team_id,
            phoneNumber: action.payload.user.phone_number,
            profileImage: action.payload.user.profile_pic,
            subscribenewsletter: false,
            credits: action.payload.user.credits
        }
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        isLoggingIn: false
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        isLoggingIn: false,
        userInfo: {
          userId: '',
          userName: '',
          email: '',
          role: '',
          team: '',
          phoneNumber: '',
          profileImage: '',
          subscribenewsletter: false
      }
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        token: action.payload.access_token,
        isLoggingIn: true,
        userInfo: {
            userId: action.payload.user.id,
            userName: action.payload.user.username,
            email: action.payload.user.email,
            role: action.payload.user.active_role,
            team: action.payload.user.team_id,
            phoneNumber: action.payload.user.phone_number,
            profileImage: '',
            subscribenewsletter: false,
            credits: action.payload.user.credits
        }
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        token: null,
        isLoggingIn: false,
        errorMessage: action.payload
      }
      case INITIALIZE_USER:
        return {
          ...state,
          userInfo: {
              userId: action.payload.userId,
              userName: action.payload.userName,
              email: action.payload.email,
              role: action.payload.role,
              team: action.payload.team,
              phoneNumber: action.payload.phoneNumber,
              profileImage: action.payload.profileImage,
              subscribenewsletter: false,
              credits: action.payload.credits
          }
        }
    case UPDATE_CREDITS:
      return {
        ...state,
        userInfo: {
            ...state.userInfo,
            credits: action.payload.credits
        }
      }
    default:
      return state;
  }
}

export default userReducer;