import React from "react";
import './badge.scss'

const Badge = ({ isEnterprise, text, newColor }) => {

    const style = {
        border: `1px solid ${newColor}`,
    };
    const textStyle = {
        color: `${newColor}`,
    };

    return (
        <div style={style} className={isEnterprise ? 'badge badge-pink' : 'badge badge-general'} >
            <p style={textStyle}>{text}</p>
        </div>
    )
}

export default Badge;