//ROLES
export const SUPERUSER = 1;
export const USER = 2;
export const TEAMADMIN = 3;
export const TEAMMEMBER = 4;
export const PROUSER = 5;

//SPECIAL APPS
export const  APPGENERATOR = 4;

//OUTPUT FORMAT TYPES
export const outputFormats = [
    { label: { es: 'Quiero que Flipando decida por mi', en: 'Let Flipando Decide for Me' }, value: 'general' },
    { label: { es: 'Texto', en: 'Text' }, value: 'text' },
    { label: { es: 'Tabla', en: 'Table' }, value: 'table' },
    { label: { es: 'Código', en: 'Code' }, value: 'code' },
    { label: { es: 'JSON', en: 'JSON' }, value: 'json' },
    { label: { es: 'Widget Interactivo (Beta)', en: 'Interactive Widget (Beta)' }, value: 'widget' },
];