import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { cancelSubscription, createSubscription } from "../../api/subscriptions";
import { createTeam } from "../../api/teams";
import { initializeUser } from '../../redux/actions/authActions';


const SubscriptionButtonWrapper = ({ selectedPlan, teamMembers, teamName, teamColor, token, setSubscriptionCompleted, setErrorMessage }) => {
  const dispatch = useDispatch();  
  const navigate = useNavigate();
    const calculateSeats = (teamMembers) => {
      if (teamMembers <= 10) return 10;
      if (teamMembers <= 25) return 25;
      if (teamMembers <= 100) return 100;
      return 300;
    }

    return (
      <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: selectedPlan.paypal_plan_id,
            quantity: selectedPlan.type === "Teams" ? calculateSeats(teamMembers) : 1
          }).then((subscriptionId) => {
            return subscriptionId;
          });
        }}
        style={{
          label: "subscribe",
          shape: "pill"
        }}
        onApprove={(data, actions) => {
          return createSubscription(token, data.subscriptionID, selectedPlan.id)
            .then((details) => {
              if (selectedPlan.type === "Teams") {
                const createTeamForm = {name: teamName, color: teamColor}
                createTeam(createTeamForm, token)
                  .then(() => {
                    setSubscriptionCompleted(true);
                    dispatch(initializeUser());
                    navigate(`/subscriptions/details`, { state: { transactionDetails: details, selectedPlan: selectedPlan } });
                  })
                  .catch(error => {
                    setErrorMessage('Something went wrong when creating team.' + error.message);
                    cancelSubscription(token, details.id);
                  });
              } else {
                setSubscriptionCompleted(true);
                dispatch(initializeUser());
                navigate(`/subscriptions/details`, { state: { transactionDetails: details, selectedPlan: selectedPlan } });
              }
            })
        }}
        onError={(error) => {
          setErrorMessage('Something went wrong with the transaction.' + error.message);
        }}
      />
    );
  }

export default SubscriptionButtonWrapper;
