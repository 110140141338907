const createWorkflowTexts = { 
    es: {
        createWorkflowError: "Por favor asegúrese de que no se deje sin usar ninguna salida de las aplicaciones intermedias.",
        createWorkflowInfo: "Información del flujo de trabajo",
        createWorkflowName: "Nombre del flujo de trabajo",
        createWorkflowDescription: "Descripción del flujo de trabajo",
        createWorkflowCreateMsg: "Puede probar este flujo de trabajo o crearlo si está listo",
        createWorkflowSelectFirstApp: "Seleccione la aplicación para iniciar el flujo de trabajo",
        createWorkflowSelectedApp: "Seleccione la siguiente aplicación para unirse a este flujo de trabajo",
        createWorkflowCreateButton: "Crear",
        createWorkflowLoadMoreButton: "Cargar más",
        createWorkflowCardBy: "Por",
        createWorkflowCardShowPrompt: "Mostrar prompt",
        createWorkflowCardFileName: "Nombre de archivo",
        createWorkflowCardFileUploaded: "Archivo subido: ",
        createWorkflowCardUploadFile: "Subir archivo",
        createWorkflowCardOutputApp: "Output de la app",
        createWorkflowCardUserInput: "Escribir input",
    },
    en: {
        createWorkflowError: "Please ensure that no output from intermediate apps is left unused.",
        createWorkflowInfo: "Workflow Information",
        createWorkflowName: "Workflow Name",
        createWorkflowDescription: "Workflow Description",
        createWorkflowCreateMsg: "You can test this workflow or create it if it is ready",
        createWorkflowSelectFirstApp: "Select the app to start the workflow",
        createWorkflowSelectedApp: "Select the next app to join this workflow",
        createWorkflowCreateButton: "Create",
        createWorkflowLoadMoreButton: "Load more",
        createWorkflowCardBy: "By",
        createWorkflowCardShowPrompt: "Show prompt",
        createWorkflowCardFileName: "File Name",
        createWorkflowCardFileUploaded: "File Uploaded: ",
        createWorkflowCardUploadFile: "Upload File",
        createWorkflowCardOutputApp: "Output App",
        createWorkflowCardUserInput: "Write input",
    }
}

export default createWorkflowTexts;