import React from "react";

const NewApiKeyModal = ({closeModal, handleCreateApiKey, setName}) => {

    return(
        <section className="modal-apikey">
            <div className="modal-background"></div>
            <div className="modal-apikey-body">
                <h1>Create New secret key</h1>

                <div className="field modal-apikey-body-field">
                        <label className="label">Name</label>
                        <input 
                        type="text" 
                        className="input"
                        onChange={(event) => setName(event.target.value)} />
                    </div>
                <div className="modal-apikey-body-buttons">
                    <button className="modal-apikey-body-buttons-cancel" onClick={() => closeModal(false)}>Cancel</button>
                    <button className="modal-apikey-body-buttons-create" onClick={handleCreateApiKey}>Create</button>
                </div>
            </div>
        </section>
    )
}

export default NewApiKeyModal;
