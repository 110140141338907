import axios from "axios";


export async function getModels(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/models/`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function getModelById(id, token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "Bearer " + token,

    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/models/${id}`, requestOptions);
    return response.data.results;
  } catch (error) {
    throw new Error(error.message);
  }
};