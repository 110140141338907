import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteApp, editApp, getApp } from "../../api/applications";
import { getCategories } from "../../api/categories";
import { ReactComponent as TrashIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as CancelIcon } from '../../assets/mini-cancel-icon.svg';
import AppPreview from "../../components/appPreview/AppPreview";
import Header from "../../components/header/Header";
import LiveAppModal from '../../components/liveAppModal/LiveAppModal';
import { USER } from '../../constants';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import './editApp.scss';


const EditApp = () => {
    const [currentApp, setCurrentApp] = useState({});
    const [appName, setAppName] = useState("");
    const [appDescription, setAppDescription] = useState("");
    const [logic, setLogic] = useState("");
    const [model, setModel] = useState("");
    const [appInput, setAppInput] = useState([]);
    const [showsPrompt, setShowsPromt] = useState(true);
    const [isPrivate, setIsPrivate] = useState(false);
    const [documentChecked, setDocumentChecked] = useState(null)
    const [categories, setCategories] = useState([]);
    const [categorySelected, setCategorySelected] = useState(null);
    const [error, setError] = useState("");
    const { app } = useParams();
    const token = useSelector((state) => state.session.token);
    const appId = parseInt(app);
    const handleRemoveFields = (index) => {
        const values = [...appInput];
        values.splice(index, 1);
        setAppInput(values);
    };
    const navigate = useNavigate();
    const logicInputRef = React.useRef();
    const user = useSelector((state) => state.session.userInfo);
    const location = useLocation();
    const fromTemplate = location.state?.fromTemplate;
    const submitButtonRef = useRef(null);
    const { language } = useLanguage();
    const [showLiveAppModel, setShowLiveAppModal] = useState(false);
    const airportApp = 3

    useEffect(() => {
        const fetchData = async () => {
            try {
                const app = await getApp(token, appId);
                setCurrentApp(app);
                setAppInput(app.inputs);
                setLogic(app.logic);
                setAppName(app.name);
                setAppDescription(app.description);
                setIsPrivate(app.is_private);
                setDocumentChecked(app.has_docs);
                setModel(app.model_id);
                setCategorySelected(app.category_id);
                setShowsPromt(app.shows_prompt)
            } catch (error) {
                setError(error.mesage);
            }
        }
        const fetchCategories = async () => {
            try {
                const categoriesResponse = await getCategories(token);
                setCategories(categoriesResponse);
            } catch (error) {
                console.error(error);
            }
        };        
        fetchData();
        fetchCategories();
    }, [token, appId]);

    useEffect(() => {
        const isLogicValid = inputLogic => {
            const missingVariables = appInput
                .filter(({name}) => !inputLogic.includes(`{${name}}`))
                .map(({name}) => `{${name}}`)
                .join(', ');
            const fileNameMissing = documentChecked && !inputLogic.includes('{FileName}');
        
            let errorMessage = "";
            if (missingVariables) {
                errorMessage += `Logic is missing the following variable(s): ${missingVariables}. `;
            }
            if (fileNameMissing) {
                errorMessage += "Logic is missing {FileName}.";
            }
            if (errorMessage) {
                errorMessage += "Remember that clicking on the variable names above will automatically add them to your app's logic."
                setError(errorMessage);
                return false;
            }
            return true;
        };
        if (logic === "" || (appInput.length === 0 && documentChecked === false) || appName === "") {
            setError("Logic and Input name or FileName must have content");
        } else if (isLogicValid(logic)) {
            setError("");
        }
    }, [logic, appInput, appName, documentChecked])

    const getPlaceholder = () => {
        let placeholder = "Write your logic here with ";
        if (documentChecked) {
            placeholder += "{FileName}";
        }
        if (appInput.length > 0 && !appInput.every(field => field.name === '')) {
            const variableNames = appInput.map((field) => `{${field.name}}`).join(', ');
            placeholder += documentChecked ? `, ${variableNames}` : variableNames;
        } else if (!documentChecked) {
            placeholder += "{Variable Name}";
        }
        return placeholder;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (currentApp.type_id === airportApp) {
            setShowLiveAppModal(true);
        }
        else {
            handleEditApp();
        }
    };

    const handleEditApp = async () => {
        ReactGA.event({
            category: 'application',
            action: 'create_app',
            label: `name: ${appName}, description: ${appDescription}, logic: ${appInput}`
        });
        const app = await editApp(appId, appName, appDescription, logic, appInput, showsPrompt, isPrivate, documentChecked, categorySelected, token);
        if (app) {
            navigate(`/explore/${app.id}`);
        } else {
            setError("Something went wrong when editing app");
        }
    };
    const handleChangeInputName = (index, event) => {
        setAppInput(prevState => {
            const values = [...prevState];
            values[index].name = event.target.value;
            return values;
        });
    };
    const handleChangeInputDescription = (index, event) => {
        setAppInput(prevState => {
            const values = [...prevState];
            values[index].value = event.target.value;
            return values;
        });
    };
    const handleAddFieldsInputs = () => {
        setAppInput([...appInput, { name: '', value: '' }]);
    };
    const handleAddFileInputs = () => {
        setDocumentChecked(!documentChecked);
    }
    const handleButtonClick = (inputName) => {
        const logicInput = logicInputRef.current;
        logicInput.focus();
        const cursorPosition = logicInput.selectionStart;
        logicInput.value = logicInput.value.slice(0, cursorPosition) + ` {${inputName}}` + logicInput.value.slice(cursorPosition);
        setLogic(logicInput.value)
    };
    const handleCancelButtonClick = () => {
        if (fromTemplate) {
            try {
                deleteApp(token, appId);
            } catch (error) {
                setError(error.message);
            }
        }
        navigate('/');
    }
    const handleDiscardChanges = () => {
        if (fromTemplate) {
            try {
                deleteApp(token, appId);
                navigate('/');
            } catch (error) {
                setError(error.message);
            }
        }
        else {
            navigate(`/explore/${appId}`);
        }
    }
    function handlePublishApp() {
        setIsPrivate(false);
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        } 
    }
    function handlePrivateApp() {
        setIsPrivate(true);
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        } 
    }
    return(
        <section className="edit-app">
            <Header title={'App Editor'}
                search={false}
                cancelButton
                handleCancelButton={handleCancelButtonClick} />
            {showLiveAppModel && ReactDOM.createPortal(
                <LiveAppModal onEditPage={true} setLiveAppModal={setShowLiveAppModal} onConfirm={handleEditApp}/>,
                document.getElementById('modal-root')
            )}
            <header className='edit-app-title'>
                <h1>{appName}</h1>
                <p>{appDescription}</p>
            </header>
                <div className='edit-app-columns'>
                    <div  className='column'>
                        <form className='edit-app-form' onSubmit={handleSubmit}>
                            <div className="edit-app-form-section">
                                <h2>App Info</h2>

                                <div className="field">
                                    <label>App Name</label>
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="App Name"
                                        value={appName}
                                        required
                                        onChange={(event) => setAppName(event.target.value)}
                                        maxLength={256}
                                    />
                                </div>

                                <div className="field">
                                    <label>App Description</label>
                                    <textarea
                                        className="input"
                                        placeholder="App Description"
                                        value={appDescription}
                                        onChange={(event) => setAppDescription(event.target.value)}
                                        maxLength={512}
                                    ></textarea>
                                </div>
                                <div className="field">
                                    <label>Category</label>
                                    <div className="select-container">
                                        <select className="input" value={categorySelected} onChange={(event) => setCategorySelected(event.target.value)}>
                                        {categories?.map((option, key) => (
                                            <option key={key} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-app-form-section">
                                <h2>Variables</h2>  
                            
                                {appInput.map((field, index) => (
                                <div className="field is-grouped" key={index}>
                                    <div className="control is-expanded">
                                        <label>Variable Name</label>
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Variable Name"
                                            name="name"
                                            value={field.name}
                                            required
                                            onChange={(event) => handleChangeInputName(index, event)}
                                        />
                                    </div>
                                    <div className="control is-expanded">
                                        <label>Description</label>
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Varibale Placeholder"
                                            name="value"
                                            value={field.value}
                                            onChange={(event) => handleChangeInputDescription(index, event)}
                                        />
                                    </div>
                                    <div className="control">
                                        {appInput.length > 0 &&
                                            <button className="remove" type="button" onClick={() => handleRemoveFields(index)}>
                                                <TrashIcon />
                                            </button>
                                        }
                                    </div>
                                </div>
                                ))}
                                <div className="field is-grouped">
                                    <div className="control">
                                        <button
                                        className="button is-info add-variable"
                                        type="button"
                                        onClick={() => handleAddFieldsInputs()}
                                        >
                                        Add Fields
                                        </button>
                                    </div>
                                </div>
                                {user.role !== USER &&
                                    <div className="field is-grouped">
                                        <div className="control">
                                            {documentChecked ?
                                                <div className="file-info-container">
                                                    <div className="file-info">
                                                        <span>FileName</span>
                                                    </div>
                                                    <button className="delete-file-button" type="button" onClick={handleAddFileInputs}>
                                                        <CancelIcon />
                                                    </button>
                                                </div>
                                                :
                                                <button
                                                    className="button is-info add-file"
                                                    type="button"
                                                    onClick={handleAddFileInputs}
                                                    disabled={user.role === USER}
                                                    {...(user.role === USER ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}
                                                >
                                                    Add File Input
                                                </button>
                                            }
                                        </div>
                                    </div>}
                            </div>
                            <div className="edit-app-form-section">
                                <h2>Logic</h2>
                                <div className="field is-grouped">
                                    <label>Shows Logic</label>
                                    <input 
                                    type="checkbox" 
                                    onChange={() => setShowsPromt(!showsPrompt)}
                                    checked={showsPrompt}
                                    disabled={user.role === USER}
                                    {...(user.role === USER ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}
                                 />
                                </div>
                                {appInput.map((field, index) => {
                                    if (field.name !== '') {
                                        return (
                                            <span key={index} className="button new-app-variable-button" onClick={() => handleButtonClick(field.name)}>{field.name}</span>
                                        );
                                    }
                                    return null;
                                })}
                                {documentChecked && (
                                    <span className="button new-app-filename-button" onClick={() => handleButtonClick('FileName')}>File Name</span>
                                )}
                                <textarea
                                    ref={logicInputRef}
                                    className="textarea"
                                    placeholder={getPlaceholder()}
                                    value={logic}
                                    onChange={(event) => setLogic(event.target.value)}
                                    ></textarea>
                            </div>
                            <p>{error}</p>
                            <div className="control edit-app-form-buttons">
                                <button className="save-changes" type="submit" disabled={error} ref={submitButtonRef}>
                                    Save Changes
                                </button>
                                <button className="discard-changes" onClick={handleDiscardChanges} >
                                    Discard Changes
                                </button>
                                {currentApp.is_private &&
                                    <button className="publish" onClick={handlePublishApp} disabled={error} >
                                        Publish App
                                    </button>
                                }
                                {!currentApp.is_private &&
                                    <button 
                                        className="private" 
                                        onClick={handlePrivateApp} 
                                        disabled={user.role === USER} 
                                        {...(user.role === USER ? { "data-tooltip": languageTexts[language].tooltipProUserFeature } : {})}
                                    >
                                        Private App
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                    <AppPreview previewInputs={appInput} initialLogic={logic} modelId={model} documentChecked={documentChecked}/>
                </div>
        </section>
        
    )
}

export default EditApp;