import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUserAction } from "../../../redux/actions/authActions";
import DecorationBanner from "../../../components/decorationBanner/DecorationBanner";
import { editPassword } from '../../../api/user';

const ResetPassword = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [controlPassword, setControlPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!newPassword === controlPassword) {
            setError(true);
        } else {
            setError(false);
        }
    }, [newPassword, controlPassword]);

    const handleNewPasswordChange = (event) => {
        setNewPassword(event);
    };

    const handleControlPasswordChange = (event) => {
        setControlPassword(event);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const match = controlPassword === newPassword;
        if(match) {
            const response = await editPassword(token, newPassword);
            if (response.status === 201){
                await dispatch(loginUserAction(response.data.results));
            } else {
                if (response.status === 401){
                    alert("The link has expired, please submit your password reset once again")
                    navigate('/forgotPassword')
                }
            }

        } else {
            setErrorMessage("The passwords entered do not match.");
            setError(true);
        }
    };

    return (
        <section className="login">
            <div className="banner-top">
                <DecorationBanner text={'Learn Build Share Showcase'} />
            </div>
            <div className="login-content">
                <h1 className="login-content-title forgot-password-title">Reset Password</h1>
                <form className="login-content-form" onSubmit={handleSubmit}>
                    <div className="field login-content-form-field">
                        <label className="label">New Password</label>
                        <div className="control">
                            <input type="password" 
                            placeholder="Enter Password" 
                            value={newPassword} 
                            onChange={(e) => handleNewPasswordChange(e.target.value)} 
                            className="input"
                            required />
                        </div>
                    </div>
                    <div className="field login-content-form-field">
                        <label className="label">Repeat New Password</label>
                        <div className="control">
                            <input type="password" 
                            placeholder="Repeat new password" 
                            value={controlPassword} 
                            onChange={(e) => handleControlPasswordChange(e.target.value)} 
                            className="input"
                            required />
                        </div>
                    </div>
                    {errorMessage}
                    <button className="button is-primary login-content-submit" type="submit" disabled={error}>Submit</button>
                </form>
            </div>
            <div className="banner-bottom">
                <DecorationBanner text={'Learn Build Share Showcase'} />
            </div>
        </section>
    );
};

export default ResetPassword;
