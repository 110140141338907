import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppCardMini from '../../components/appCardMini/AppCardMini';
import Searcher from "../../components/searcher/Searcher";
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import './allAppsSection.scss';

const AllAppsSection = ({ mostUsedApps, recentApps, exploreMostUsedText, exploreRecentText }) => {
    const [selectedTab, setSelectedTab] = useState('mostUsed');
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredRecentApps, setFilteredRecentApps] = useState(recentApps);
    const [filteredMostUsedApps, setFilteredMostUsedApps] = useState(mostUsedApps);
    const { language } = useLanguage();


    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

    const applyFilters = (query, category) => {
        let newFilteredRecentApps = [...recentApps];
        let newFilteredMostUsedApps = [...mostUsedApps];
  
        if (query) {
          const normalizedQuery = removeAccents(query.toLowerCase());
          newFilteredRecentApps = newFilteredRecentApps.filter((app) =>
              removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
          );
          newFilteredMostUsedApps = newFilteredMostUsedApps.filter((app) =>
              removeAccents(app.name.toLowerCase()).includes(normalizedQuery)
          );
        }
        if (category) {
          newFilteredRecentApps = newFilteredRecentApps.filter((app) => app.category[`name_${language}`] === category);
          newFilteredMostUsedApps = newFilteredMostUsedApps.filter((app) => app.category[`name_${language}`] === category);
        }
        setFilteredRecentApps(newFilteredRecentApps);
        setFilteredMostUsedApps(newFilteredMostUsedApps);
      };

    const handleSearch = (query) => {
        setSearchQuery(query);
        applyFilters(query);
      };
      useEffect(() => {
        applyFilters(searchQuery);
      }, [recentApps, mostUsedApps, searchQuery]);

    const renderApps = () => {
        const appsToRender = selectedTab === 'mostUsed' ? filteredMostUsedApps : filteredRecentApps;
        return appsToRender && appsToRender.map((card, index) => (
            <div key={index} className='showAllApps-apps-card'>
                <Link to={`./${card.id}`}>{ <AppCardMini appInformation={card} />}</Link>
            </div>
        ));
    };

    return (
        <section className="showAllApps">
            <header className="showAllApps-header">
                <Searcher onSearch={handleSearch} placeholder="Search" />
            </header>
            <div className="showAllApps-apps">
                <div className='showAllApps-apps-tabs'>
                    <button
                        onClick={() => setSelectedTab('mostUsed')}
                        className={`showAllApps-apps-tabs-button ${selectedTab === 'mostUsed' ? 'active' : ''}`}
                    >
                        {exploreMostUsedText}
                    </button>
                    <button
                        onClick={() => setSelectedTab('recent')}
                        className={`showAllApps-apps-tabs-button ${selectedTab === 'recent' ? 'active' : ''}`}
                    >
                        {exploreRecentText}
                    </button>
                </div>
                <div className='showAllApps-apps-container'>
                    {renderApps()}
                </div>
            </div>
        </section>
    );
};

export default AllAppsSection;
